import React, { DragEvent, MouseEvent, useState } from 'react'
import {
  FolderInterface,
  moveDriveContent,
  setDroppedFilesLoading,
  setFolderToDelete,
  setSelectedFilesFolders,
  setTargetedFolder,
} from '../../../features/Drives/redux/drivesSlice'
import {
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFolder,
  AiOutlineMinus,
} from 'react-icons/ai'
import { Button, Dropdown, FormInstance, Tooltip } from 'antd'
import { localFormatDate } from '../../../utils/Utils'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { MenuProps } from 'antd/lib'
import { IoTrashOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { useDrivesContext } from '../../../features/Drives/DrivesContext'
import { compareFilesFolders } from './DrivesList'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'

interface Props {
  folder: FolderInterface
  handleRowClick: (select: FolderInterface, event: MouseEvent) => void
  renameFolderForm: FormInstance
  setSelectedFolderInTable: (folder: FolderInterface) => void
  selectedFolderInTable?: FolderInterface
  setOpenRenamedDriveFolderModal: (open: boolean) => void
  handleDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleDragEnd: () => void
}

function FolderRow({
  folder,
  handleRowClick,
  renameFolderForm,
  setSelectedFolderInTable,
  selectedFolderInTable,
  setOpenRenamedDriveFolderModal,
  handleDragStart,
  handleDragEnd,
}: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { onFolderOpen } = useDrivesContext()
  const auth = useSelector((state: RootState) => state.auth)
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const selectedFilesFolders = useSelector(
    (state: RootState) => state.drive.selectedFilesFolders,
  )
  const rights = useSelector((state: RootState) => state.drive.driveRights)
  const { creatorEmail } = useDriveLoader(selectedDriveFolder?.driveId)

  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const currentlyDragging = useSelector((state: RootState) => state.drive.draggingOver)

  const unSelectFolder = (e: MouseEvent) => {
    const isLeavingList =
      !e.relatedTarget || !e.currentTarget.contains(e.relatedTarget as Node)

    if (isLeavingList) {
      setIsDraggingOver(false)
    }

    if (selectedDriveFolder) {
      dispatch(setTargetedFolder({ folder: selectedDriveFolder }))
    }
  }

  const handleDragEnter = (e: any) => {
    e.preventDefault()
    if (!selectedFilesFolders.find((item) => compareFilesFolders(item, folder))) {
      setIsDraggingOver(true)
      dispatch(setTargetedFolder({ folder }))
    } else {
      unSelectFolder(e)
    }
  }

  const handleDragLeave = (e: MouseEvent) => {
    e.preventDefault()
    unSelectFolder(e)
  }

  const handleDrop = (e: MouseEvent) => {
    e.preventDefault()
    setIsDraggingOver(false)

    if (selectedDriveFolder && selectedFilesFolders.length > 0) {
      const isTargetedFolderInSelectedFiles = selectedFilesFolders.find((item) => {
        if ('id' in item) {
          return item.id === folder.id
        }
        return false
      })

      if (!isTargetedFolderInSelectedFiles) {
        dispatch(setDroppedFilesLoading(true))
        dispatch(
          moveDriveContent({
            contentToMove: selectedFilesFolders,
            folderId: selectedDriveFolder.id,
            targetedFolderId: folder.id,
            driveId: folder.driveId,
          }),
        )
      }
    }
  }

  /**
   * Sets up the modal to rename the folder.
   *
   * @param event - The click event.
   * @param document - The folder to be renamed.
   */
  function setRenameFolder(document: FolderInterface) {
    if (selectedDriveFolder && document) {
      renameFolderForm.setFieldValue('name', document.name)
      setSelectedFolderInTable(document)
      setOpenRenamedDriveFolderModal(true)
    }
  }

  /**
   * Handles the click event for deleting a folder.
   *
   * @param event - The click event.
   * @param document - The folder to be deleted.
   */
  function onDeleteFolder(document?: FolderInterface) {
    if (document) {
      dispatch(
        setFolderToDelete({
          folder: document,
          mainFolder: false,
        }),
      )
    }
  }

  const handleMouseDown = (event: MouseEvent) => {
    if (!selectedFilesFolders.includes(folder) && !event.shiftKey && !event.ctrlKey) {
      dispatch(setSelectedFilesFolders([folder]))
    }
  }

  const folderItems: MenuProps['items'] = [
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEdit size="1.2em" />
          {t('Rename')}
        </span>
      ),
      onClick: () => setRenameFolder(folder),
      key: `rename_folder_${selectedFolderInTable?.id}`,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem error-color">
          <IoTrashOutline size="1.2em" />
          {t('Delete')}
        </span>
      ),
      onClick: () => selectedFolderInTable && onDeleteFolder(selectedFolderInTable),
      className: 'delete-btn',
      key: `delete_folder_${selectedFolderInTable?.id}`,
    },
  ]

  const isSelected = selectedFilesFolders.some((item) =>
    compareFilesFolders(item, folder),
  )

  return (
    <div
      className={`DrivesList-table-row ${
        isSelected ? 'DrivesList-table-row-selected' : ''
      } ${isDraggingOver ? 'folder-drag-over' : ''} ${
        currentlyDragging && isSelected ? 'file-dragging' : ''
      }`}
      onDoubleClick={() => onFolderOpen(folder)}
      onClick={(e) => handleRowClick(folder, e)}
      draggable
      onDragStart={(e) => handleDragStart(e)}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onMouseDown={(e) => handleMouseDown(e)}
    >
      <div className="DrivesList-table-name-column">
        <div className="name">
          <span className="document-icon">
            <AiOutlineFolder size="1.7em" color="#757575" />
          </span>
          <span className="document-name">{folder.name}</span>
        </div>
      </div>
      <div className="DrivesList-table-owner-column">
        {folder.creatorFullName ? (
          <Tooltip title={folder.creatorEmail}>
            <span>{folder.creatorFullName}</span>
          </Tooltip>
        ) : (
          <span>{folder.creatorEmail}</span>
        )}
      </div>
      <div className="DrivesList-table-date-column">
        {localFormatDate(dayjs(folder.createdAt))}
      </div>
      <div className="DrivesList-table-size-column">
        <AiOutlineMinus />
      </div>
      <div className="DrivesList-table-actions-column">
        <div className="action-main-container">
          {(rights?.folderRights || creatorEmail === auth.email) && (
            <>
              <div className="action-custom-container">
                <div className="action-hover-container">
                  <div className="action-icons">
                    <div
                      className="action-rounded-icon"
                      onClick={(e) => setRenameFolder(folder)}
                    >
                      <AiOutlineEdit size="1.5em" />
                    </div>
                  </div>
                </div>
              </div>
              <Dropdown menu={{ items: folderItems }} trigger={['click']}>
                <a
                  onClick={() => setSelectedFolderInTable(folder)}
                  className="action-more-container"
                >
                  <div className="action-more">
                    <BiDotsVerticalRounded size="1.5em" />
                  </div>
                </a>
              </Dropdown>
            </>
          )}
          <div className="action-more-mobile">
            <Button type="primary" onClick={() => onFolderOpen(folder)}>
              <AiOutlineEye size="1.5em" />
            </Button>
            {(rights?.folderRights || creatorEmail === auth.email) && (
              <>
                <Button onClick={(e) => setRenameFolder(folder)}>
                  <AiOutlineEdit size="1.5em" />
                </Button>
                <Button
                  className="btn-danger-border"
                  onClick={(e) => onDeleteFolder(folder)}
                >
                  <IoTrashOutline size="1.2em" className="error-color" />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FolderRow
