import React, { useEffect, useState } from 'react'
import {
  EventType,
  NotarisationEvent,
  fetchNotarisation,
  idleFetchNotarisation,
} from './roomNotarisationSlice'
import { Alert, Row, Button, Col, Spin, Select, Table, TablePaginationConfig } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import './notarisation.scss'
import { ColumnsType } from 'antd/lib/table'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface'
import { useImmer } from 'use-immer'
import { localFormatDate } from '../../utils/Utils'
import { RoomParticipantRoleEnum } from '../room/RoomInterface'
import { useToastContext } from '../../components/Toast/ToastContext'

interface Props {
  roomId: string
}

interface TableParams {
  pagination: TablePaginationConfig
  sortField?: string
  sortOrder?: string
  filters?: Record<string, FilterValue>
}

function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

function NotarisationTable({ roomId }: Props) {
  const { t } = useTranslation('notarisation')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const notarisation = useSelector((state: RootState) => state.roomNotarisation.data)
  const total = useSelector((state: RootState) => state.roomNotarisation.total)
  const peers = useSelector((state: RootState) => state.roomNotarisation.peers)
  const role = useSelector((state: RootState) => state.roomNotarisation.userRole)
  const notarisationStatus = useSelector(
    (state: RootState) => state.roomNotarisation.fetchStatus,
  )
  const [filterPeer, setFilterPeer] = useState<string | undefined>()
  const [filterType, setFilterType] = useState<string | undefined>()

  const [tableParams, setTableParams] = useImmer<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  useEffect(() => {
    return () => {
      dispatch(idleFetchNotarisation())
    }
  }, [])

  useEffect(() => {
    if (notarisationStatus === 'error') {
      ToastOpen({
        message: t('Fail to fetch notarisation.'),
        type: 'error',
      })
    }
  }, [notarisationStatus])

  useEffect(() => {
    setTableParams((draft) => {
      draft.pagination.total = total
    })
  }, [total])

  useEffect(() => {
    dispatch(
      fetchNotarisation({
        roomId,
        filterType,
        filterPeer,
        pageNumber: tableParams.pagination.current || 1,
        pageSize: tableParams.pagination.pageSize || 10,
        sortField: tableParams.sortField,
        sortOrder: tableParams.sortOrder,
      }),
    )
  }, [
    filterType,
    filterPeer,
    tableParams.pagination.current,
    tableParams.sortField,
    tableParams.sortOrder,
  ])

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<NotarisationEvent> | SorterResult<NotarisationEvent>[],
    extra: TableCurrentDataSource<NotarisationEvent>,
  ) {
    const s = sorter as SorterResult<NotarisationEvent>
    const { current, pageSize } = pagination
    setTableParams((draft) => {
      draft.pagination.current = current
      draft.pagination.pageSize = pageSize
      draft.sortField = String(s.columnKey)
      draft.sortOrder = String(s.order) || 'asc'
    })
    dispatch(
      fetchNotarisation({
        roomId,
        filterType,
        filterPeer,
        pageNumber: current || 1,
        pageSize: pageSize || 10,
        sortField: tableParams.sortField,
        sortOrder: tableParams.sortOrder,
      }),
    )
  }

  function handleTypeChange(value?: string) {
    setFilterType(value)
    setTableParams((draft) => {
      draft.pagination.current = 1
    })
  }

  function handlePeerChange(value?: string) {
    setFilterPeer(value)
    setTableParams((draft) => {
      draft.pagination.current = 1
    })
  }

  function filterReset() {
    setFilterType(undefined)
    setFilterPeer(undefined)
    setTableParams((draft) => {
      draft.pagination.current = 1
    })
  }

  function eventColor(event: NotarisationEvent) {
    switch (event.type) {
      case EventType.MEETING_STARTED:
      case EventType.PEER_JOINED:
      case EventType.FILE_UPLOADED:
        return '#006442'
      case EventType.PEER_DISCONNECTED:
      case EventType.PEER_REMOVED:
      case EventType.MEETING_ENDED:
      case EventType.FILE_REMOVED:
        return '#C42929'
      default:
        return '#1F4788'
    }
  }

  function NotarisationType({ type }: { type: EventType }) {
    switch (type) {
      case EventType.PEER_REMOVED:
      case EventType.PEER_INVITED:
      case EventType.PEER_DISCONNECTED:
      case EventType.PEER_JOINED:
      case EventType.PEER_NOTIFY_MISSING_PARTICIPANTS:
        return <Trans i18nKey="PEER" ns="notarisation" />
      case EventType.FILE_DOWNLOAD:
      case EventType.FILE_REMOVED:
      case EventType.FILE_UPLOADED:
        return <Trans ns="notarisation" i18nKey="FILES" />
      case EventType.NEW_CHAT_STARTED:
        return <Trans ns="notarisation" i18nKey="NEW_CHAT" />
      default:
        return <Trans ns="notarisation" i18nKey="MEETING" />
    }
  }

  function EventDetail({ event }: { event: NotarisationEvent }) {
    switch (event.type) {
      case EventType.PEER_REMOVED:
        return (
          <Trans
            i18nKey="PEER_REMOVED"
            ns="notarisation"
            values={{
              email: event.targetEmail,
            }}
          />
        )
      case EventType.PEER_INVITED:
        return (
          <Trans
            ns="notarisation"
            i18nKey="PEER_INVITED"
            values={{
              email: event.targetEmail,
            }}
          />
        )
      case EventType.FILE_UPLOADED:
        return (
          <Trans
            ns="notarisation"
            i18nKey="FILE_UPLOAD"
            shouldUnescape={true}
            values={{
              filename: event.filename,
            }}
          />
        )
      case EventType.FILE_DOWNLOAD:
        return (
          <Trans
            ns="notarisation"
            i18nKey="FILE_DOWNLOAD"
            shouldUnescape={true}
            values={{
              filename: event.filename,
            }}
          />
        )
      case EventType.FILE_REMOVED:
        return (
          <Trans
            ns="notarisation"
            i18nKey="FILE_REMOVED"
            shouldUnescape={true}
            values={{
              filename: event.filename,
            }}
          />
        )
      case EventType.NEW_CHAT_STARTED:
        return (
          <Trans
            ns="notarisation"
            i18nKey="NEW_CHAT_STARTED"
            values={{
              peerDestination: event.peerDestination,
            }}
          />
        )
      default:
        return <>{t(event.type)}</>
    }
  }

  const columns: ColumnsType<NotarisationEvent> = [
    {
      title: t('Date'),
      dataIndex: 'createdDt',
      className: 'date-column',
      key: 'createdDt',
      render: (createdDt: string, event: NotarisationEvent) => {
        return {
          props: {
            style: { color: eventColor(event) },
          },
          children: localFormatDate(dayjs(new Date(createdDt))),
        }
      },
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: t('User'),
      dataIndex: 'peer',
      className: 'peer-column',
      key: 'peer',
      render: (peer, event) => {
        return {
          props: {
            style: { color: eventColor(event) },
          },
          children:
            (peers && peers[peer]?.firstName + ' ' + peers[peer]?.lastName) || '-',
        }
      },
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      className: 'types-column',
      key: 'type',
      render(type, event) {
        return {
          props: {
            style: { color: eventColor(event) },
          },
          children: <NotarisationType type={type} />,
        }
      },
    },
    {
      title: t('Details'),
      dataIndex: 'details',
      className: 'details-column',
      key: 'details',
      render(_, event: NotarisationEvent) {
        return {
          props: {
            style: { color: eventColor(event) },
          },
          children: <EventDetail event={event} />,
        }
      },
    },
  ]

  const FILTER_TYPES = [
    { value: 'PEER', label: t('PEER') },
    { value: 'MEETING', label: t('MEETING') },
    { value: 'NEW_CHAT', label: t('NEW_CHAT') },
    ...(role !== RoomParticipantRoleEnum.Attendee
      ? [{ value: 'FILES', label: t('FILES') }]
      : []),
  ]

  return (
    <>
      <Row className="center-space mb-05rem">
        <Col className="margin-right-col">{t('User')} :</Col>
        <Col span={4}>
          <Select
            optionFilterProp="children"
            placeholder={t('Search by') + ' ' + t('peer')}
            options={
              Object.values(peers || {}).map((peer) => ({
                value: peer.email,
                label: peer.firstName + ' ' + peer.lastName,
              })) || []
            }
            className="input-filter"
            onChange={handlePeerChange}
            value={filterPeer}
            showSearch
            allowClear
            filterOption={(inputValue, option) =>
              removeAccents(option?.label || '')
                .toLowerCase()
                .includes(removeAccents(inputValue).toLowerCase()) || false
            }
          />
        </Col>
        <Col span={1}></Col>
        <Col className="margin-right-col">{t('Type')} :</Col>
        <Col span={4}>
          <Select
            optionFilterProp="children"
            placeholder={t('Search by') + ' ' + t('types')}
            options={FILTER_TYPES}
            className="input-filter"
            onChange={handleTypeChange}
            showSearch
            allowClear
            filterOption={(inputValue, option) =>
              removeAccents(option?.label || '')
                .toLowerCase()
                .includes(removeAccents(inputValue).toLowerCase()) || false
            }
            value={filterType}
          />
        </Col>
        <Col span={1}></Col>
        <Col>
          <Button onClick={filterReset} size="small" className="cancel-button">
            {t('Reset all')}
          </Button>
        </Col>
      </Row>
      {notarisationStatus === 'success' && (
        <>
          <Table
            dataSource={notarisation}
            rowKey="id"
            columns={columns}
            onChange={onChange}
            showSorterTooltip={false}
            pagination={
              (total || 0) >= 11 && {
                position: ['bottomCenter'],
                showTotal: (total, range) => (
                  <Trans
                    ns="common"
                    i18nKey="TABLE_PAGINATION"
                    values={{
                      range0: range[0],
                      range1: range[1],
                      total: total,
                    }}
                  />
                ),
                showSizeChanger: true,
                total: tableParams.pagination.total,
                current: tableParams.pagination.current,
                pageSize: tableParams.pagination.pageSize,
              }
            }
          />
        </>
      )}

      {notarisationStatus === 'loading' && (
        <div className="text-center pt-1rem">
          <Spin />
        </div>
      )}

      {notarisationStatus === 'error' && (
        <Alert
          message={t('Unable to retrieve the notarization for this meeting.')}
          type="error"
        />
      )}
    </>
  )
}

export default NotarisationTable
