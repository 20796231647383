import dayjs, { OpUnitType } from "dayjs"

export function buildCalendarUrl(currentDate: string, view: OpUnitType) {
  const min = dayjs(currentDate).startOf('month').toISOString()
  const max = dayjs(currentDate).endOf('month').add(1, 'milliseconds').toISOString()
  const queryParams = new URLSearchParams({
    minDate: min,
    maxDate: max,
    view: view,
    currentDate: currentDate
  })
  
  return `/calendar?${queryParams}`
}