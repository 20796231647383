import React, { ReactNode } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import sanitizeHtml from 'sanitize-html'
import { AppDispatch, RootState } from '../../../store'
import ChatMessageFile from './ChatMessageFile'
import './ChatMessages.scss'
import { Chat, ChatMessage, setCurrentChatId } from './chatsSlice'

import { BsFillReplyFill } from 'react-icons/bs'
import { generateUniqueColor } from '../../../features/room/RoomChat/ChatLibrary'
import { allowedTagsObject, localFormatDate } from '../../../utils/Utils'
import ChatMessageActions from './ChatMessageActions'
import useScrollToMessage from '../../../utils/hooks/useScrollToMessage'
import { ImBlocked } from 'react-icons/im'

interface MessageProps {
  //TODO: this interface can be exported and used in ChatMessageActions. try and see eslint problem
  chat: Chat
  message: ChatMessage
  citation?: true
}

export function DeletedMessageContent() {
  const { t } = useTranslation('chat')

  return (
    <div className="deleted_message_content">
      <ImBlocked size="0.75rem" />
      <em>{t('This message was deleted')}</em>
    </div>
  )
}

export default function Message({ message, chat, citation }: MessageProps) {
  const repliedTo: ChatMessage | null =
    message.repliedTo?.length > 0 ? JSON.parse(message.repliedTo) : null

  return (
    <MessageContainer message={message} chat={chat} citation={citation}>
      {message.deleted ? (
        <DeletedMessageContent />
      ) : (
        <>
          {repliedTo && !citation && <Message message={repliedTo} chat={chat} citation />}
          {message.text && (
            <div
              className="chat_message_text"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(message.text, allowedTagsObject),
              }}
            />
          )}
          {message.files && (
            <ChatMessageFile
              encryptKey={chat.fileEncryptionKey}
              content={message.files}
              withControls={true}
            />
          )}
        </>
      )}
      <ChatMessageActions message={message} citation={citation} chat={chat} />
    </MessageContainer>
  )
}

function MessageContainer({
  message,
  chat,
  citation,
  children,
}: MessageProps & { children: ReactNode }) {
  const { t } = useTranslation('chat')
  const invite = useSelector((state: RootState) => state.invite)
  const username = useSelector((state: RootState) => state.auth.email || invite.email)
  const messageToEdit = useSelector(
    (state: RootState) => state.chats.messageInputDraft[chat.id]?.messageToEdit,
  )
  const dispatch = useDispatch<AppDispatch>()
  const chats = useSelector((state: RootState) => state.chats.chats[chat.squadId])

  const scrollToMessage = useScrollToMessage(chat.id, chat.squadId)

  const p2pChatsByPeer = chats.reduce((result: { [peer: string]: string }, chat) => {
    if (chat.peerToPeer && chat.peer) {
      result[chat.peer.email] = chat.id
    }

    return result
  }, {})

  function peerDisplayName(message: ChatMessage) {
    return message.peerFirstName && message.peerLastName
      ? `${message.peerFirstName} ${message.peerLastName}`
      : message.peerEmail
  }

  function peerInitials(message: ChatMessage): string {
    return message.peerFirstName && message.peerLastName
      ? message.peerFirstName.substring(0, 1) +
          message.peerLastName.substring(0, 1).toUpperCase()
      : message.peerEmail.substring(0, 2).toUpperCase()
  }

  function goToPeer2PeerChat(chatId: string) {
    dispatch(setCurrentChatId(chatId))
  }

  return (
    <div
      className={classNames({
        'chat_message-container': true,
        'chat_message-container--self': !citation && message.peerEmail === username,
      })}
    >
      <div
        className={classNames({
          chat_message: true,
          'chat_message--citation': citation,
          'chat_message--self': !citation && message.peerEmail === username,
          'chat_message--self-edit': !citation && message.id === messageToEdit?.id,
          'chat_message--other': !citation && message.peerEmail !== username,
        })}
        id={
          citation
            ? undefined /* to avoid highlighting of cited msg in scrollToMessage */
            : message.id
        }
        key={message.id}
        style={{
          borderLeft: `${
            citation ? `3px solid ${generateUniqueColor(message.peerEmail)}` : ''
          }`,
        }}
        onClick={() => {
          citation && scrollToMessage(message.id)
        }}
      >
        {(p2pChatsByPeer[message.peerEmail] || message.peerEmail !== username) &&
          !citation && (
            <div
              className="chat_message_author_icon"
              style={{
                backgroundColor: generateUniqueColor(message.peerEmail),
              }}
            >
              {peerInitials(message)}
            </div>
          )}
        <div className="chat_message_header">
          <div
            className={`chat_message_author ${
              p2pChatsByPeer[message.peerEmail] || message.peerEmail !== username
                ? 'chat_message_author--clickable'
                : ''
            }`}
            onClick={() => {
              if (p2pChatsByPeer[message.peerEmail]) {
                goToPeer2PeerChat(p2pChatsByPeer[message.peerEmail])
              }
            }}
          >
            {p2pChatsByPeer[message.peerEmail] || message.peerEmail !== username ? (
              <Tooltip title={message.peerEmail}>{peerDisplayName(message)}</Tooltip>
            ) : (
              <>{t('You', { ns: 'room' })}</>
            )}
          </div>
          <div className="chat_message_date">
            <Tooltip
              title={
                message.editedAt && !citation ? (
                  <>
                    {t('Created on') + ` ${localFormatDate(dayjs(message.createdAt))}`}
                    <br />
                    {t('Edited on') + ` ${localFormatDate(dayjs(message.editedAt))}`}
                  </>
                ) : (
                  dayjs(message.createdAt).format('LLL')
                )
              }
              trigger={['hover', 'click']}
            >
              {dayjs(message.createdAt).format('LT')}
              {!citation && message.editedAt && (
                <>
                  &nbsp;-&nbsp;{' '}
                  {dayjs().format('LL') === dayjs(message.editedAt).format('LL')
                    ? t('Edited at') + ` ${dayjs(message.editedAt).format('LT')}`
                    : t('Edited on') + ` ${localFormatDate(dayjs(message.editedAt))}`}
                </>
              )}
              {citation && <BsFillReplyFill />}
            </Tooltip>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
