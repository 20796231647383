import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Button, Col, List, Row } from 'antd'
import './ModifyChatPeers.scss'
import {
  SearchGroupDTO,
  SearchUserDTO,
} from '../../../features/Organisation/OrganisationService'
import UserAndGroupAutocomplete from '../../UserAndGroupAutocomplete/UserAndGroupAutocomplete'
import { useTranslation } from 'react-i18next'
import { IoTrashOutline } from 'react-icons/io5'

interface Props {
  peers: SearchUserDTO[]
  groups: SearchGroupDTO[]
  onUsersUpdate: (peers: SearchUserDTO[]) => void
  onGroupsUpdate: (peers: SearchGroupDTO[]) => void
}

function ModifyChatPeers({ peers, groups, onUsersUpdate, onGroupsUpdate }: Props) {
  const { t } = useTranslation('chat')
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const email = useSelector((state: RootState) => state.auth.email)

  const organisationId = useSelector(
    (state: RootState) => state.organisation.organisation?.id,
  )

  function addPeer(peer: SearchUserDTO) {
    onUsersUpdate([...peers, peer])
  }

  function deletePeer(email: string) {
    onUsersUpdate(peers.filter((p) => p.email !== email))
  }

  function addGroup(group: SearchGroupDTO) {
    onGroupsUpdate([...groups, group])
  }

  function deleteGroup(id: string) {
    onGroupsUpdate(groups.filter((g) => g.id !== id))
  }

  return (
    <>
      {jwt && email && organisationId && (
        <UserAndGroupAutocomplete
          jwt={jwt}
          email={email}
          organisationId={organisationId}
          peers={peers}
          groups={groups}
          onUserSelect={addPeer}
          onGroupSelect={addGroup}
        />
      )}
      <Row>
        <Col span={11}>
          <List
            className="peers_list"
            itemLayout="horizontal"
            dataSource={peers}
            renderItem={(peer) => (
              <List.Item>
                <List.Item.Meta
                  title={`${peer.firstName} ${peer.lastName}`}
                  description={peer.email}
                />
                {peer.email !== email && (
                  <Button
                    className="btn-danger-border"
                    onClick={() => deletePeer(peer.email)}
                    disabled={peer.email === email}
                  >
                    <IoTrashOutline className="error-color" size="1em" />
                  </Button>
                )}
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11}>
          {groups.length > 0 && (
            <List
              className="peers_list"
              itemLayout="horizontal"
              dataSource={groups}
              renderItem={(group) => (
                <List.Item>
                  <List.Item.Meta
                    title={group.name}
                    description={t('Group', { ns: 'drives' })}
                  />
                  <Button
                    className="btn-danger-border"
                    onClick={() => deleteGroup(group.id)}
                  >
                    <IoTrashOutline className="error-color" size="1em" />
                  </Button>
                </List.Item>
              )}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

export default ModifyChatPeers
