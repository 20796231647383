import { DriveInterface, FileInterface, FolderInterface, selectableInterface } from "../../features/Drives/redux/drivesSlice"
import { UserGroup } from "../../features/auth/redux/authSlice"
import { compareFilesFolders } from "./DrivesList/DrivesList"

/**
 * 
 * @param folder 
 * @param newFolder 
 * @param parentId 
 * @returns 
 */
export function addChildToFolder(folder: FolderInterface, newFolder: FolderInterface, parentId: string) {
  if (folder.id === parentId) {
    folder.childrenFolders.push(newFolder)
    folder.childrenFolders.sort((a, b) => a.name.localeCompare(b.name))
    return folder
  } else {
    if (folder.childrenFolders && folder.childrenFolders.length > 0) {
      for (const childFolder of folder.childrenFolders) {
        addChildToFolder(childFolder, newFolder, parentId)
      } 
    }
  }
}
  

/**
 * 
 * @param driveFolders 
 * @param newFolder 
 * @param parentId 
 * @returns 
 */
export function addDriveFolder(driveFolders: FolderInterface[], newFolder: FolderInterface, parentId: string) {
  for (const driveFolder of driveFolders) {
    if (driveFolder.driveId === newFolder.driveId) {
      addChildToFolder(driveFolder, newFolder, parentId)
    }
  }

  return driveFolders
}


/**
 * Update drive folders recursively
 * @param drives Array of DriveInterface
 * @param newFolder New folder to update
 * @returns Updated array of DriveInterface
 */
export function updateDriveFoldersRecursively(drives: DriveInterface[], newFolder: FolderInterface): DriveInterface[] {
  return drives.map((drive: DriveInterface) => {
    if (drive.id === newFolder.driveId) {
      const updatedRootFolder = updateFolderRecursively(drive.rootFolder, newFolder)
      if (updatedRootFolder !== drive.rootFolder) {
        return {
          ...drive,
          rootFolder: updatedRootFolder,
        }
      }
    }
    return drive
  })
}

/**
 * Update folder recursively
 * @param folder Folder to update
 * @param newFolder New folder data
 * @returns Updated folder if found, else the original folder
 */
export function updateFolderRecursively(folder: FolderInterface, newFolder: FolderInterface): FolderInterface {
  if (folder.id === newFolder.id) {
    return {
      ...folder,
      childrenFiles: newFolder.childrenFiles,
      childrenFolders: newFolder.childrenFolders,
    }
  } else if (folder.childrenFolders && folder.childrenFolders.length > 0) {
    const updatedChildren = folder.childrenFolders.map((childFolder) =>
      updateFolderRecursively(childFolder, newFolder)
    )
    if (updatedChildren !== folder.childrenFolders) {
      return {
        ...folder,
        childrenFolders: updatedChildren,
      }
    }
  }
  return folder
}

/**
 * remove drive folders recursively
 * @param drives Array of DriveInterface
 * @param deletedItem folder to delete
 * @returns Updated array of DriveInterface
 */
export function deleteDriveItemRecursively(drives: DriveInterface[], driveId: string, deletedItem: selectableInterface): DriveInterface[] {
  return drives.map((drive: DriveInterface) => {
    if (drive.id === driveId) {
      const deletedRootFolder = deletedItemRecursively([drive.rootFolder], deletedItem)
      if (deletedRootFolder !== drive.rootFolder.childrenFolders) {
        return {
          ...drive,
          rootFolder: deletedRootFolder[0]
        }
      }
    }
    return drive
  })
}

/**
 * delete folder recursively
 * @param childrenFolders of the current folder
 * @param itemToDelete file/folder to delete
 * @returns Updated folder if found, else the original folder
 */
export function deletedItemRecursively(childrenFolders: FolderInterface[], itemToDelete: selectableInterface): FolderInterface[] {
  if (childrenFolders && childrenFolders.length > 0) {
    const updatedChildren = childrenFolders
      .filter((childFolder) => !compareFilesFolders(childFolder, itemToDelete))
      .map((childFolder) =>
        {return {
          ...childFolder,
          childrenFolders: deletedItemRecursively(childFolder.childrenFolders, itemToDelete),
          childrenFiles: childFolder.childrenFiles?.filter((file) => !compareFilesFolders(file, itemToDelete))
        }}
      )
    if (updatedChildren !== childrenFolders) {
      return updatedChildren
    }
  }
  return childrenFolders
}

/**
 * 
 * @param drivefolders 
 * @param newFolder 
 * @returns 
 */
export function mapDrives(drivefolders: DriveInterface[], callback: (folders: FolderInterface[]) => FolderInterface[]) : DriveInterface[] {
  return drivefolders.map((drive: DriveInterface) => {
    if (drive.rootFolder) {
      drive.rootFolder = callback([drive.rootFolder])[0]
      return drive
    } else {
      return drive
    }
  })
}

/**
 * 
 * @param folders 
 * @param selectedFolder 
 * @param id 
 * @returns 
 */
export function findFolderById(
  folders: FolderInterface[],
  selectedFolder: FolderInterface,
  id: React.Key,
): FolderInterface | null {
  for (const folder of folders) {
    if (selectedFolder.driveId === folder.driveId) {
      if (folder.id === id) {
        return folder
      }

      if (folder.childrenFolders && folder.childrenFolders.length > 0) {
        const childFolder = findFolderById(folder.childrenFolders, selectedFolder, id)
        if (childFolder) {
          return childFolder
        }
      }
    }
  }
  return null
}

export function findParentFolderById(
  folders: FolderInterface[],
  id: React.Key,
  driveId: string,
): FolderInterface | null {
  function recursiveFind(parents: FolderInterface[], depth: number): FolderInterface | null {
    for (const folder of parents) {
      if (folder.driveId === driveId) {
        if (folder.childrenFolders) {
          for (const childFolder of folder.childrenFolders) {
            if (childFolder.id === id) {
              return folder
            }
            const parentFolder = recursiveFind([childFolder], depth + 1)
            if (parentFolder) {
              return parentFolder
            }
          }
        }
      }
    }
    return null
  }
  
  return recursiveFind(folders, 0)
}

export function filterFileSize(file: File) {
  const size = process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA ? parseInt(process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA, 10) * 1024 * 1024 : 100 * 1024 * 1024
  return file.size <= size
}

export function filterFileListBySize(files: FileList) {
  const filesArray = Array.from(files)
  const filesToAdd = filesArray.filter((file: File) => filterFileSize(file))
  const filesToRemove = filesArray.filter((file: File) => !filterFileSize(file))

  return {
    filesToAdd,
    filesToRemove
  }
}

export function isDriveAdmin(drive?: DriveInterface, userEmail?: string, userGroups?: UserGroup[]): boolean {
  if (!drive || !userEmail) {
    return false
  }

  if (drive.creatorEmail === userEmail) {
    return true
  }

  const user = drive.users.find(u => u.email === userEmail && u.adminRights)
  const group = drive.groups.find(g => userGroups?.map(gr => gr.id).includes(g.id) && g.adminRights)
  return !!user || !!group
}