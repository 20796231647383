import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../../utils/FetchStatus'
import { OrganizationParticipant, OrganizationInterface } from '../OrganizationInterface'
import ApiError from '../../../utils/errors/ApiError'
import customFetch from '../../../customFetch'


interface State {
  usersTotalNumber: number
  organisation?: OrganizationInterface
  users?: OrganizationParticipant[]
  fetchOrganizationStatus: FetchStatus
  fetchOrganizationError?: string
  fetchUsersStatus: FetchStatus
  fetchUsersError?: string
  addParticipantStatus: FetchStatus
  addParticipantError?: string
  attachParticipantStatus: FetchStatus
  attachParticipantError?: string
  modifyParticipantStatus: FetchStatus
  deleteParticipantStatus: FetchStatus
}


export const initialState: State = {
  usersTotalNumber: 0,
  fetchOrganizationStatus: "idle",
  fetchUsersStatus: "idle",
  addParticipantStatus: "idle",
  attachParticipantStatus: "idle",
  modifyParticipantStatus: "idle",
  deleteParticipantStatus: "idle",
}


/**
 * Delete user from organisation.
 */
export const attemptDeleteParticipant = createAsyncThunk(
  'organisation/deleteParticipantStatus', 
  async (
    { organizationId, user }:
      {
        organizationId: string
        user: { email: string }
      }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await customFetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/detach_user`, {
      method: 'POST', //TODO review, maybe it should be DELETE? however it is not allowed on backend
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify(user),
    })

    if (!response.ok) {
      throw new Error("Could not delete participant")
    }
  }
)


export const attemptAddParticipant = createAsyncThunk(
  'organisation/addParticipant',
  async (
    { organizationId, user }: { organizationId: string, user: OrganizationParticipant },
    { getState }
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await customFetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/user/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify(user),
    })
  }
)


export const fetchOrganizationSummary = createAsyncThunk(
  'organization/fetchOrganizationSummary',
  async (
    { organizationId }: { organizationId: string },
    { getState }
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await customFetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/summary`, { //TODO: keep oranization || organisation in all our projects
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
    },
    )

    const body = await response.json()
    return body
  }
)


export const attemptAttachParticipant = createAsyncThunk(
  'organisation/attachParticipant',
  async (
    { organizationId, 
      user}:
      {
        organizationId: string
        user: OrganizationParticipant 
      }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await customFetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/upstert_and_attach_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify(user),
    })
  }
)


export const attemptModifyParticipant = createAsyncThunk(
  'organisation/modifyParticipant',
  async (
    { organizationId, originalEmail, user }: { organizationId: string, originalEmail: string, user: OrganizationParticipant },
    { getState }
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await customFetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/user/modify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify({
        originalEmail,
        ...user
      }),
    })

    if (!response.ok) {
      throw new Error("Could not modify participant")
    }

  }
)

export const fetchUsers = createAsyncThunk(
  'organizationUsers/fetchUsers',
  async ({ page, usersPerPage }: { page: number, usersPerPage: number }, { getState }) => {
    const { auth, organisation } = getState() as { auth: { jwt: string }, organisation: { organisation: { id: string } } }
    const organizationId = organisation.organisation.id
    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/users?page=${page}&usersPerPage=${usersPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
    const data = await response.json()
    return data
  }
)


const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    idleAddParticipantStatus: (state) => {
      state.addParticipantStatus = "idle"
      state.addParticipantError = undefined
    },
    idleModifyParticipantStatus: (state) => {
      state.modifyParticipantStatus = "idle"
    },
    idleAttachParticipantStatus: (state) => {
      state.attachParticipantStatus = "idle"
      state.attachParticipantError = undefined
    },
    idleDeleteParticipantStatus: (state) => {
      state.deleteParticipantStatus = "idle"
    },
    setOrganisation: (state, { payload }: PayloadAction<OrganizationInterface | undefined>) => {
      state.organisation = payload
    },
    idleUsers: (state) => {
      state.fetchUsersStatus = "idle"
      state.fetchUsersError = undefined
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationSummary.pending, (state) => {
        // state.fetchSummaryStatus = "loading"
      })
      .addCase(fetchOrganizationSummary.fulfilled, (state, action) => {
        // state.fetchSummaryStatus = "success"
        state.organisation = action.payload
      })
      .addCase(fetchOrganizationSummary.rejected, (state) => {
        // state.fetchSummaryStatus = "error"
      })

      .addCase(fetchUsers.pending, (state) => {
        state.fetchUsersStatus = "loading"
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }: PayloadAction<{ users: OrganizationParticipant[], totalNumber: number }>) => {
        state.fetchUsersStatus = "success"
        state.users = payload.users
        state.usersTotalNumber = payload.totalNumber
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.fetchUsersStatus = "error"
      })

      .addCase(attemptDeleteParticipant.pending, (state, action) => {
        state.deleteParticipantStatus = "loading"
      })
      .addCase(attemptDeleteParticipant.fulfilled, (state, action) => {
        state.deleteParticipantStatus = "success"
        // state.organisation = action.payload
      })
      .addCase(attemptDeleteParticipant.rejected, (state, action) => {
        state.deleteParticipantStatus = "error"
      })

      .addCase(attemptAddParticipant.pending, (state, action) => {
        state.addParticipantStatus = "loading"
        state.addParticipantError = undefined
      })
      .addCase(attemptAddParticipant.fulfilled, (state, action) => {
        state.addParticipantStatus = "success"
        state.addParticipantError = undefined
        // state.organisation = action.payload
      })
      .addCase(attemptAddParticipant.rejected, (state, action) => {
        console.log('action.error:', action.error)
        const error = action.error
        state.addParticipantStatus = "error"
        state.addParticipantError = error.message ?? "unknown_error"
      })

      .addCase(attemptAttachParticipant.pending, (state, action) => {
        state.attachParticipantStatus = "loading"
        state.attachParticipantError = undefined
      })
      .addCase(attemptAttachParticipant.fulfilled, (state, action) => {
        state.attachParticipantStatus = "success"
        state.attachParticipantError = undefined
        // state.organisation = action.payload
      })
      .addCase(attemptAttachParticipant.rejected, (state, action) => {
        const error = action.error
        state.attachParticipantStatus = "error"
        state.attachParticipantError = error.message ?? "unknown_error"
      })

      .addCase(attemptModifyParticipant.pending, (state, action) => {
        state.modifyParticipantStatus = "loading"
      })
      .addCase(attemptModifyParticipant.fulfilled, (state, action) => {
        console.log('fulfilled action:', action)
        state.modifyParticipantStatus = "success"
      })
      .addCase(attemptModifyParticipant.rejected, (state, action) => {
        console.log('rejected action:', action)

        state.modifyParticipantStatus = "error"
      })
  },
})


export default organisationSlice.reducer


export const {
  idleAddParticipantStatus,
  idleModifyParticipantStatus,
  idleAttachParticipantStatus,
  idleDeleteParticipantStatus,
  idleUsers,
  setOrganisation,
} = organisationSlice.actions
