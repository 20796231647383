import { useState, type Dispatch, type SetStateAction, useEffect } from 'react'
import { ChatMessages, saveChatScrollPosition } from '../../components/ChatComponent/Chat/chatsSlice'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'


interface ChunkLoadingInfo {
  prevLimit: number
}

export function useChatScrollPositionMemory(
  currentChatId: string,
  chatMessages: ChatMessages,
  scrolledToBottom: boolean,
  scrollbarRef: React.RefObject<Scrollbars>,
  ):[ChunkLoadingInfo | undefined, Dispatch<SetStateAction<ChunkLoadingInfo | undefined>>] {
  const [loading, setLoading] = useState<ChunkLoadingInfo | undefined>({ prevLimit: 0 })

  const dispatch = useDispatch<AppDispatch>()
  const selectChatDetails = (state: RootState) => state.chats.chatsDetails[currentChatId]
  const chatScrollPositionSelector = createSelector(selectChatDetails, (chatDetails) => {
    return chatDetails?.scrollPosition
  })

  const unreadMessagesCountSelector = createSelector(selectChatDetails, (chatDetails) => {
    return chatDetails?.unreadMessagesCount
  })
  const unreadMessagesCounter = useSelector(unreadMessagesCountSelector)

  const chatScrollPosition = useSelector(chatScrollPositionSelector)

  function scrollToNewMessagesSeparator(){
    const newMessagesSeparator = document.getElementById('new_messages')
    if(!newMessagesSeparator || !scrollbarRef.current) return
    const { scrollHeight, clientHeight } = scrollbarRef.current.getValues()
    const separatorOffset = newMessagesSeparator.offsetTop
    const newPosition = scrollHeight - clientHeight > separatorOffset ? separatorOffset : scrollHeight - clientHeight
    scrollbarRef.current.scrollTop(newPosition)
  }

  useEffect(() => {
    if(!chatMessages) return
    // Loading done

    setLoading(undefined)
    if(scrolledToBottom) {
      scrollbarRef.current?.scrollToBottom()
    }
    else if (loading?.prevLimit && scrollbarRef.current) {      
      const scrollHeight = scrollbarRef.current.getScrollHeight()
      scrollbarRef.current?.scrollTop(scrollHeight - loading.prevLimit)
      dispatch(saveChatScrollPosition({chatId: currentChatId, position: scrollHeight - loading.prevLimit}))
    }

    else if(chatScrollPosition) {
      scrollbarRef.current?.scrollTop(chatScrollPosition)
    } 

    else if(unreadMessagesCounter){
      scrollToNewMessagesSeparator()
    }
    // we could save scroll position on leaving chat, however it makes problem when chat has many chunks
    // return () => {
    //   if(scrollbarRef.current){
    //     const { scrollTop } = scrollbarRef.current.getValues()
    //     dispatch(saveChatScrollPosition({chatId: currentChatId, position: scrollTop}))
    //   }
    // }
  }, [chatMessages])

  return [loading, setLoading]
}
