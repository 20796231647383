import { AppDispatch } from "../../store"
import { addLiveNotification, attemptFetchLiveNotifications, Notification } from "./liveWidgetSlice"

export default class Notifier {
  private controller = new AbortController()
  private stream? = new ReadableStream

  constructor(private dispatch: AppDispatch) {
  }

  public async connect(jwt: string) {
    try {
      const notifier = this

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/notification/stream`, {
        method: 'GET',
        headers: {
          'Authorization': `${jwt}`
        },
        signal: this.controller.signal
      })
      if (!response.ok) {
        throw new Error('Failed to connect to event stream. Status: ' + response.status);
      }
      const reader = response.body!.getReader();

      this.stream = new ReadableStream({
        start(controller) {
          function processStreamResult(result: ReadableStreamReadResult<Uint8Array>): Promise<void> {
            if (result.done) {
              controller.close()
              return Promise.resolve()
            }
            try {
              const chunk: string = new TextDecoder().decode(result.value!)
              const lines: string[] = chunk.split('\n')
              lines.forEach(line => {
                  if (line.trim() !== '') {
                      notifier.handleEvent(line)
                  }
              });
            } catch (error) {
              console.error('Error processing stream data:', error)
            }
            return reader.read().then(processStreamResult)
          }
          return reader.read().then(processStreamResult)
        }
      })
    } catch(e) {
      console.error(e)
    }
  }

  public disconnect() {
    this.stream?.cancel()
    this.controller.abort()
  }

  // Function to handle incoming events
  private handleEvent(event: string): void {
    const notificaion = JSON.parse(event.substring(5)) as Notification
    switch (notificaion.type) {
      case "CHAT_MESSAGE":
      case "DRIVE_CREATED":
      case "DRIVE_DELETED":
      case "PARTICIPANT_ADDED_TO_DRIVE":
      case "PARTICIPANT_REMOVED_FROM_DRIVE":
        this.dispatch(addLiveNotification(notificaion))
        break
      case "DELETED":
        this.dispatch(attemptFetchLiveNotifications())
        break
    }
  }
}
