import React from 'react'
import { Collapse } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { Chat as ChatInterface, setCurrentChatId } from './chatsSlice'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { getChatDetails } from '../../../features/room/RoomChat/ChatLibrary'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import CreateChat from './CreateChat'
import ChatMessageFileInList from './ChatMessageFileInList'
import { Squad } from '../Squad/squadListSlice'
import { Scrollbars } from 'react-custom-scrollbars-2'
import classnames from 'classnames'
import './ChatList.scss'
import './ModifyChatPeers.scss'
import { DeletedMessageContent } from './Message'
import { createSelector } from '@reduxjs/toolkit'

interface Props {
  inCall?: boolean
  currentSquadId: string
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
}

export default function ChatList({
  inCall,
  currentSquadId,
  setOpenResponsiveDrawerMenu,
}: Props) {
  const selectSquad = (state: RootState) => state.chatSquads.squads[currentSquadId]
  const selectChats = (state: RootState) => state.chats.chats[currentSquadId]
  const selectChatsDetails = (state: RootState) => state.chats.chatsDetails
  const { t } = useTranslation('chat')

  const chatsSelector = createSelector(
    [selectSquad, selectChats, selectChatsDetails],
    (squad, chats, chatsDetails) => {
      const p2pChats: React.ReactElement[] = []
      const groupChats: React.ReactElement[] = []
      const archivedChats: React.ReactElement[] = []

      const lastMessageChatSort = (a: ChatInterface, b: ChatInterface) => {
        if (a.title === 'EVERYBODY' && b.title !== 'EVERYBODY') {
          //keep geenral chat at the top of the list
          return -1
        } else if (a.title !== 'EVERYBODY' && b.title === 'EVERYBODY') {
          return 1
        }
        if (!chatsDetails[a.id] || !chatsDetails[b.id]) return 0
        return dayjs(
          chatsDetails[a.id].lastMessage
            ? chatsDetails[a.id].lastMessage?.createdAt
            : dayjs('1970-01-01'),
        ).isBefore(
          dayjs(
            chatsDetails[b.id].lastMessage
              ? chatsDetails[b.id].lastMessage?.createdAt
              : dayjs('1970-01-01'),
          ),
        )
          ? 1
          : -1
      }

      if (squad && chats) {
        let chatSorted
        if (chats[0].title === 'EVERYBODY') {
          chatSorted = [
            chats[0],
            ...chats.slice(1).sort((a, b) => lastMessageChatSort(a, b)),
          ]
        } else {
          chatSorted = [...chats.slice(1).sort((a, b) => lastMessageChatSort(a, b))]
        }
        for (const c of chatSorted || []) {
          if (c.archived) {
            archivedChats.push(
              <ChatListLine
                key={c.id}
                squad={squad}
                chat={c}
                setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
              />,
            )
          } else {
            if (c.everybody) {
              groupChats.push(
                <ChatListLine
                  key={c.id}
                  squad={squad}
                  chat={c}
                  setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                />,
              )
            } else if (c.peerToPeer) {
              p2pChats.push(
                <ChatListLine
                  key={c.id}
                  squad={squad}
                  chat={c}
                  setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                />,
              )
            } else {
              groupChats.push(
                <ChatListLine
                  key={c.id}
                  squad={squad}
                  chat={c}
                  setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                />,
              )
            }
          }
        }
      }
      return { p2pChats, groupChats, archivedChats }
    },
  )

  const { p2pChats, groupChats, archivedChats } = useSelector(chatsSelector)
  return (
    <>
      <CreateChat squadId={currentSquadId} />
      <Scrollbars className="ChatListComponent" autoHide>
        <div className={classnames({ 'mt-m1rem': inCall })}>
          <div>
            {groupChats.length > 0 && (
              <Collapse
                className="chat_group"
                collapsible="header"
                defaultActiveKey={['1']}
                ghost
                items={[
                  {
                    key: '1',
                    label: (
                      <>
                        <div className="chat_group_title">
                          <div className="mr-05rem">{t('Discussion channels')}</div>
                        </div>
                      </>
                    ),
                    children: groupChats,
                  },
                ]}
              />
            )}

            <Collapse
              className="chat_group"
              defaultActiveKey={['1']}
              collapsible="header"
              ghost
              items={[
                {
                  key: '1',
                  label: (
                    <div className="chat_group_title">
                      <div className="mr-05rem">{t('Private messages')}</div>
                    </div>
                  ),
                  children: p2pChats,
                },
              ]}
            />

            {archivedChats.length > 0 && (
              <Collapse
                className="chat_group"
                collapsible="header"
                defaultActiveKey={['1']}
                ghost
                items={[
                  {
                    key: '2',
                    label: (
                      <div className="chat_group_title">
                        <div className="mr-05rem">{t('Archives')}</div>
                      </div>
                    ),
                    children: archivedChats,
                  },
                ]}
              />
            )}
          </div>
        </div>
      </Scrollbars>
    </>
  )
}

interface ChatLineProps {
  chat: ChatInterface
  squad: Squad
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
}

function ChatListLine({ squad, chat, setOpenResponsiveDrawerMenu }: ChatLineProps) {
  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()
  const { name, icon, backgroundColor } = getChatDetails(chat)
  const chatsDetails = useSelector((state: RootState) => state.chats.chatsDetails)
  const lastMessage = chatsDetails[chat.id]?.lastMessage
  const unreadMessagesCount = chatsDetails[chat.id]?.unreadMessagesCount
  const currentChatId = useSelector((state: RootState) => state.chats.currentChat?.id)
  const peersStatuses = useSelector(
    (state: RootState) => state.chatSquads.peersStatuses[squad.id],
  )

  const messageInputDraft = useSelector(
    (state: RootState) => state.chats.messageInputDraft[chat.id],
  )

  function selectChat(chatId: string) {
    dispatch(setCurrentChatId(chatId))
    if (setOpenResponsiveDrawerMenu) {
      setOpenResponsiveDrawerMenu(false)
    }
  }

  function DisplayLastMessage() {
    if (!lastMessage)
      return (
        <div className="chat_item_no_messages">
          {t('Send a first message to this conversation')}
        </div>
      )
    else if (lastMessage.deleted)
      return (
        <>
          <DeletedMessageContent />
          {unreadMessagesCount > 0 && (
            <div className="chat_item_unread_messages_count">{unreadMessagesCount}</div>
          )}
        </>
      )
    else
      return (
        <>
          {lastMessage.text && (
            <div
              className="chat_item_last_message"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(lastMessage.text, {
                  allowedTags: [],
                }),
              }}
            />
          )}
          {lastMessage.files && <ChatMessageFileInList content={lastMessage.files} />}
          {unreadMessagesCount > 0 && (
            <div className="chat_item_unread_messages_count">{unreadMessagesCount}</div>
          )}
        </>
      )
  }

  return (
    <div
      key={chat.id}
      onClick={() => selectChat(chat.id)}
      className={classNames({
        chat_item: true,
        'chat_item--current': chat.id === currentChatId,
        'chat_item--unread': unreadMessagesCount > 0,
      })}
    >
      <div className="chat_item_avatar_container">
        <div className="chat_item_avatar" style={{ backgroundColor }}>
          {icon}
        </div>
        {chat.peerToPeer &&
          chat.peer &&
          peersStatuses &&
          peersStatuses[chat.peer.email] === 'ONLINE' && (
            <div className="chat_item_p2p_online" />
          )}
      </div>
      <div className="chat_item_details">
        <div className="chat_item_details_first">
          <div className="chat_item_title">
            <span>
              <span className="chat_item_name">{t(name, { ns: 'meetings' })}</span>
            </span>
          </div>
          <div className="chat_item_last_message_date">
            {lastMessage &&
              (dayjs().format('LL') === dayjs(lastMessage.createdAt).format('LL')
                ? dayjs(lastMessage.createdAt).format('HH:mm')
                : dayjs(lastMessage.createdAt).format('DD/MM'))}
          </div>
        </div>
        <div className="chat_item_details_second">
          {messageInputDraft?.text || messageInputDraft?.files ? (
            <>
              <span className="draft-indicator">{t('Draft:')} &nbsp;</span>
              {messageInputDraft.text && (
                <div
                  className="chat_item_last_message"
                  dangerouslySetInnerHTML={{
                    //TODO: translate Draft
                    __html: sanitizeHtml(messageInputDraft.text, {
                      allowedTags: [],
                    }),
                  }}
                />
              )}
              {messageInputDraft.files && messageInputDraft.files.length > 0 && (
                <ChatMessageFileInList
                  content={JSON.stringify(
                    messageInputDraft.files.map((f) => {
                      return {
                        id: f.response.id,
                        size: f.response.size,
                        mimeType: f.response.mimeType,
                        name: f.response.name,
                      }
                    }),
                  )}
                />
              )}
            </>
          ) : (
            <DisplayLastMessage />
          )}
        </div>
      </div>
    </div>
  )
}
