import React, { MouseEvent } from 'react'
import { Button, Empty, Tooltip } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { BsDownload } from 'react-icons/bs'
import { IoArrowBack, IoCloseOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import {
  ContentTypes,
  resetSelectedFilesFolders,
} from '../../features/Drives/redux/drivesSlice'
import { AppDispatch, RootState } from '../../store'
import { downloadEncryptedFile, renderIcon } from '../../utils/Utils'
import './viewer.scss'
import { idleViewer, setFileToView } from './ViewerSlice'
import classNames from 'classnames'
import useEventListener from '../../utils/hooks/useEventListener'

const SupportedTypesBrowser = {
  PDF: ContentTypes.PDF,
  TXT: ContentTypes.TXT,
}

interface Props {
  fullSize?: boolean
}

function Viewer({ fullSize }: Props) {
  const { t } = useTranslation('drives')
  const fileToView = useSelector((state: RootState) => state.viewer.fileToView)
  const encryptKey = fileToView?.encryptKey
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const { id, name, mimeType } = fileToView?.file || {}
  const dispatch = useDispatch<AppDispatch>()

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (fileToView && e.key === 'Escape') {
      resetViewer()
    }
  })

  if (!id || !name) {
    return <></>
  }

  const resetViewer = () => {
    dispatch(idleViewer())
    dispatch(resetSelectedFilesFolders())
  }

  /**
   *
   * @param e
   * @param id
   * @param encryptKey
   */
  function download(e: MouseEvent) {
    if (encryptKey && id && encryptKey) {
      downloadEncryptedFile(id, encryptKey)
      e.stopPropagation()
    }
  }

  /**
   *
   * @returns
   */
  function renderWrapper() {
    if (id && name) {
      if (mimeType === ContentTypes.DCM || name.toLowerCase().endsWith('.dcm')) {
        //Windows removes mime type when download dicom
        return (
          <div className="Viewer-wrapper">
            <iframe
              src={`${window.location.origin}/u-dicom-viewer?drive_id=${selectedDriveFolder?.driveId}&file_id=${id}&filename=${name}&key=${encryptKey}`}
            />
          </div>
        )
      } else if (mimeType && Object.values(SupportedTypesBrowser).includes(mimeType)) {
        return (
          <div className="Viewer-wrapper">
            <iframe
              src={`${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${id}/${encryptKey}`}
            />
          </div>
        )
      } else if (mimeType && mimeType.includes('image')) {
        return (
          <div className="Viewer-wrapper Viewer-wrapper-transparent">
            <img
              src={`${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${id}/${encryptKey}`}
              alt={id}
            />
          </div>
        )
      } else if (mimeType && mimeType.includes('video')) {
        return (
          <div className="Viewer-wrapper Viewer-wrapper-transparent">
            <video
              src={`${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${id}/${encryptKey}`}
              autoPlay
              controls
            />
          </div>
        )
      } else {
        return (
          <div className="Viewer-wrapper Viewer-wrapper-transparent">
            <div className="unsupported">
              <h3 className="mb-0">
                <Trans
                  ns="drives"
                  i18nKey="NOT_SUPPORTED_VIEWER"
                  values={{
                    name: name.split('.').pop(),
                  }}
                  components={{ bold: <strong /> }}
                />
              </h3>
              <Empty description="" />
              <Button onClick={(e) => download(e)}>
                <Button type="link" className="p-0" icon={<BsDownload />}>
                  {t('Download')}
                </Button>
              </Button>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <>
      {id && name ? (
        <div className={classNames('Viewer', { 'Viewer-fullSize': fullSize })}>
          <div className="Viewer-control-bar">
            <div className="Viewer-control-bar-left">
              <div className="action-rounded-icon" onClick={resetViewer}>
                <IoArrowBack size="1.5em" className="back" />
              </div>
            </div>
            <div className="Viewer-control-bar-center">
              <img
                className="file-icon mb-05rem"
                src={renderIcon(mimeType ?? '', name)}
              />
              <span className="title">{name}</span>
            </div>
            <div className="Viewer-control-bar-right">
              <Tooltip title={t('Download')} placement="bottom">
                <div className="action-rounded-icon" onClick={(e) => download(e)}>
                  <BsDownload size="1.5em" />
                </div>
              </Tooltip>
              <Tooltip title={t('Close', { ns: 'common' })} placement="bottom">
                <div className="action-rounded-icon" onClick={resetViewer}>
                  <IoCloseOutline size="1.5em" className="back" />
                </div>
              </Tooltip>
            </div>
          </div>
          {renderWrapper()}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Viewer
