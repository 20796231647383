import React, { ChangeEvent, RefObject } from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useTranslation } from 'react-i18next'
import { limitText } from '../../../utils/Utils'
import {
  UploadedFile,
  addEncryptDriveFiles,
  idleAddDriveFilesStatus,
  setContextMenuVisible,
  setUploadListFiles,
} from '../../../features/Drives/redux/drivesSlice'
import { filterFileListBySize } from '../DrivesService'
import { useToastContext } from '../../Toast/ToastContext'

interface Props {
  inputFile: RefObject<HTMLInputElement>
}

function DrivesFileUpload({ inputFile }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('drives')
  const auth = useSelector((state: RootState) => state.auth)
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const driveEncryptKey = useSelector(
    (state: RootState) =>
      state.drive.drives?.find((drive) => drive.id === selectedDriveFolder?.driveId)
        ?.fileEncryptKey,
  )
  const addDriveFilesStatus = useSelector(
    (state: RootState) => state.drive.addDriveFilesStatus,
  )
  const setUploadingFiles = (files: UploadedFile[]) => {
    dispatch(setUploadListFiles(files))
  }
  const { ToastOpen } = useToastContext()

  useAttemptsListener([
    [
      addDriveFilesStatus.status,
      {
        success_single_file: () => {
          ToastOpen({
            message: t('FILE_ADDED', {
              ns: 'drives',
              shouldUnescape: true,
              name: limitText(addDriveFilesStatus.info, 30),
              components: { bold: <strong /> },
            }),
            type: 'success',
          })
        },
        success_some_files: () => {
          ToastOpen({
            message: t('Some files have been imported successfully.'),
            type: 'success',
          })
        },
        success_multiple_files: () => {
          ToastOpen({
            message: t('Files have been imported successfully.'),
            type: 'success',
          })
        },
        NOT_ENOUGH_FILE_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to add files to this project.',
            ),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error adding file.'),
            type: 'error',
          })
        },
      },
      () => {
        setTimeout(() => setUploadingFiles([]), 3000)
        dispatch(idleAddDriveFilesStatus())
      },
    ],
  ])

  /**
   *
   * @param e
   */
  const handleFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContextMenuVisible(false))
    e.preventDefault()
    if (e.target.files && selectedDriveFolder?.driveId && driveEncryptKey) {
      const { filesToAdd, filesToRemove } = filterFileListBySize(e.target.files)

      if (filesToAdd.length > 0 && auth.email && auth.firstName && auth.lastName) {
        dispatch(
          addEncryptDriveFiles({
            driveId: selectedDriveFolder?.driveId,
            folderId: selectedDriveFolder.id,
            driveEncryptKey,
            creatorEmail: auth.email,
            creatorFirstName: auth.firstName,
            creatorLastName: auth.lastName,
            files: filesToAdd,
            filesBlocked: filesToRemove.length > 0,
          }),
        )
      }

      if (filesToRemove.length > 0) {
        filesToRemove.forEach((file) => {
          ToastOpen({
            message: t('FILE_TOO_LARGE', {
              ns: 'drives',
              shouldUnescape: true,
              name: limitText(file.name, 30),
              size: process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA,
              components: { bold: <strong /> },
            }),
            type: 'error',
          })
        })
      }
    }
    e.target.value = ''
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  return (
    <>
      <Form.Item getValueFromEvent={normFile} className="mb-0">
        <label>
          {t('Import file')}
          <input
            type="file"
            onChange={handleFilesChange}
            multiple
            style={{ display: 'none' }}
            ref={inputFile}
          />
        </label>
      </Form.Item>
    </>
  )
}

export default DrivesFileUpload
