import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Collapse, Divider, Dropdown, Form, MenuProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  FolderInterface,
  setCreateDrive,
  setDriveToDelete,
  setDriveToModify,
  setOpenCreateFolder,
  setSelectedDriveFolder,
} from '../../../features/Drives/redux/drivesSlice'
import './DrivesMenu.scss'
import { useTranslation } from 'react-i18next'
import DriveMenuItem from './DriveMenuItem'
import CreateDriveModal from '../CreateDrive/CreateDriveModal'
import UpdateDriveModal from '../CreateDrive/UpdateDriveModal'
import { Scrollbars } from 'react-custom-scrollbars-2'
import DeleteDriveModal from '../CreateDrive/DeleteDriveModal'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { IoAddCircle } from 'react-icons/io5'
import { LuHardDriveDownload } from 'react-icons/lu'
import { MdOutlineCreateNewFolder, MdOutlineUploadFile } from 'react-icons/md'
import DrivesFileUpload from '../DrivesHeader/DrivesFileUpload'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import { useToastContext } from '../../Toast/ToastContext'

interface Props {
  drivesFolders: FolderInterface[]
}

export interface FolderTreeData {
  key: React.Key
  title: string
  children: any
  createdAt: string
  driveId: string
}

/**
 *
 * @param param0
 * @returns
 */
function DrivesMenu({ drivesFolders }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const [treeDriveFolders, setTreeDriveFolders] = useState<FolderTreeData[]>([])
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const fetchDrivesStatus = useSelector(
    (state: RootState) => state.drive.fetchDrivesStatus,
  )
  const deleteDriveStatus = useSelector(
    (state: RootState) => state.drive.deleteDriveStatus,
  )
  const rights = useSelector((state: RootState) => state.drive.driveRights)
  const { creatorEmail } = useDriveLoader(selectedDriveFolder?.driveId)
  const auth = useSelector((state: RootState) => state.auth)
  const inputFile = useRef<HTMLInputElement>(null)
  const items: MenuProps['items'] = [
    {
      key: 'create_drive',
      className: 'menu-create-drive',
      label: (
        <>
          <Form.Item className="mb-0">
            <label>{t('Create drive')}</label>
          </Form.Item>
        </>
      ),
      onClick: () => dispatch(setCreateDrive(true)),
      icon: <LuHardDriveDownload size="1rem" />,
    },
  ]

  if (selectedDriveFolder) {
    if (rights?.folderRights || creatorEmail === auth.email) {
      items.push(
        {
          type: 'divider',
        },
        {
          key: 'create_folder',
          className: 'menu-create-drive',
          label: (
            <>
              <Form.Item className="mb-0">
                <label>{t('Create folder')}</label>
              </Form.Item>
            </>
          ),
          onClick: () => dispatch(setOpenCreateFolder(true)),
          icon: <MdOutlineCreateNewFolder size="1rem" />,
        },
      )
    }
    if (rights?.fileRights || creatorEmail === auth.email) {
      items.push({
        key: 'import_file',
        className: 'menu-create-drive',
        label: (
          <span onClick={(event) => event.stopPropagation()}>
            <DrivesFileUpload inputFile={inputFile} />
          </span>
        ),
        icon: <MdOutlineUploadFile size="1rem" />,
        onClick: () => inputFile.current?.click(),
      })
    }
  }

  useAttemptsListener([
    [
      deleteDriveStatus,
      {
        success: () => {
          dispatch(setDriveToDelete(undefined))
          dispatch(setDriveToModify(undefined))
          ToastOpen({
            message: t('Drive was successfully deleted.'),
            type: 'success',
          })
        },
        error: () =>
          ToastOpen({
            message: t('Error deleting drive.'),
            type: 'error',
          }),
      },
    ],
  ])
  /**
   *
   */
  useEffect(() => {
    const treeDrivesData = setDrivesTreeMemoized(drivesFolders)
    if (treeDrivesData) {
      setTreeDriveFolders(treeDrivesData)
    }

    if (drivesFolders && !selectedDriveFolder) {
      dispatch(setSelectedDriveFolder({ folder: drivesFolders[0] }))
    }
  }, [drivesFolders])

  /**
   *
   */
  const setDrivesTreeMemoized = useCallback(
    (driveFolders: FolderInterface[]): FolderTreeData[] | undefined => {
      if (driveFolders) {
        return driveFolders.map((driveFolder: FolderInterface) => {
          const childrenNodes = setDrivesTreeMemoized(driveFolder.childrenFolders || [])

          return {
            key: driveFolder.id,
            title: driveFolder.name,
            children: childrenNodes,
            createdAt: driveFolder.createdAt?.toString(),
            driveId: driveFolder.driveId,
          }
        })
      }
    },
    [],
  )

  function myDrives() {
    return treeDriveFolders.map((folder: FolderTreeData, index) => (
      <div key={folder.key}>
        <DriveMenuItem folder={folder} drivesFolders={drivesFolders} />
        {index !== treeDriveFolders.length - 1 && <Divider className="m-0" />}
      </div>
    ))
  }

  return (
    <>
      <div className="menu-action-btn-container">
        <Dropdown menu={{ items }} trigger={['click']}>
          <Button
            className="menu-action-btn"
            type="primary"
            icon={<IoAddCircle size="1.5rem" color="white" />}
          >
            {t('New', { ns: 'common' })}
          </Button>
        </Dropdown>
      </div>
      <Scrollbars autoHide className="DrivesMenu">
        {fetchDrivesStatus === 'success' && (
          <Collapse
            className="menu_group"
            collapsible="header"
            defaultActiveKey={['1']}
            ghost
            items={[
              {
                key: '1',
                label: (
                  <>
                    <div className="menu_group_title">
                      <div className="mr-05rem">{t('My drives')}</div>
                    </div>
                  </>
                ),
                children: myDrives(),
                extra: <CreateDriveModal />,
              },
            ]}
          />
        )}

        <UpdateDriveModal />
        <DeleteDriveModal />
      </Scrollbars>
    </>
  )
}

export default DrivesMenu
