import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import Notifier from './Notifier'
import { Popover, Tooltip, Drawer } from 'antd'
import { IoIosNotificationsOutline } from 'react-icons/io'
import { Trans, useTranslation } from 'react-i18next'
import {
  attemptDeleteAllNotifications,
  attemptFetchLiveNotifications,
  attemptMarkAllNotificationsSeen,
} from './liveWidgetSlice'
import './NotificationWidget.scss'
import { Link } from 'react-router-dom'
import { IoCheckmarkCircleOutline, IoTrashOutline } from 'react-icons/io5'
import { BsListColumnsReverse } from 'react-icons/bs'
import NotificationList from './NotificationList'
import useSound from 'use-sound'
import sound from './assets/notifications.mp3'

function NotificationWidget() {
  const dispatch = useDispatch<AppDispatch>()
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const unseen = useSelector((state: RootState) => state.liveNotification.unseen)
  const [oldUnseen, setOldUnseen] = useState(unseen)
  const notifier = useRef(new Notifier(dispatch))
  const [play] = useSound(sound, {
    interrupt: true,
  })
  const [drawerVisible, setDrawerVisible] = useState(false)

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible)
  }

  useEffect(() => {
    if (!jwt) {
      return
    }

    dispatch(attemptFetchLiveNotifications())
    notifier.current.connect(jwt)

    return () => {
      if (!jwt) {
        notifier.current.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (unseen != oldUnseen) {
      if ((unseen as number) > (oldUnseen as number)) {
        play()
      }

      setOldUnseen(unseen)
    }
  }, [unseen])

  return (
    <>
      <Popover
        content={<Content />}
        trigger="click"
        color="#f6f6f6"
        placement="bottomRight"
        className="hideOnMobile"
        arrow={{ pointAtCenter: true }}
        align={{ offset: [21, 14] }}
      >
        <div className="notification_widget">
          <IoIosNotificationsOutline size="2em" />
          {unseen !== undefined && unseen > 0 && (
            <div className="notification_widget_unseen_count">{unseen}</div>
          )}
        </div>
      </Popover>
      <div className="notification_widget" onClick={toggleDrawer}>
        <IoIosNotificationsOutline size="2em" />
        {unseen !== undefined && unseen > 0 && (
          <div className="notification_widget_unseen_count">{unseen}</div>
        )}
        <Drawer
          width="40rem"
          title="Notifications"
          placement="right"
          onClose={toggleDrawer}
          open={drawerVisible}
        >
          <Content />
        </Drawer>
      </div>
    </>
  )
}

function Content() {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch<AppDispatch>()
  const notifications = useSelector(
    (state: RootState) => state.liveNotification.notifications,
  )
  const total = useSelector((state: RootState) => state.liveNotification.total)

  return (
    <div className="notification_widget_content">
      {notifications.length > 0 ? (
        <>
          <div className="notification_widget_content_actions">
            <Link to="/notifications" className="notification_widget_content_action">
              <BsListColumnsReverse />
              &nbsp;
              <span className="notification_widget_content_all">
                {t('See all notifications')}
              </span>
            </Link>

            <div className="mt-0_2rem">
              <Tooltip title={t('Mark all as read')}>
                <IoCheckmarkCircleOutline
                  onClick={() => dispatch(attemptMarkAllNotificationsSeen())}
                  className="notification_widget_content_action"
                />
              </Tooltip>

              <Tooltip title={t('Delete all')}>
                <IoTrashOutline
                  onClick={() => dispatch(attemptDeleteAllNotifications())}
                  className="notification_widget_content_action"
                />
              </Tooltip>
            </div>
          </div>
          {notifications.map((notification) => (
            <NotificationList notification={notification} key={notification.id} />
          ))}
        </>
      ) : (
        <div>{t('No notification')}</div>
      )}
    </div>
  )
}

export default NotificationWidget
