import React, { createContext, useContext, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UploadFile } from 'antd'
import { AppDispatch, RootState } from '../../store'
import {
  FolderInterface,
  fetchFolderById,
  setExpandedFolderKeys,
  setSelectedDriveFolder,
} from './redux/drivesSlice'
import { findFolderById } from '../../components/DrivesComponent/DrivesService'

interface DrivesContextProps {
  fetchOrSelectFolder: (row: FolderInterface) => void
  onFolderOpen: (row: FolderInterface) => void
  files: UploadFile<any>[] | undefined
  setFiles: React.Dispatch<React.SetStateAction<UploadFile<any>[] | undefined>>
}

interface DriveProviderProps {
  children: React.ReactNode
}

const DrivesContext = createContext<DrivesContextProps | undefined>(undefined)

export const useDrivesContext = () => {
  const context = useContext(DrivesContext)
  if (!context) {
    throw new Error('useDrivesContext must be used within a DriveProvider')
  }
  return context
}

const DriveProvider: React.FC<DriveProviderProps> = ({ children }) => {
  const [files, setFiles] = useState<UploadFile<any>[] | undefined>(undefined)
  const drives = useSelector((state: RootState) => state.drive.drives)
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const expandedKeys = useSelector((state: RootState) => state.drive.expandedFolderKeys)
  const dispatch = useDispatch<AppDispatch>()

  const onFolderOpen = (row: FolderInterface) => {
    if (!drives) return
    const parentFolder = selectedDriveFolder

    const folder = findFolderById(
      drives.map((drive) => drive.rootFolder),
      row,
      row.id,
    )

    if (folder) {
      fetchOrSelectFolder(folder)
    }

    if (parentFolder && !expandedKeys.includes(parentFolder?.id)) {
      dispatch(setExpandedFolderKeys([...expandedKeys, parentFolder?.id]))
    }
  }

  const fetchOrSelectFolder = (folder: FolderInterface) => {
    if (
      (folder.childrenFolders &&
        folder.childrenFolders.length > 0 &&
        typeof folder.childrenFolders[0] === 'string') ||
      (folder.childrenFiles.length > 0 && typeof folder.childrenFiles[0] === 'string')
    ) {
      dispatch(
        fetchFolderById({
          id: folder.id,
          driveId: folder.driveId,
          selected: true,
        }),
      )
    } else {
      dispatch(setSelectedDriveFolder({ folder }))
    }
  }

  const memoizedValue = useMemo(
    () => ({
      fetchOrSelectFolder,
      onFolderOpen,
      files,
      setFiles,
    }),
    [fetchOrSelectFolder, onFolderOpen, files, setFiles],
  )

  return <DrivesContext.Provider value={memoizedValue}>{children}</DrivesContext.Provider>
}

export default DriveProvider
