import { Col, Form, Row, Switch } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

function AccountNotifications() {
  const { t } = useTranslation('account')

  return (
    <Row gutter={[24, 8]}>
      <Col span={24} className="account-notification">
        <Form.Item
          label={t('Reminder email 30 minutes before my meeting')}
          name="emailMeetingReminder"
          valuePropName="checked"
          className="account-notification-item"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('Meeting invitation email')}
          name="emailMeetingInvitation"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('Meeting modification email')}
          name="emailMeetingUpdate"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('Email invitation to indicate availability for a meeting')}
          name="emailMeetingVote"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('Reminder email when a participant joins your meeting')}
          name="emailMeetingJoin"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default AccountNotifications
