import React, { MouseEvent, useEffect, useState } from 'react'
import { Tooltip, Tree } from 'antd'
import type { DirectoryTreeProps, EventDataNode } from 'antd/es/tree'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  DriveInterface,
  FolderInterface,
  fetchFolderById,
  setAdminRight,
  setDriveToModify,
  setExpandedFolderKeys,
  setSelectedDriveFolder,
} from '../../../features/Drives/redux/drivesSlice'
import './DrivesMenu.scss'
import { findFolderById, isDriveAdmin } from '../DrivesService'
import { MdOutlineFolderShared } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { FolderTreeData } from './DrivesMenu'
import { useDrivesContext } from '../../../features/Drives/DrivesContext'

const { DirectoryTree } = Tree

interface Props {
  folder: FolderTreeData
  drivesFolders: FolderInterface[]
}

function DriveMenuItem({ folder, drivesFolders }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { fetchOrSelectFolder } = useDrivesContext()
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const drives = useSelector((state: RootState) => state.drive.drives)
  const email = useSelector((state: RootState) => state.auth.email)
  const expandedKeys = useSelector((state: RootState) => state.drive.expandedFolderKeys)
  const groups = useSelector((state: RootState) => state.auth.groups)

  function showDrive(e: MouseEvent, drive: DriveInterface) {
    dispatch(setAdminRight(isDriveAdmin(drive, email, groups)))
    dispatch(setDriveToModify({ drive }))
    e.stopPropagation()
  }

  /**
   *
   */
  function renderTreeIcon(folder: FolderTreeData) {
    const drive = drives?.find((drive) => drive.id === folder.driveId)

    if (drive) {
      const isAdmin = isDriveAdmin(drive, email, groups)

      if (isAdmin) {
        return (
          <Tooltip title={t('Manage access rights')}>
            <AiOutlineUsergroupAdd size="1.3em" onClick={(e) => showDrive(e, drive)} />
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title={t('View access rights')}>
            <AiOutlineUser size="1.2em" onClick={(e) => showDrive(e, drive)} />
          </Tooltip>
        )
      }
    }
  }

  /**
   *
   * @param keys
   * @param info
   */
  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    const nodeInfo = info.node as EventDataNode<FolderInterface>

    const folder = findFolderById(drivesFolders, nodeInfo, nodeInfo.key)
    if (folder) {
      fetchOrSelectFolder(folder)
    }
  }

  /**
   *
   * @param keys
   * @param info
   */
  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
    const nodeInfo = info.node as EventDataNode<FolderInterface>
    if (nodeInfo.active == false) {
      const folder = findFolderById(drivesFolders, nodeInfo, nodeInfo.key)
      if (folder) {
        if (
          folder.childrenFolders &&
          folder.childrenFolders.length > 0 &&
          typeof folder.childrenFolders[0] === 'string'
        ) {
          dispatch(fetchFolderById({ id: folder.id, driveId: folder.driveId }))
        }
      }
      dispatch(setExpandedFolderKeys(keys))
    }
  }

  return (
    <DirectoryTree
      onExpand={onExpand}
      onSelect={onSelect}
      treeData={[
        {
          ...folder,
          icon: (
            <MdOutlineFolderShared
              className="DrivesMenu-project-title-icon"
              size="1.2em"
            />
          ),
          title: (
            <span className="DrivesMenu-project-title-container">
              <span className="title-folder">{folder.title}</span>
              <span className="group-icon">{renderTreeIcon(folder)}</span>
            </span>
          ),
        },
      ]}
      selectedKeys={selectedDriveFolder ? [selectedDriveFolder?.id] : []}
      expandedKeys={expandedKeys}
    />
  )
}

export default DriveMenuItem
