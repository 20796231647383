import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import './ChatMessages.scss'
import {
  Chat,
  ChatMessage,
  setMessageInputDraftEditMsg,
  setMessageInputDraftReplyMsg,
} from './chatsSlice'
import { useTranslation } from 'react-i18next'
import { Modal, Tooltip } from 'antd'
import './ChatMessages.scss'
import { IoTrashOutline } from 'react-icons/io5'
import { TbEdit, TbEditOff } from 'react-icons/tb'
import _ from 'lodash'
import { BsFillReplyFill } from 'react-icons/bs'
import { LiaTrashRestoreSolid } from 'react-icons/lia'
import sanitizeHtml from 'sanitize-html'
import ChatMessageFile from './ChatMessageFile'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import { ViewFile } from '../../Viewer/ViewerSlice'
import { renderIcon } from '../../../utils/Utils'

// import { MessageProps } from './Message'

// interface ChatMessageActions extends MessageProps {
//   scrollToMessage: (messageId: string) => void
// }
const { confirm } = Modal

interface Props {
  chat: Chat
  message: ChatMessage
  citation?: true
  // scrollToMessage: (messageId: string) => void
}

export default function ChatMessageActions({
  chat,
  message,
  citation,
  // scrollToMessage,
}: Props) {
  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()
  const ws = useContext(WebSocketConnectionContext)

  const messageToEdit = useSelector(
    (state: RootState) => state.chats.messageInputDraft[chat.id]?.messageToEdit,
  )

  const username = useSelector((state: RootState) => state.auth.email)

  const OwnActions = () => {
    return (
      <>
        <Tooltip
          title={t(messageToEdit?.id === message.id ? 'Cancel edit' : 'Edit', {
            ns: 'common',
          })}
        >
          <div
            className="chat_action"
            onClick={() => {
              messageToEdit?.id === message.id
                ? dispatch(setMessageInputDraftEditMsg({ chatId: chat.id })) //maybe completely reset?
                : dispatch(
                    setMessageInputDraftEditMsg({
                      messageToEdit: message,
                      chatId: chat.id,
                    }),
                  )
            }}
          >
            {messageToEdit?.id === message.id ? (
              <TbEditOff size="1.2rem" />
            ) : (
              <TbEdit size="1.2rem" />
            )}
          </div>
        </Tooltip>
        <Tooltip title={t('Delete', { ns: 'common' })}>
          <div
            className="chat_action chat_action_delete"
            onClick={() =>
              confirm({
                centered: true,
                title: t('Delete message'),
                icon: null,
                content: (
                  <p>
                    {t('Are you sure you want to delete this message?')}
                    <div className="message-delete-preview">
                      {message?.text && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(message?.text, {
                              allowedTags: [
                                'b',
                                'p',
                                'strong',
                                'em',
                                's',
                                'u',
                                'li',
                                'ul',
                                'ol',
                                'br',
                              ],
                            }),
                          }}
                        />
                      )}
                      {message?.files && (
                        <DeleteChatMessageFile content={message.files} />
                      )}
                    </div>
                  </p>
                ),
                okText: t('Yes'),
                cancelText: t('No'),
                onOk: () =>
                  ws?.markChatMessageDeleted(chat.squadId, chat.id, message.id, true),
                maskClosable: true,
                closable: true,
              })
            }
          >
            <IoTrashOutline size="1.2rem" />
          </div>
        </Tooltip>
      </>
    )
  }

  const CommonActions = () => {
    return (
      <Tooltip title={t('Reply', { ns: 'common' })}>
        <div
          className={`chat_action ${
            message.peerEmail === username
              ? 'chat_action_reply_own'
              : 'chat_action_reply_only'
          }`}
          onClick={() =>
            dispatch(
              setMessageInputDraftReplyMsg({
                chatId: chat.id,
                messageToReply: message,
              }),
            )
          }
        >
          <BsFillReplyFill size="1.2rem" />
        </div>
      </Tooltip>
    )
  }

  const RestoreMessageAction = () => {
    return (
      <>
        <Tooltip title={t('Restore', { ns: 'common' })}>
          <div
            className={`chat_action`}
            onClick={() =>
              ws?.markChatMessageDeleted(chat.squadId, chat.id, message.id, false)
            }
          >
            <LiaTrashRestoreSolid size="1.4rem" />
          </div>
        </Tooltip>
      </>
    )
  }

  return (
    <div className="chat_actions_hover chat_actions_hover--global">
      {message.deleted
        ? message.peerEmail === username && <RestoreMessageAction />
        : !citation && (
            <>
              <CommonActions />
              {message.peerEmail === username && <OwnActions />}
            </>
          )}
    </div>
  )
}

function DeleteChatMessageFile({ content }: { content: string }) {
  const files: ViewFile[] = JSON.parse(content)
  return (
    <>
      {files.length > 0 && (
        <div className="chat_message_images_container">
          {files.map((f) => (
            <div className="chat_message_image" key={f.id}>
              {f.mimeType && f.name && (
                <div
                  className={'chat_message_icon_name chat_message_icon_name--deletable'}
                >
                  <img src={renderIcon(f.mimeType, f.name)} className="file-icon" />
                  <div className="name">{f.name}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
