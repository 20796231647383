import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { Button, Card } from 'antd'
import Meta from 'antd/es/card/Meta'
import './Applications.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LOGO } from '../../assets/logos'
// import { useDispatch, useSelector } from 'react-redux'
// import { AppDispatch, RootState } from '../../store'
// import ModalUnauthorizedAccess from '../../components/Modal/ModalUnauthorizedAccess'

function Applications() {
  const { t } = useTranslation('applications')
  const navigate = useNavigate()
  // const dispatch = useDispatch<AppDispatch>()
  // const organisationName = useSelector(
  //   (state: RootState) => state.organisation.organisation?.name,
  // )
  // const unAuthorizedAcces = useSelector((state: RootState) => state.app.isUnauthorized)

  const applicationsData = [
    {
      title: 'Meetings',
      class: 'auxameetings-card',
      logo: LOGO.logo_auxameetings,
      description: t('Encrypted and secure videoconferences'),
      description_back: t(
        'Organise and take part in collaborative, sovereign and notarised videoconferences.',
      ),
      action: () => navigate('/upcoming-meetings'),
      coming_soon: false,
    },
    {
      title: 'Planning',
      class: 'auxaplanning-card',
      logo: LOGO.logo_auxaplanning,
      description: t('Encrypted and secure planning management'),
      description_back: t('Plan and manage your meetings and events in your calendar.'),
      action: () => navigate('/calendar'),
      coming_soon: false,
    },
    {
      title: 'Team',
      class: 'auxateam-card',
      logo: LOGO.logo_auxateam,
      description: t('Encrypted and secure instant messaging system'),
      description_back: t(
        'Exchange and collaborate securely via your instant messaging system with your colleagues and partners.',
      ),
      action: () => navigate('/messages'),
      coming_soon: false,
    },
    {
      title: 'Drive',
      class: 'auxadrive-card',
      logo: LOGO.logo_auxadrive,
      description: t('Encrypted and secure documents storage'),
      description_back: t(
        'Share and organise your documents by workgroup and manage access to them.',
      ),
      action: () => navigate('/drives'),
      coming_soon: false,
    },
    {
      title: 'Transfer',
      class: 'auxatransfer-card',
      logo: LOGO.logo_auxatransfer,
      description: t('Encrypted and secure file transfer'),
      description_back: t(
        'Share your large documents in the application or by email by managing the lifespan of the access link.',
      ),
      coming_soon: true,
    },
    {
      title: 'Auxadoc',
      class: 'auxadoc-card',
      logo: LOGO.logo_auxadoc,
      description: t('Encrypted and secure Medicascopy and teleconsultation'),
      description_back: t(
        'Medicascopy and a teleconsultation solution to connect patients and doctors.',
      ),
      action: () => window.open('https://medecin-demo.temma.care', '_blank'),
      coming_soon: false,
    },
  ]

  function renderApplications() {
    return (
      <div className="applications-content applications-content--desktop">
        {applicationsData.map((app, index) => (
          <div className="flip-card" key={index} onClick={app.action}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Card hoverable className={app.class}>
                  <div className="card-logo-container">
                    <img src={app.logo} className="card-logo" />
                  </div>
                  <Meta title={app.title} description={app.description} />
                </Card>
              </div>
              <div className="flip-card-back">
                <Card hoverable className={app.class}>
                  <div className="card-logo-container">
                    <img src={app.logo} className="card-logo" />
                    <h3>{app.title}</h3>
                  </div>
                  <div className="card-desc-container">
                    <Meta description={app.description_back} />
                    {app.coming_soon ? (
                      <Button
                        type="primary"
                        className="btn-disabled"
                        key={app.title}
                        disabled
                      >
                        {t('Coming soon', { ns: 'layout' })}
                      </Button>
                    ) : (
                      <Button type="primary" onClick={app.action} key={app.title}>
                        {t('Access')}
                      </Button>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  function renderApplicationsMobile() {
    return (
      <div className="applications-content applications-content--mobile">
        {applicationsData.map((app, index) => (
          <Card hoverable className={app.class} key={index} onClick={app.action}>
            <div className="card-logo-container">
              <img src={app.logo} className="card-logo" />
              <h3>{app.title}</h3>
            </div>
          </Card>
        ))}
      </div>
    )
  }

  return (
    <>
      <Layout
        footer={true}
        background={true}
        content={
          <div className="applications">
            {renderApplications()}
            {renderApplicationsMobile()}
          </div>
        }
      />
      {/* <ModalUnauthorizedAccess
        unAuthorizedAcces={unAuthorizedAcces}
        organisationName={organisationName}
      /> */}
    </>
  )
}

export default Applications
