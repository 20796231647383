import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../store"
import { NotificationsItem } from "../../features/Notifications/Notifications"
import { Notification } from '../../components/NotificationWidget/liveWidgetSlice'
import { attemptFetchNotifications } from '../../features/Notifications/notificationsSlice'
import { useAttemptsListener } from 'auxasphere-react-kit'


interface Props {
    notificationsItem: NotificationsItem
    setNotificationsDisplayed: (notifications: Notification[] | undefined) => void
}


export default function useNotificationDisplayed({ notificationsItem, setNotificationsDisplayed } : Props) {
    const dispatch = useDispatch<AppDispatch>()
    const notifications = useSelector(
        (state: RootState) => state.notifications.notifications,
    )
    const attemptFetchNotificationsStatus = useSelector(
        (state: RootState) => state.notifications.attemptFetchNotificationsStatus,
      )

    useEffect(() => {
        if (notifications) {
          switch (notificationsItem) {
            case NotificationsItem.Meetings:
              setNotificationsDisplayed(
                notifications.filter((n) => n.type.toString().includes('MEETING')),
              )
              break
            case NotificationsItem.Planning:
              setNotificationsDisplayed(
                notifications.filter((n) => n.type.toString().includes('PLANNING')),
              )
              break
            case NotificationsItem.Team:
              setNotificationsDisplayed(
                notifications.filter((n) => n.type.toString().includes('CHAT')),
              )
              break
            case NotificationsItem.Drive:
              setNotificationsDisplayed(
                notifications.filter((n) => n.type.toString().includes('DRIVE')),
              )
              break
            case NotificationsItem.Transfer:
              setNotificationsDisplayed(
                notifications.filter((n) => n.type.toString().includes('TRANSFER')),
              )
              break
            case NotificationsItem.Organization:
              setNotificationsDisplayed(
                notifications.filter((n) => n.type.toString().includes('ORGANIZATION')),
              )
              break
            default:
              dispatch(attemptFetchNotifications())
              setNotificationsDisplayed(notifications)
              break
          }
        }
      }, [notificationsItem])

    useAttemptsListener([
    [
        attemptFetchNotificationsStatus,
        {
        success: () => {
            setNotificationsDisplayed(notifications)
        },
        },
    ],
    ])

}