import React from 'react'
import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useTranslation } from 'react-i18next'

interface SelectLanguageProps {
  className?: string
  disabled?: boolean
  onChange?: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void
}

function SelectLanguage({ onChange, className, disabled }: SelectLanguageProps) {
  const { i18n } = useTranslation()

  return (
    <Select
      defaultValue={i18n.language}
      onChange={onChange}
      className={className}
      disabled={disabled}
    >
      <Select.Option value="de">Deutsch</Select.Option>
      <Select.Option value="en">English</Select.Option>
      <Select.Option value="fr">Français</Select.Option>
      <Select.Option value="it">Italiano</Select.Option>
      <Select.Option value="uk">Українська</Select.Option>
    </Select>
  )
}

export default SelectLanguage
