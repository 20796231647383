
import auxamed_inline_light from'auxasphere-react-kit/assets/logos/auxamed_inline_light.svg'
import auxamed_inline from'auxasphere-react-kit/assets/logos/auxamed_inline.svg'
import auxamed_mobile_light from'auxasphere-react-kit/assets/logos/auxamed_mobile_light.svg'
import auxamed_mobile_png from'auxasphere-react-kit/assets/logos/auxamed_mobile.png'
import auxamed_mobile_svg from'auxasphere-react-kit/assets/logos/auxamed_mobile.svg'
import auxamed_top_light from'auxasphere-react-kit/assets/logos/auxamed_top_light.svg'
import auxamed_top from'auxasphere-react-kit/assets/logos/auxamed_top.svg'
import auxashpere from'auxasphere-react-kit/assets/logos/auxashpere.png'
import auxaview_inline_light from'auxasphere-react-kit/assets/logos/auxaview_inline_light.svg'
import auxaview_inline from'auxasphere-react-kit/assets/logos/auxaview_inline.svg'
import auxaview_light from'auxasphere-react-kit/assets/logos/auxaview_light.svg'
import auxaview_mobile_png from'auxasphere-react-kit/assets/logos/auxaview_mobile.png'
import auxaview_mobile_svg from'auxasphere-react-kit/assets/logos/auxaview_mobile.svg'
import auxaview_top_light from'auxasphere-react-kit/assets/logos/auxaview_top_light.svg'
import auxaview_top from'auxasphere-react-kit/assets/logos/auxaview_top.svg'
import FAQ from'auxasphere-react-kit/assets/logos/FAQ.svg'
import logo_auxadoc from'auxasphere-react-kit/assets/logos/logo_auxadoc.svg'
import logo_auxadrive from'auxasphere-react-kit/assets/logos/logo_auxadrive.svg'
import logo_auxameetings from'auxasphere-react-kit/assets/logos/logo_auxameetings.svg'
import logo_auxaplanning from'auxasphere-react-kit/assets/logos/logo_auxaplanning.svg'
import logo_auxateam from'auxasphere-react-kit/assets/logos/logo_auxateam.svg'
import logo_auxatransfer from'auxasphere-react-kit/assets/logos/logo_auxatransfer.svg'
import logo_groups from'auxasphere-react-kit/assets/logos/logo_groups.svg'
import logo_param from'auxasphere-react-kit/assets/logos/logo_param.svg'
import logo_users from'auxasphere-react-kit/assets/logos/logo_users.svg'
import menu from'auxasphere-react-kit/assets/logos/menu.svg'
import zoom_in from'auxasphere-react-kit/assets/logos/zoom_in.svg'
import zoom_out from'auxasphere-react-kit/assets/logos/zoom_out.svg'
//TODO: Add logo_customer_area.svg to auxasphere-react-kit

export const LOGO = {
    auxamed_inline_light,
    auxamed_inline,
    auxamed_mobile_light,
    auxamed_mobile_png,
    auxamed_mobile_svg,
    auxamed_top_light,
    auxamed_top,
    auxashpere,
    auxaview_inline_light,
    auxaview_inline,
    auxaview_light,
    auxaview_mobile_png,
    auxaview_mobile_svg,
    auxaview_top_light,
    auxaview_top,
    FAQ,
    logo_auxadoc,
    logo_auxadrive,
    logo_auxameetings,
    logo_auxaplanning,
    logo_auxateam,
    logo_auxatransfer,
    logo_groups,
    logo_param,
    logo_users,
    menu,
    zoom_in,
    zoom_out,
}