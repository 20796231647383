
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UploadFile } from 'antd'



interface PreviewerState {
    imgToView?: {
        file: UploadFile<any>
    }
}



export const initialState: PreviewerState = {
}

export const previewerSlice = createSlice({
    name: 'previewer',
    initialState,
    reducers: {
        setImgToView(state, { payload }: PayloadAction<{ file: UploadFile<any> } | undefined>) {
            state.imgToView = payload
        },
    },
    extraReducers: {}
})


export const {
    setImgToView,
} = previewerSlice.actions

export default previewerSlice.reducer