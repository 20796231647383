import React from 'react'
import Layout from '../../../components/Layout/Layout'
import NewMeetingForm from './NewMeetingForm'
import { useNavigate } from 'react-router-dom'
import MeetingsMenu from '../MeetingsMenu'

function NewMeeting() {
  const navigate = useNavigate()

  return (
    <Layout
      title="Meetings"
      menu={<MeetingsMenu />}
      content={
        <NewMeetingForm
          onMeetingCreated={() => navigate('/upcoming-meetings')}
          onPendingMeetingCreated={() => navigate('/pending-meetings')}
          onMeetingCancel={() => navigate('/upcoming-meetings')}
        />
      }
      footer={false}
    />
  )
}

export default NewMeeting
