import { useState } from "react"
import { UploadProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useToastContext } from "../../components/Toast/ToastContext"

export interface PersonalizationUploaderInterface {
    authLogoUploaded: string | undefined
    setAuthLogoUploaded: (value: string | undefined) => void
    authLogoUploadedFileName: string | undefined
    setAuthLogoUploadedFileName: (value: string | undefined) => void
    authBackgroundUploaded: string | undefined
    setAuthBackgroundUploaded: (value: string | undefined) => void
    authBackgroundUploadedFileName: string | undefined
    setAuthBackgroundUploadedFileName: (value: string | undefined) => void
    headerLogoUploaded: string | undefined
    setHeaderLogoUploaded: (value: string | undefined) => void
    headerLogoUploadedFileName: string | undefined
    setHeaderLogoUploadedFileName: (value: string | undefined) => void
    headerLogoMobileUploaded: string | undefined
    setHeaderLogoMobileUploaded: (value: string | undefined) => void
    headerLogoMobileUploadedFileName: string | undefined
    setHeaderLogoMobileUploadedFileName: (value: string | undefined) => void
    emailLogoUploaded: string | undefined
    setEmailLogoUploaded: (value: string | undefined) => void
    emailLogoUploadedFileName: string | undefined
    setEmailLogoUploadedFileName: (value: string | undefined) => void
    callLogoUploaded: string | undefined
    setCallLogoUploaded: (value: string | undefined) => void
    callLogoUploadedFileName: string | undefined
    setCallLogoUploadedFileName: (value: string | undefined) => void
    iconUploaded: string | undefined
    setIconUploaded: (value: string | undefined) => void
    iconUploadedFileName: string | undefined
    setIconUploadedFileName: (value: string | undefined) => void
    clear: () => void
    emailLogoDraggerProps: UploadProps
    loginLogoDraggerProps: UploadProps
    loginBackgroundDraggerProps: UploadProps
    headerLogoDraggerProps: UploadProps
    headerLogoMobileDraggerProps: UploadProps
    callLogoDraggerProps: UploadProps
    iconDraggerProps: UploadProps
}

export default function usePersonalizationUploader(
) {
    const { t } = useTranslation(['common', 'personalization'])
    const auth = useSelector((state: RootState) => state.auth)

    const { ToastOpen } = useToastContext()

    const [authLogoUploaded, setAuthLogoUploaded] = useState<string | undefined>()
    const [authLogoUploadedFileName, setAuthLogoUploadedFileName] = useState<string | undefined>()
    const [authBackgroundUploaded, setAuthBackgroundUploaded] = useState<string | undefined>()
    const [authBackgroundUploadedFileName, setAuthBackgroundUploadedFileName] = useState<string | undefined>()
    const [headerLogoUploaded, setHeaderLogoUploaded] = useState<string | undefined>()
    const [headerLogoUploadedFileName, setHeaderLogoUploadedFileName] = useState<string | undefined>()
    const [headerLogoMobileUploaded, setHeaderLogoMobileUploaded] = useState<string | undefined>()
    const [headerLogoMobileUploadedFileName, setHeaderLogoMobileUploadedFileName] = useState<string | undefined>()
    const [callLogoUploaded, setCallLogoUploaded] = useState<string | undefined>()
    const [callLogoUploadedFileName, setCallLogoUploadedFileName] = useState<string | undefined>()
    const [iconUploaded, setIconUploaded] = useState<string | undefined>()
    const [iconUploadedFileName, setIconUploadedFileName] = useState<string | undefined>()
    const [emailLogoUploaded, setEmailLogoUploaded] = useState<string | undefined>()
    const [emailLogoUploadedFileName, setEmailLogoUploadedFileName] = useState<string | undefined>()

    const getBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

    const loginLogoDraggerProps: UploadProps = {
        name: 'logo',
        accept: 'image',
        multiple: false,
        showUploadList: false,
        onChange: async (info) => {
          if (info.file.status === 'done') {
            setAuthLogoUploaded((await getBase64(info.file.originFileObj as File)) as string)
            setAuthLogoUploadedFileName(info.file.response['filename'])
          } else if (info.file.status === 'error') {
            ToastOpen({
              message: t('Logo upload failed.', { ns: 'personalization' }),
              type: 'error',
            })
          }
        },
        action:
          process.env.REACT_APP_BASE_PERSONALIZATION_URL + '/upload/login_logo',
        headers: {
          'Authorization': `${auth.jwt}`,
        },
      }


      const emailLogoDraggerProps: UploadProps = {
        //only png because used in email
        name: 'logo',
        accept: 'image/png',
        multiple: false,
        showUploadList: false,
        onChange: async (info) => {
          if (info.file.status === 'done') {
            setEmailLogoUploaded((await getBase64(info.file.originFileObj as File)) as string)
            setEmailLogoUploadedFileName(info.file.response['filename'])
          } else if (info.file.status === 'error') {
            ToastOpen({
              message: t('Email logo upload failed.', { ns: 'personalization' }),
              type: 'error',
            })
          }
        },
        action:
          process.env.REACT_APP_BASE_PERSONALIZATION_URL + '/upload/logo_email',
        headers: {
          'Authorization': `${auth.jwt}`,
        },
      }
    
      const loginBackgroundDraggerProps: UploadProps = {
        name: 'logo',
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        onChange: async (info) => {
          if (info.file.status === 'done') {
            setAuthBackgroundUploaded(
              (await getBase64(info.file.originFileObj as File)) as string,
            )
            setAuthBackgroundUploadedFileName(info.file.response['filename'])
          } else if (info.file.status === 'error') {
            ToastOpen({
              message: t('Background upload failed.', { ns: 'personalization' }),
              type: 'error',
            })
          }
        },
        action:
          process.env.REACT_APP_BASE_PERSONALIZATION_URL +
          '/upload/login_background',
        headers: {
          'Authorization': `${auth.jwt}`,
        },
      }
    
      const headerLogoDraggerProps: UploadProps = {
        name: 'logo',
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        onChange: async (info) => {
          if (info.file.status === 'done') {
            setHeaderLogoUploaded(
              (await getBase64(info.file.originFileObj as File)) as string,
            )
            setHeaderLogoUploadedFileName(info.file.response['filename'])
          } else if (info.file.status === 'error') {
            ToastOpen({
              message: t('Header logo upload failed.', { ns: 'personalization' }),
              type: 'error',
            })
          }
        },
        action:
          process.env.REACT_APP_BASE_PERSONALIZATION_URL + '/upload/header_logo',
        headers: {
          'Authorization': `${auth.jwt}`,
        },
      }
    
      const headerLogoMobileDraggerProps: UploadProps = {
        name: 'logo',
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        onChange: async (info) => {
          if (info.file.status === 'done') {
            setHeaderLogoMobileUploaded(
              (await getBase64(info.file.originFileObj as File)) as string,
            )
            setHeaderLogoMobileUploadedFileName(info.file.response['filename'])
          } else if (info.file.status === 'error') {
            ToastOpen({
              message: t('Mobile header logo upload failed.', { ns: 'personalization' }),
              type: 'error',
            })
          }
        },
        action:
          process.env.REACT_APP_BASE_PERSONALIZATION_URL +
          '/upload/header_logo_mobile',
        headers: {
          'Authorization': `${auth.jwt}`,
        },
      }
    
      const callLogoDraggerProps: UploadProps = {
        name: 'logo',
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        onChange: async (info) => {
          if (info.file.status === 'done') {
            setCallLogoUploaded((await getBase64(info.file.originFileObj as File)) as string)
            setCallLogoUploadedFileName(info.file.response['filename'])
          } else if (info.file.status === 'error') {
            ToastOpen({
              message: t('Logo for the call page upload failed.', { ns: 'personalization' }),
              type: 'error',
            })
          }
        },
        action:
          process.env.REACT_APP_BASE_PERSONALIZATION_URL + '/upload/call_logo',
        headers: {
          'Authorization': `${auth.jwt}`,
        },
      }
    
      const iconDraggerProps: UploadProps = {
        name: 'logo',
        accept: 'image/png',
        multiple: false,
        showUploadList: false,
        onChange: async (info) => {
          if (info.file.status === 'done') {
            setIconUploaded((await getBase64(info.file.originFileObj as File)) as string)
            setIconUploadedFileName(info.file.response['filename'])
          } else if (info.file.status === 'error') {
            ToastOpen({
              message: t('Icon upload failed.', { ns: 'personalization' }),
              type: 'error',
            })
          }
        },
        action: process.env.REACT_APP_BASE_PERSONALIZATION_URL + '/upload/icon',
        headers: {
          'Authorization': `${auth.jwt}`,
        },
      }

      function clear() {
        setAuthLogoUploadedFileName(undefined)
        setEmailLogoUploadedFileName(undefined)
        setAuthBackgroundUploadedFileName(undefined)
        setHeaderLogoUploadedFileName(undefined)
        setHeaderLogoMobileUploadedFileName(undefined)
        setCallLogoUploadedFileName(undefined)
        setIconUploadedFileName(undefined)
      }

    return {
        authLogoUploaded, setAuthLogoUploaded,
        authLogoUploadedFileName, setAuthLogoUploadedFileName,
        authBackgroundUploaded, setAuthBackgroundUploaded,
        authBackgroundUploadedFileName, setAuthBackgroundUploadedFileName,
        headerLogoUploaded, setHeaderLogoUploaded,
        headerLogoUploadedFileName, setHeaderLogoUploadedFileName,
        headerLogoMobileUploaded, setHeaderLogoMobileUploaded,
        headerLogoMobileUploadedFileName, setHeaderLogoMobileUploadedFileName,
        callLogoUploaded, setCallLogoUploaded,
        callLogoUploadedFileName, setCallLogoUploadedFileName,
        iconUploaded, setIconUploaded,
        iconUploadedFileName, setIconUploadedFileName,
        emailLogoUploaded, setEmailLogoUploaded,
        emailLogoUploadedFileName, setEmailLogoUploadedFileName,
        clear,
        loginLogoDraggerProps,
        emailLogoDraggerProps,
        loginBackgroundDraggerProps,
        headerLogoDraggerProps,
        headerLogoMobileDraggerProps,
        callLogoDraggerProps,
        iconDraggerProps,
    }
}