import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { setRoomState } from "./roomSlice"

export type MEDIA_DEVICE_STATUS = "UNKNOWN" | "ASKING_PERMISSIONS" | "PERMISSIONS_GRANTED" | "FAILED" | "NOT_ALLOWED" | "NOT_FOUND" | "STARTED" | "STOPPED"
export interface Me {
  micActive            : boolean
  canSendMic           : boolean
  micInProgress        : boolean
  micStatus            : MEDIA_DEVICE_STATUS

  webcamActive         : boolean
  canSendWebcam        : boolean
  canChangeWebcam      : boolean
  webcamStatus         : MEDIA_DEVICE_STATUS
  
  speakerInProgress    : boolean
  shareInProgress      : boolean
  audioOnly            : boolean
  audioOnlyInProgress  : boolean
  restartIceInProgress : boolean
  bokehEffectInProgress: boolean
  bokehEffectActive    : boolean
  showModalConnectionError: boolean
}

export const initialState:Me = {
  micActive            : true,
  canSendMic           : false,
  micStatus            : "UNKNOWN",
  webcamActive         : true,
  canSendWebcam        : false,
  canChangeWebcam      : false,
  webcamStatus         : "UNKNOWN",
  micInProgress        : false,
  speakerInProgress    : false,
  shareInProgress      : false,
  audioOnly            : false,
  audioOnlyInProgress  : false,
  restartIceInProgress : false,
  bokehEffectInProgress: false,
  bokehEffectActive    : false,
  showModalConnectionError: false,
}

const meSlice = createSlice({
  initialState,
  name: 'me',
  reducers: {
    setMediaCapabilities: (state, action: { payload: { canSendMic: boolean, canSendWebcam: boolean }}) => {
      state.canSendMic = action.payload.canSendMic
      state.canSendWebcam = action.payload.canSendWebcam
    },

    setWebcamActive: (state, { payload }: PayloadAction<boolean>) => {
      state.webcamActive = payload
    },

    setMicActive: (state, { payload }: PayloadAction<boolean>) => {
      state.micActive = payload
    },

    setMicStatus: (state, { payload }: PayloadAction<MEDIA_DEVICE_STATUS>) => {
      state.micStatus = payload
    },

    setCanChangeWebcam: (state, action) => {
      state.canChangeWebcam = action.payload
    },

    setWebcamStatus: (state, { payload }: PayloadAction<MEDIA_DEVICE_STATUS>) => {
      state.webcamStatus = payload
    },

    setMicInProgress: (state, action) => {
      state.micInProgress = action.payload
    },
    
    setSpeakerInProgress: (state, action) => {
      state.speakerInProgress = action.payload
    },

    setShareInProgress: (state, action) => {
      state.shareInProgress = action.payload
    },
    
    setAudioOnly: (state, action) => {
      state.audioOnly = action.payload
    },

    setAudioOnlyInProgress: (state, action) => {
      state.audioOnlyInProgress = action.payload
    },

    setRestartIceInProgress: (state, action: { payload: boolean}) => {
      state.restartIceInProgress = action.payload
    },

    setBokehEffectInProgress: (state, action: { payload: boolean}) => {
      state.bokehEffectInProgress = action.payload
    },

    setBokehEffectActive: (state, action: { payload: boolean}) => {
      state.bokehEffectActive = action.payload
    },
    
    setShowModalConnectionError: (state, action: { payload: boolean}) => {
      state.showModalConnectionError = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setRoomState, (state, action: { payload: string }) => {
      if (action.payload === 'closed') {
        // TODO
        state.webcamStatus          = "UNKNOWN"
        state.micInProgress         = false
        state.speakerInProgress     = false
        state.shareInProgress       = false
        state.audioOnly             = false
        state.audioOnlyInProgress   = false
        state.restartIceInProgress  = false
        state.bokehEffectInProgress = false
      }
    })
  },
})


export default meSlice.reducer

export const {
  setMediaCapabilities,
  setWebcamActive,
  setMicActive,
  setMicStatus,
  setCanChangeWebcam,
  setWebcamStatus,
  setMicInProgress,
  setSpeakerInProgress,
  setShareInProgress,
  setAudioOnly,
  setAudioOnlyInProgress,
  setRestartIceInProgress,
  setBokehEffectInProgress,
  setBokehEffectActive,
  setShowModalConnectionError,
} = meSlice.actions
