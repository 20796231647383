import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../../utils/FetchStatus'
import { addChildToFolder, addDriveFolder, deleteDriveItemRecursively, findParentFolderById, mapDrives, updateDriveFoldersRecursively, updateFolderRecursively } from '../../../components/DrivesComponent/DrivesService'
import _ from 'lodash'

export const ContentTypes = {
  PDF: 'application/pdf',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PNG: 'image/png',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOC: 'application/msword',
  DOCS: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  TXT: 'text/plain',
  CSS: 'text/css',
  HTML: 'text/html',
  JS: 'text/javascript',
  DCM: 'application/dicom',
  MP4: 'video/mp4',
  MP3: 'audio/mp3',
  CSV: 'text/csv',
}

export type selectableInterface = FileInterface | FolderInterface

interface State {
  createDriveStatus: FetchStatus
  driveToModify?: DriveInterface
  updateDriveStatus: FetchStatus
  fetchDriveStatus: FetchStatus
  fetchDrivesStatus: FetchStatus
  fetchDriveRightsStatus: FetchStatus
  drives?: DriveInterface[]
  fetchFolderStatus: FetchStatus
  createFolderStatus: FetchStatus
  selectedDriveFolder?: FolderInterface
  deleteDriveFileStatus: FetchStatus
  renameDriveFileStatus: FetchStatus
  moveDriveContentStatus: {
    status: FetchStatus,
    info?: string
  }
  deleteFolderStatus: FetchStatus
  renameDriveFolderStatus: FetchStatus
  addDriveFilesStatus: {
    status: FetchStatus,
    info?: string
  }
  fileToRename?: FileInterface
  fileToOpen?: DecryptedFileInterface
  folderToDelete?: {
    folder: FolderInterface,
    mainFolder: boolean
  }
  targetedFolder?: FolderInterface
  droppedFilesLoading: boolean
  draggingOver: boolean
  selectedFilesFolders: (selectableInterface)[]
  driveRights?: {
    adminRights: boolean
    folderRights: boolean
    fileRights: boolean
  }
  expandedFolderKeys: React.Key[]
  createDrive: boolean
  addEncryptDriveFilesStatus: FetchStatus
  uploadListFiles: UploadedFile[]
  driveModifyForm: {
    participants: DriveUserParticipant[]
    groups: DriveGroupParticipant[]
    isAdmin: boolean
  }
  driveToDelete: DriveInterface | undefined
  deleteDriveStatus: FetchStatus
  openCreateFolder: boolean
  contextMenuVisible: boolean
}

export interface DecryptedFileInterface {
  data: string
  file: FileInterface
}

export enum DriveRole {
  READ_ONLY = 'READ_ONLY', //adminRights: false, folderRights: false, fileRights: false
  FILES = 'FILES',//adminRights: false, folderRights: false, fileRights: true
  FOLDERS_FILES = 'FOLDERS_FILES',//adminRights: false, folderRights: true, fileRights: true
  ADMIN = 'ADMIN'//adminRights: true, folderRights: true, fileRights: true
}

export interface DriveInterface {
  id: string
  name: string,
  creatorEmail: string,
  creatorFullName: string,
  createdAt: number,
  updatedAt: number | null,
  users: DriveUserParticipant[],
  groups: DriveGroupParticipant[],
  rootFolder: FolderInterface,
  fileEncryptKey: string
}

export interface FolderInterface {
  id: string
  name: string,
  creatorEmail: string,
  creatorFullName: string,
  driveId: string,
  parentId: string | null,
  childrenFolders: FolderInterface[],
  childrenFiles: FileInterface[],
  createdAt: number,
  updatedAt: number | null
}

export interface DriveUserParticipant {
  email: string
  fullName: string
  adminRights: boolean
  folderRights: boolean //Can create documents in drive
  fileRights: boolean //Can upload/rename/delete documents in drive
}


export interface DriveGroupParticipant {
  id: string
  name: string
  adminRights: boolean
  folderRights: boolean //Can create documents in drive
  fileRights: boolean //Can upload/rename/delete documents in drive
}

export interface FileInterface {
  id: string
  name: string,
  mimeType: string,
  size: string,
  creatorEmail: string,
  creatorFullName: string,
  createdAt: number,
  updatedAt: number | null
}

export const initialState: State = {
  createDriveStatus: 'idle',
  updateDriveStatus: 'idle',
  fetchDriveStatus: 'idle',
  fetchDrivesStatus: 'idle',
  fetchDriveRightsStatus: 'idle',
  fetchFolderStatus: 'idle',
  createFolderStatus: 'idle',
  deleteDriveFileStatus: 'idle',
  renameDriveFileStatus: 'idle',
  moveDriveContentStatus: { status: 'idle' },
  deleteFolderStatus: 'idle',
  renameDriveFolderStatus: 'idle',
  addDriveFilesStatus: { status: 'idle' },
  droppedFilesLoading: false,
  draggingOver: false,
  selectedFilesFolders: [],
  expandedFolderKeys: [],
  createDrive: false,
  addEncryptDriveFilesStatus: 'idle',
  uploadListFiles: [],
  driveModifyForm: {
    participants: [],
    groups: [],
    isAdmin: false,
  },
  driveToDelete: undefined,
  deleteDriveStatus: "idle",
  openCreateFolder: false, 
  contextMenuVisible: false
}

export interface UploadedFile {
  id: string
  name: string
  size: number
  mimeType: string
  percent: number
  status: 'error' | 'done' | 'uploading' | 'removed'
}

export const createDrive = createAsyncThunk(
  'drive/createDriveStatus',
  async (
    payload: {
      name: string
      users: DriveUserParticipant[],
      groups: DriveGroupParticipant[]
    },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )
    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }
  },
)


export const updateDrive = createAsyncThunk(
  'drive/updateDriveStatus',
  async (
    payload: {
      id: string,
      name: string
      users: DriveUserParticipant[],
      groups: DriveGroupParticipant[]
    },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/update',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    dispatch(fetchDrive({ id: responseBody.id }))
  },
)

export const fetchDrives = createAsyncThunk(
  'drive/fetchDrivesStatus',
  async (payload: {}, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/list',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return {
      drives: responseBody,
    }
  },
)

export const fetchDrive = createAsyncThunk(
  'drive/fetchDriveStatus',
  async (payload: { id: string, newDrive?: boolean }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      `${process.env.REACT_APP_BASE_DRIVE_URL}/${payload.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return {
      drive: responseBody,
      newDrive: payload.newDrive 
    }
  },
)

export const fetchFolderById = createAsyncThunk(
  'drive/fetchFolderStatus',
  async (payload: { id: string, driveId: string, selected?: boolean }, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      `${process.env.REACT_APP_BASE_DRIVE_URL}/folder/${payload.driveId}/${payload.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    if (payload.selected) {
      dispatch(setSelectedDriveFolder({ folder :responseBody }))
    }

    return {
      folder: responseBody,
    }
  },
)


export const createFolder = createAsyncThunk(
  'drive/createFolderStatus',
  async (payload: { parentId: string, name: string, driveId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/folder/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify(payload)
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return {
      folder: responseBody,
      parentId: payload.parentId
    }
  },
)


export const deleteFolder = createAsyncThunk(
  'drive/deleteFolderStatus',
  async (payload: { driveId: string, folderId: string, mainFolder?: boolean }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/folder/delete',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify({
          driveId: payload.driveId,
          folderId: payload.folderId,
        }),       
      },
    )
    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return {
      folderId: payload.folderId,
      mainFolder: payload.mainFolder
    }
  },
)


export const renameDriveFolder = createAsyncThunk(
  'drive/renameDriveFolderStatus',
  async (payload: { name: string, id: string, driveId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/folder/rename',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify({
          name: payload.name,
          folderId: payload.id,
          driveId: payload.driveId,
        }),
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return responseBody
  },
)


export const deleteDriveFile = createAsyncThunk(
  'drive/deleteDriveFileStatus',
  async (payload: { driveId: string, folderId: string, fileId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/file/delete',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify({
          driveId: payload.driveId,
          folderId: payload.folderId,
          fileId: payload.fileId
        })
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return {
      fileId: payload.fileId
    }
  },
)

export const addDriveFiles = createAsyncThunk(
  'drive/addDriveFilesStatus',
  async (payload: { driveId: string, folderId: string, files: UploadedFile[], filesBlocked: boolean, creatorEmail: string, creatorFirstName: string, creatorLastName: string }, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    if (payload.files.length > 0) {

      const response = await fetch(
        process.env.REACT_APP_BASE_DRIVE_URL + '/file/create',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${auth.jwt}`,
          },
          body: JSON.stringify({
            files: payload.files,
            driveId: payload.driveId,
            folderId: payload.folderId,
          }),        
        },
      )

      const responseBody = await response.json()

      if (!response.ok) {
        throw new Error(responseBody.message || "unknown_error")
      }
  
      return {
        files: responseBody.files,
        filesBlocked: payload.filesBlocked
      }
    }
  }
)

export const addEncryptDriveFiles = createAsyncThunk(
  'drive/addEncryptDriveFilesStatus',
  async (payload: { driveId: string, folderId: string, driveEncryptKey: string, files: File[], filesBlocked: boolean, creatorEmail: string, creatorFirstName: string, creatorLastName: string }, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    let uploadList: UploadedFile[] = payload.files.map((file) => {
      return {
        name: file.name,
        percent: 0,
        status: 'uploading'
      } as UploadedFile
    })

    async function uploadFile(file: File) {
      const formData = new FormData()
      formData.append('file', file)
    
      const request = new XMLHttpRequest()
      request.open('POST', `${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${payload.driveEncryptKey}`)
      request.setRequestHeader('Authorization', `${auth.jwt}`)

      function handleProgress(event: ProgressEvent<EventTarget>) {
        const percent = (event.loaded / event.total) * 100
        const updatedFile: UploadedFile = { id: '', size: file.size, mimeType: file.type, status: 'uploading', name: file.name, percent }
        dispatch(addUploadListFiles([updatedFile]))
        updateUploadList(updatedFile)
      }
    
      function handleLoad() {
        const response = JSON.parse(request.response)
        let newObject: UploadedFile = { id: response.id, size: file.size, mimeType: file.type, status: 'done', name: file.name, percent: 100 }
    
        if (request.status !== 201) {
          newObject.status = 'error'
          newObject.percent = 0
        }
        dispatch(addUploadListFiles([newObject]))
        updateUploadList(newObject)
    
        if (uploadList.every((f) => f.status === 'done')) {
          dispatch(addDriveFiles({
            driveId: payload.driveId,
            folderId: payload.folderId,
            files: uploadList,
            filesBlocked: payload.filesBlocked,
            creatorEmail: payload.creatorEmail,
            creatorFirstName: payload.creatorFirstName,
            creatorLastName: payload.creatorLastName
          }))
          request.upload.removeEventListener('progress', handleProgress)
          request.removeEventListener('load', handleLoad)
        }
      }

      request.onreadystatechange = () => {
        if (request.status === 503) {
          dispatch(removeUploadListFiles([{ id: '', size: file.size, mimeType: file.type, status: 'error', name: file.name, percent: 0 }]))
        }
      }
      request.upload.addEventListener('progress', handleProgress)
      request.addEventListener('load', handleLoad)
    
      request.send(formData)
    
      function updateUploadList(updatedFile: UploadedFile) {
        const mergedFiles = _.mergeWith(
          _.keyBy(uploadList, 'name'),
          _.keyBy([updatedFile], 'name'),
          (obj1, obj2): UploadedFile => ({ ...obj1, ...obj2 }),
        )
        uploadList = _.values(mergedFiles)
      }
    }

    const uploadPromises = payload.files.map((file) => uploadFile(file))

    await Promise.all(uploadPromises)
  }
)

export const renameDriveFile = createAsyncThunk(
  'drive/renameDriveFileStatus',
  async (payload: { filename: string, fileId: string, driveId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      process.env.REACT_APP_BASE_DRIVE_URL + '/file/rename',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify(payload)
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return {
      fileId: payload.fileId,
      driveId: payload.driveId,
      filename: payload.filename
    }
  },
)


export const moveDriveContent = createAsyncThunk(
  'drive/moveDriveContentStatus',
  async (payload: { contentToMove: selectableInterface[], folderId: string, targetedFolderId: string, driveId: string }, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const files = payload.contentToMove.filter((item) => 'mimeType' in item) as FileInterface[]
    const folders = payload.contentToMove.filter((item) => !('mimeType' in item)) as FolderInterface[]

    const fileIds = files.map((file) => file.id)
    const folderIds = folders.map((folder) => folder.id)

    var targetedFolder = null

    if (files.length !== 0) {
      const responseFiles = await fetch(
        process.env.REACT_APP_BASE_DRIVE_URL + '/file/move',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${auth.jwt}`,
          },
          body: JSON.stringify({
            fileIds,
            targetedFolderId: payload.targetedFolderId,
            driveId: payload.driveId,
          })
        },
      )

      const responseFilesBody = await responseFiles.json()
      if (!responseFiles.ok) {
        throw new Error(responseFilesBody.message || "unknown_error")
      }
      targetedFolder = responseFilesBody
    }

    if (folders.length !== 0) {
      const responseFolders = await fetch(
        process.env.REACT_APP_BASE_DRIVE_URL + '/folder/move',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${auth.jwt}`,
          },
          body: JSON.stringify({
            folderIds,
            folderId: payload.targetedFolderId,
            driveId: payload.driveId,
          })
        },
      )
      const responseFoldersBody = await responseFolders.json()   
      if (!responseFolders.ok) {
        throw new Error(responseFoldersBody.message || "unknown_error")
      }
      targetedFolder = responseFoldersBody
    }
    
    dispatch(removeItemsSelectedDriveFolder(folders.map(item => item.id).concat(files.map(item => item.id))))

    return {
      files: files,
      folders: folders,
      targetedFolder,
    }
  },
)

export const fetchDriveRights = createAsyncThunk(
  'groups/fetchDriveRightsStatus',
  async (payload: { driveId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await fetch(
      `${process.env.REACT_APP_BASE_DRIVE_URL}/rights/${payload.driveId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
      },
    )

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || "unknown_error")
    }

    return {
      driveRights: responseBody,
    }
  },
)

export const deleteDrive = createAsyncThunk(
  'groups/deleteDriveStatus',
  async (payload, { getState }) => {
    const { auth, drive } = getState() as { auth: { jwt: string }, drive: { driveToDelete: DriveInterface } }
    const driveToDelete = drive.driveToDelete

    const response = await fetch(
      `${process.env.REACT_APP_BASE_DRIVE_URL}/delete`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify({
          id: driveToDelete.id,
          rootFolderId: driveToDelete.rootFolder.id,
        }),
      },
    )

    if (!response.ok) {
      const responseBody = await response.json()
      throw new Error(responseBody.message || "unknown_error")
    }

    return driveToDelete.id
  },
)


const DriveSlice = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    idleCreateDriveStatus: (state) => {
      state.createDriveStatus = 'idle'
    },
    idleUpdateDriveStatus: (state) => {
      state.updateDriveStatus = 'idle'
    },
    idleFetchDrivesStatus: (state) => {
      state.fetchDrivesStatus = 'idle'
    },
    idleFetchFolderStatus: (state) => {
      state.fetchFolderStatus = 'idle'
    },
    idleCreateFolderStatus: (state) => {
      state.createFolderStatus = 'idle'
    },
    idleDeleteDriveFileStatus: (state) => {
      state.deleteDriveFileStatus = 'idle'
    },
    idleDeleteFolderStatus: (state) => {
      state.deleteFolderStatus = 'idle'
    },
    idleRenameDriveFileStatus: (state) => {
      state.renameDriveFileStatus = 'idle'
    },
    idleRenameDriveFolderStatus: (state) => {
      state.renameDriveFolderStatus = 'idle'
    },
    idleAddDriveFilesStatus: (state) => {
      state.addDriveFilesStatus = { status: 'idle' }
    },
    idleMoveDriveContentStatus: (state) => {
      state.moveDriveContentStatus = { status: 'idle' }
      state.droppedFilesLoading = false
    },
    idleDeleteDriveStatus: (state) => {
      state.deleteDriveStatus = 'idle'
    },
    setSelectedDriveFolder: (state, action: PayloadAction<{ folder: FolderInterface | undefined } | undefined>) => {
      state.selectedDriveFolder = action.payload?.folder
    },
    setDriveFolders: (state, action: PayloadAction<{ folders: DriveInterface[] }>) => {
      state.drives = action.payload.folders
    },
    idleDrive: (state) => {
      state.selectedDriveFolder = undefined
      state.expandedFolderKeys = []
    },
    idleAddEncryptDriveFilesStatus: (state) => {
      state.addEncryptDriveFilesStatus = 'idle'
    },
    setFileToRename: (state, action: PayloadAction<{ file: FileInterface } | undefined>) => {
      state.fileToRename = action.payload?.file
    },
    setFileToOpen: (state, action: PayloadAction<{ file: DecryptedFileInterface } | undefined>) => {
      state.fileToOpen = action.payload?.file
    },
    setFolderToDelete: (state, action: PayloadAction<{ folder: FolderInterface, mainFolder: boolean } | undefined>) => {
      state.folderToDelete = action.payload ? { folder: action.payload.folder, mainFolder: action.payload.mainFolder } : undefined
    },
    setDroppedFilesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.droppedFilesLoading = payload
    },
    setTargetedFolder: (state, action: PayloadAction<{ folder: FolderInterface } | undefined>) => {
      state.targetedFolder = action.payload?.folder
    },
    setDraggingOver: (state, { payload }: PayloadAction<boolean>) => {
      state.draggingOver = payload
    },
    setSelectedFilesFolders: (state, { payload }: PayloadAction<(selectableInterface)[]>) => {
      state.selectedFilesFolders = payload
    },
    resetSelectedFilesFolders: (state) => {
      state.selectedFilesFolders = []
    },
    setDriveToModify: (state, action: PayloadAction<{ drive: DriveInterface } | undefined>) => {
      state.driveToModify = action.payload?.drive
    },
    setExpandedFolderKeys:(state, { payload }: PayloadAction<React.Key[]>) => {
      state.expandedFolderKeys = payload
    },
    setCreateDrive: (state, { payload }: PayloadAction<boolean>) => {
      state.createDrive = payload
    },
    addUploadListFiles: (state, { payload }: PayloadAction<UploadedFile[]>) => {
      const mergedFiles = _.mergeWith(
        _.keyBy(state.uploadListFiles, 'name'),
        _.keyBy(payload, 'name'),
        (obj1, obj2):UploadedFile => ({ ...obj1, ...obj2 }),
      )

      state.uploadListFiles = _.values(mergedFiles)
    },
    removeUploadListFiles: (state, { payload }: PayloadAction<UploadedFile[]>) => {
      const cleanFiles = _.filter(state.uploadListFiles, (file) => !payload.map((f) => f.name).includes(file.name))

      state.uploadListFiles = cleanFiles
    },
    setUploadListFiles: (state, { payload }: PayloadAction<UploadedFile[]>) => {
      state.uploadListFiles = payload
    },
    setParticipants(state, { payload }: PayloadAction<DriveUserParticipant[]>) {
      state.driveModifyForm.participants = payload
    },
    setGroups(state, { payload }: PayloadAction<DriveGroupParticipant[]>) {
      state.driveModifyForm.groups = payload
    },
    setAdminRight(state, { payload }: PayloadAction<boolean>) {
      state.driveModifyForm.isAdmin = payload
    },
    setDriveToDelete(state, { payload }: PayloadAction<DriveInterface | undefined>) {
      state.driveToDelete = payload
    },
    removeItemsSelectedDriveFolder(state, { payload }: PayloadAction<string[]>) {
      const remove = (parentFolder: FolderInterface, ids: string[]):FolderInterface => {
        return {
          ...parentFolder,
          childrenFolders: parentFolder.childrenFolders.filter((folder) => !ids.includes(folder.id)),
          childrenFiles: parentFolder.childrenFiles?.filter((file) => !ids.includes(file.id)),
        }
      }
      state.selectedDriveFolder = remove(state.selectedDriveFolder as FolderInterface, payload)
    },
    setOpenCreateFolder(state, { payload }: PayloadAction<boolean>) {
      state.openCreateFolder = payload
    },
    setContextMenuVisible(state, { payload }: PayloadAction<boolean>) {
      state.contextMenuVisible = payload
    }
  },
  extraReducers: {
    [createDrive.pending.type]: (state, action) => {
      state.createDriveStatus = 'loading'
    },
    [createDrive.fulfilled.type]: (state, action) => {
      state.createDriveStatus = 'success'
    },
    [createDrive.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.createDriveStatus = "FETCH_ERROR"
      } else {
        state.createDriveStatus = error.message ?? 'unknown_error'
      }
    },

    [updateDrive.pending.type]: (state, action) => {
      state.updateDriveStatus = 'loading'
    },
    [updateDrive.fulfilled.type]: (state, action) => {
      state.updateDriveStatus = 'success'
    },
    [updateDrive.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.updateDriveStatus = "FETCH_ERROR"
      } else {
        state.updateDriveStatus = error.message ?? 'unknown_error'
      }
    },

    [fetchDrive.pending.type]: (state, action) => {
      state.fetchDriveStatus = 'loading'
    },
    [fetchDrive.fulfilled.type]: (
      state,
      action: PayloadAction<{ drive: DriveInterface, newDrive: boolean }>,
    ) => {
      state.fetchDriveStatus = 'success'
      const drive = action.payload.drive

      if (action.payload.newDrive) {
        state.drives?.push(drive)
        state.drives?.sort((a, b) => a.name.localeCompare(b.name))
        state.selectedDriveFolder = drive.rootFolder
      } else {
        const index = state.drives?.findIndex(existingDrive => existingDrive?.id === drive.id)
        if (index !== undefined && index !== -1 && state.drives) {
          state.drives[index] = drive
          state.drives?.sort((a, b) => a.name.localeCompare(b.name))
          if (state.selectedDriveFolder?.driveId === drive.id) {
            state.selectedDriveFolder = drive.rootFolder
          }
        }
      }
    },
    [fetchDrive.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.fetchDriveStatus = "FETCH_ERROR"
      } else {
        state.fetchDriveStatus = error.message ?? 'unknown_error'
      }
    },

    [fetchDrives.pending.type]: (state, action) => {
      state.fetchDrivesStatus = 'loading'
    },
    [fetchDrives.fulfilled.type]: (
      state,
      action: PayloadAction<{ drives: DriveInterface[] }>,
    ) => {
      state.fetchDrivesStatus = 'success'
      state.drives = action.payload.drives
    },
    [fetchDrives.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.fetchDrivesStatus = "FETCH_ERROR"
      } else {
        state.fetchDrivesStatus = error.message ?? 'unknown_error'
      }
    },

    [deleteDrive.pending.type]: (state, action) => {
      state.deleteDriveStatus = 'loading'
    },
    [deleteDrive.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.deleteDriveStatus = 'success'
      state.drives = state.drives?.filter(drive => drive.id !== payload)

      if (state.selectedDriveFolder?.driveId === payload) {
        state.selectedDriveFolder = undefined
      }
    },
    [deleteDrive.rejected.type]: (state, action) => {
      state.deleteDriveStatus = 'error'
    },

    [fetchFolderById.pending.type]: (state, action) => {
      state.fetchFolderStatus = 'loading'
      state.droppedFilesLoading = true
    },
    [fetchFolderById.fulfilled.type]: (
      state,
      action: PayloadAction<{ folder: FolderInterface }>,
    ) => {
      state.fetchFolderStatus = 'success'
      const newFolder = action.payload.folder
      if (state.drives) {
        state.drives = updateDriveFoldersRecursively(state.drives, newFolder)
      }
      state.droppedFilesLoading = false
    },
    [fetchFolderById.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.fetchFolderStatus = "FETCH_ERROR"
      } else {
        state.fetchFolderStatus = error.message ?? 'unknown_error'
      }
      state.droppedFilesLoading = false
    },

    [createFolder.pending.type]: (state, action) => {
      state.createFolderStatus = 'loading'
    },
    [createFolder.fulfilled.type]: (state, action: PayloadAction<{ folder: FolderInterface, parentId: string }>) => {
      state.createFolderStatus = 'success'
      const newFolder = action.payload.folder
      const parentId = action.payload.parentId
      if (state.drives) {
          state.drives = mapDrives(state.drives, (folders) => {
            if (state.drives) {
              return addDriveFolder(folders, newFolder, parentId)
            }
            return folders
          })
      }

      if (state.selectedDriveFolder) {
        state.selectedDriveFolder = addChildToFolder(state.selectedDriveFolder, newFolder, parentId)
      }
    },
    [createFolder.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.createFolderStatus = "FETCH_ERROR"
      } else {
        state.createFolderStatus = error.message ?? 'unknown_error'
      }
    },


    [deleteFolder.pending.type]: (state, action) => {
      state.deleteFolderStatus = 'loading'
    },
    [deleteFolder.fulfilled.type]: (state, action: PayloadAction<{ folderId: string, mainFolder: boolean }>) => {
      state.deleteFolderStatus = 'success'

      if (state.selectedDriveFolder && state.drives) {
        const folderIdToDelete = action.payload.folderId
        const isMainFolder = action.payload.mainFolder
      
        if (isMainFolder) {
          var parentFolder = null
          for(const drive of state.drives) {
            parentFolder = findParentFolderById([drive.rootFolder], folderIdToDelete, drive.rootFolder.driveId)
            if (parentFolder) {
              break
            }
          }

          if (parentFolder) {
            state.selectedDriveFolder = {
              ...parentFolder,
              childrenFolders: parentFolder.childrenFolders.filter((folder) => folder.id !== folderIdToDelete),
            }
          }
        } else {
          state.selectedDriveFolder = {
            ...state.selectedDriveFolder,
            childrenFolders: state.selectedDriveFolder.childrenFolders.filter((folder) => folder.id !== folderIdToDelete),
          }
        }
        state.drives = updateDriveFoldersRecursively(state.drives, state.selectedDriveFolder)
      }
    },

    [deleteFolder.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.deleteFolderStatus = "FETCH_ERROR"
      } else {
        state.deleteFolderStatus = error.message ?? 'unknown_error'
      }
    },
    [renameDriveFolder.pending.type]: (state, action) => {
      state.renameDriveFolderStatus = 'loading'
    },
    [renameDriveFolder.fulfilled.type]: (state, action: PayloadAction<{ name: string, folderId: string }>) => {
      state.renameDriveFolderStatus = 'success'
    
      if (state.drives && state.selectedDriveFolder) {
        const updatedSelectedDriveFolder = {
          ...state.selectedDriveFolder,
          childrenFolders: state.selectedDriveFolder.childrenFolders.map((folder) => {
            if (folder.id === action.payload.folderId) {
              return {
                ...folder,
                name: action.payload.name
              }
            }
            return folder
          }),
        }
        state.selectedDriveFolder = updatedSelectedDriveFolder
        state.drives = updateDriveFoldersRecursively(state.drives, updatedSelectedDriveFolder)
      }
    },
    [renameDriveFolder.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.renameDriveFolderStatus = "FETCH_ERROR"
      } else {
        state.renameDriveFolderStatus = error.message ?? 'unknown_error'
      }
    },

    [renameDriveFile.pending.type]: (state, action) => {
      state.renameDriveFileStatus = 'loading'
    },
    [renameDriveFile.fulfilled.type]: (state, action: PayloadAction<{ fileId: string, driveId: string, filename: string }>) => {
      state.renameDriveFileStatus = 'success'
  
      if (state.drives && state.selectedDriveFolder) {
          const updatedSelectedDriveFolder = {
              ...state.selectedDriveFolder,
              childrenFiles: state.selectedDriveFolder.childrenFiles.map((file) => {
                  if (file.id === action.payload.fileId) {
                      return {
                          ...file,
                          name: action.payload.filename
                      }
                  }
                  return file
              }),
          }
  
          state.selectedDriveFolder = updatedSelectedDriveFolder
          state.drives = updateDriveFoldersRecursively(state.drives, updatedSelectedDriveFolder)
  
          if (state.fileToOpen && state.fileToOpen.data) {
              const newFile = {
                  data: state.fileToOpen.data,
                  file: {
                      ...state.fileToOpen.file,
                      name: action.payload.filename
                  }
              }
              state.fileToOpen = newFile
          }
      }
  },
    [renameDriveFile.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.renameDriveFileStatus = "FETCH_ERROR"
      } else {
        state.renameDriveFileStatus = error.message ?? 'unknown_error'
      }
    },

    [addDriveFiles.pending.type]: (state, action) => {
      state.addDriveFilesStatus = { status: 'loading' }
    },
    [addDriveFiles.fulfilled.type]: (state, action: PayloadAction<{ files: FileInterface[], filesBlocked: boolean }>) => {
      if (!action.payload) {
        state.addDriveFilesStatus = { status: 'fetch_error'}
        return
      }

      if (action.payload.files.length > 1) {
        state.addDriveFilesStatus = action.payload.filesBlocked ? { status: 'success_some_files' } : { status: 'success_multiple_files' }
      } else {
        state.addDriveFilesStatus = { 
          status: 'success_single_file',
          info: action.payload.files[0].name
        }
      }
      
      if (state.drives && state.targetedFolder && state.selectedDriveFolder) {
        const updatedSelectedDriveFolder = {
          ...state.targetedFolder,
          childrenFiles: [
            ...(state.targetedFolder.childrenFiles || []),
            ...action.payload.files,
          ].sort((a, b) => (a.name && b.name) ? a.name.localeCompare(b.name) : 0)
        }

        state.selectedDriveFolder = updateFolderRecursively(state.selectedDriveFolder, updatedSelectedDriveFolder)
        state.drives = updateDriveFoldersRecursively(state.drives, updatedSelectedDriveFolder)      }
    },
    [addDriveFiles.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.addDriveFilesStatus = { status: 'FETCH_ERROR' }
      } else {
        state.addDriveFilesStatus =  { status: error.message } ??  { status: 'unknown_error' }
      }
    },

    [moveDriveContent.pending.type]: (state, action) => {
      state.moveDriveContentStatus = { status: 'loading' }
    },
    [moveDriveContent.fulfilled.type]: (state, action: PayloadAction<{ files: FileInterface[], folders: FolderInterface[], targetedFolder: FolderInterface }>) => {
      if (!action.payload) {
        state.moveDriveContentStatus = { status: 'fetch_error'}
        return
      }

      const hasFiles = action.payload.files.length > 0
      const hasFolders = action.payload.folders.length > 0
      const mulitpleFiles = action.payload.files.length > 1
      const mulitpleFolders = action.payload.folders.length > 1
      if (hasFiles && hasFolders) {
        state.moveDriveContentStatus = { status: 'success_multiple_files' }
      } else if (hasFiles) {
        if (mulitpleFiles) {
          state.moveDriveContentStatus = { status: 'success_multiple_files' }
        } else {
          state.moveDriveContentStatus = {
            status: 'success_single_file',
            info: action.payload.files[0]?.name || ""
          }
        }
      } else {
        if(mulitpleFolders) {
          state.moveDriveContentStatus = { status: 'success_multiple_folders' }
        } else {
          state.moveDriveContentStatus = {
            status: 'success_single_folder',
            info: action.payload.folders[0]?.name || ""
          }
        }
      }

      action.payload.folders.forEach((folder) => {
        if (state.drives) {
          state.drives = deleteDriveItemRecursively(state.drives, action.payload.targetedFolder.driveId, folder)
        }
      })
      action.payload.files.forEach((file) => {
        if (state.drives) {
          state.drives = deleteDriveItemRecursively(state.drives, action.payload.targetedFolder.driveId, file)
        }
      })

      if (state.drives) {
        state.drives = updateDriveFoldersRecursively(state.drives, action.payload.targetedFolder)
      }
    },
    [moveDriveContent.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.moveDriveContentStatus = { status: 'FETCH_ERROR' }
      } else {
        state.moveDriveContentStatus =  { status: error.message } ??  { status: 'unknown_error' }
      }
    },

    [deleteDriveFile.pending.type]: (state, action) => {
      state.deleteDriveFileStatus = 'loading'
    },
    [deleteDriveFile.fulfilled.type]: (state, action: PayloadAction<{ fileId: string }>) => {
      state.deleteDriveFileStatus = 'success'
      if (state.drives && state.selectedDriveFolder) {
        const updatedSelectedDriveFolder = {
          ...state.selectedDriveFolder,
          childrenFiles: state.selectedDriveFolder.childrenFiles.filter((file) => file.id !== action.payload.fileId),
        }
        state.selectedDriveFolder = updatedSelectedDriveFolder
        state.drives = updateDriveFoldersRecursively(state.drives, updatedSelectedDriveFolder)
      }
    },
    [deleteDriveFile.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.deleteDriveFileStatus = "FETCH_ERROR"
      } else {
        state.deleteDriveFileStatus = error.message ?? 'unknown_error'
      }
    },

    [fetchDriveRights.pending.type]: (state, action) => {
      state.fetchDriveRightsStatus = 'loading'
    },
    [fetchDriveRights.fulfilled.type]: (state, action: PayloadAction<{
      driveRights: {
        adminRights: boolean
        folderRights: boolean
        fileRights: boolean
      }
    }>) => {
      state.fetchDriveRightsStatus = 'success'
      state.driveRights = action.payload.driveRights
    },
    [fetchDriveRights.rejected.type]: (state, action) => {
      const error = action.error
      if (error && error.message === "Failed to fetch") {
        state.fetchDriveRightsStatus = "FETCH_ERROR"
      } else {
        state.fetchDriveRightsStatus = error.message ?? 'unknown_error'
      }
    },

    [addEncryptDriveFiles.pending.type]: (state, action) => {
      state.addEncryptDriveFilesStatus = 'loading'
    },
    [addEncryptDriveFiles.fulfilled.type]: (state, action) => {
      state.addEncryptDriveFilesStatus = 'success'
    },
    [addEncryptDriveFiles.rejected.type]: (state, action) => {
      state.addEncryptDriveFilesStatus = 'error'
    },
  },
})

export default DriveSlice.reducer

export const { 
  idleCreateDriveStatus, 
  idleUpdateDriveStatus,
  idleFetchDrivesStatus, 
  idleFetchFolderStatus, 
  idleCreateFolderStatus, 
  idleDeleteDriveFileStatus, 
  setSelectedDriveFolder,
  setDriveFolders, 
  idleDrive, 
  idleDeleteFolderStatus,
  idleRenameDriveFileStatus, 
  idleRenameDriveFolderStatus, 
  idleAddDriveFilesStatus,
  idleAddEncryptDriveFilesStatus,
  idleDeleteDriveStatus,
  setFileToRename, 
  setFileToOpen, 
  setFolderToDelete, 
  setDroppedFilesLoading, 
  idleMoveDriveContentStatus,
  setTargetedFolder, 
  setDraggingOver, 
  setSelectedFilesFolders,
  setDriveToModify,
  setExpandedFolderKeys,
  setCreateDrive,
  addUploadListFiles,
  removeUploadListFiles,
  setUploadListFiles,
  setParticipants,
  setGroups,
  setAdminRight,
  setDriveToDelete,
  resetSelectedFilesFolders,
  removeItemsSelectedDriveFolder,
  setOpenCreateFolder, 
  setContextMenuVisible
} = DriveSlice.actions
