import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/uk'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import accountEN from './locales/account.en.json'
import accountFR from './locales/account.fr.json'
import accountDE from './locales/account.de.json'
import accountIT from './locales/account.it.json'
import accountUK from './locales/account.uk.json'
import errorEN from './locales/error.en.json'
import errorFR from './locales/error.fr.json'
import errorDE from './locales/error.de.json'
import errorIT from './locales/error.it.json'
import errorUK from './locales/error.uk.json'
import antechamberEN from './locales/antechamber.en.json'
import antechamberFR from './locales/antechamber.fr.json'
import antechamberDE from './locales/antechamber.de.json'
import antechamberIT from './locales/antechamber.it.json'
import antechamberUK from './locales/antechamber.uk.json'
import appEN from './locales/app.en.json'
import appFR from './locales/app.fr.json'
import appDE from './locales/app.de.json'
import appIT from './locales/app.it.json'
import appUK from './locales/app.uk.json'
import authEN from './locales/auth.en.json'
import authFR from './locales/auth.fr.json'
import authDE from './locales/auth.de.json'
import authIT from './locales/auth.it.json'
import authUK from './locales/auth.uk.json'
import commonEN from './locales/common.en.json'
import commonFR from './locales/common.fr.json'
import commonDE from './locales/common.de.json'
import commonIT from './locales/common.it.json'
import commonUK from './locales/common.uk.json'
import faqEN from './locales/faq.en.json'
import faqFR from './locales/faq.fr.json'
import faqDE from './locales/faq.de.json'
import faqIT from './locales/faq.it.json'
import faqUK from './locales/faq.uk.json'
import meetingsEN from './locales/meetings.en.json'
import meetingsFR from './locales/meetings.fr.json'
import meetingsDE from './locales/meetings.de.json'
import meetingsIT from './locales/meetings.it.json'
import meetingsUK from './locales/meetings.uk.json'
import homepageEN from './locales/homepage.en.json'
import homepageFR from './locales/homepage.fr.json'
import homepageDE from './locales/homepage.de.json'
import homepageIT from './locales/homepage.it.json'
import homepageUK from './locales/homepage.uk.json'
import inviteEN from './locales/invite.en.json'
import inviteFR from './locales/invite.fr.json'
import inviteDE from './locales/invite.de.json'
import inviteIT from './locales/invite.it.json'
import inviteUK from './locales/invite.uk.json'
import layoutEN from './locales/layout.en.json'
import layoutFR from './locales/layout.fr.json'
import layoutDE from './locales/layout.de.json'
import layoutIT from './locales/layout.it.json'
import layoutUK from './locales/layout.uk.json'
import notarisationEN from './locales/notarisation.en.json'
import notarisationFR from './locales/notarisation.fr.json'
import notarisationDE from './locales/notarisation.de.json'
import notarisationIT from './locales/notarisation.it.json'
import notarisationUK from './locales/notarisation.uk.json'
import notificationEN from './locales/notification.en.json'
import notificationFR from './locales/notification.fr.json'
import notificationDE from './locales/notification.de.json'
import notificationIT from './locales/notification.it.json'
import notificationUK from './locales/notification.uk.json'
import modalEN from './locales/modal.en.json'
import modalFR from './locales/modal.fr.json'
import modalDE from './locales/modal.de.json'
import modalIT from './locales/modal.it.json'
import modalUK from './locales/modal.uk.json'
import organisationEN from './locales/organisation.en.json'
import organisationFR from './locales/organisation.fr.json'
import organisationDE from './locales/organisation.de.json'
import organisationIT from './locales/organisation.it.json'
import organisationUK from './locales/organisation.uk.json'
import personalizationEN from './locales/personalization.en.json'
import personalizationFR from './locales/personalization.fr.json'
import personalizationDE from './locales/personalization.de.json'
import personalizationIT from './locales/personalization.it.json'
import personalizationUK from './locales/personalization.uk.json'
import reinitPasswordEN from './locales/reinitpassword.en.json'
import reinitPasswordFR from './locales/reinitpassword.fr.json'
import reinitPasswordDE from './locales/reinitpassword.de.json'
import reinitPasswordIT from './locales/reinitpassword.it.json'
import reinitPasswordUK from './locales/reinitpassword.uk.json'
import roomDevicesConfigurationfromEN from './locales/room-devices-configuration.en.json'
import roomDevicesConfigurationfromFR from './locales/room-devices-configuration.fr.json'
import roomDevicesConfigurationfromDE from './locales/room-devices-configuration.de.json'
import roomDevicesConfigurationfromIT from './locales/room-devices-configuration.it.json'
import roomDevicesConfigurationfromUK from './locales/room-devices-configuration.uk.json'
import roomGeneralInformationfromEN from './locales/room-general-information.en.json'
import roomGeneralInformationfromFR from './locales/room-general-information.fr.json'
import roomGeneralInformationfromDE from './locales/room-general-information.de.json'
import roomGeneralInformationfromIT from './locales/room-general-information.it.json'
import roomGeneralInformationfromUK from './locales/room-general-information.uk.json'
import roomEN from './locales/room.en.json'
import roomFR from './locales/room.fr.json'
import roomDE from './locales/room.de.json'
import roomIT from './locales/room.it.json'
import roomUK from './locales/room.uk.json'
import calendarEN from './locales/calendar.en.json'
import calendarFR from './locales/calendar.fr.json'
import calendarDE from './locales/calendar.de.json'
import calendarIT from './locales/calendar.it.json'
import calendarUK from './locales/calendar.uk.json'
import groupsEN from './locales/groups.en.json'
import groupsFR from './locales/groups.fr.json'
import groupsDE from './locales/groups.de.json'
import groupsIT from './locales/groups.it.json'
import groupsUK from './locales/groups.uk.json'
import drivesEN from './locales/drives.en.json'
import drivesFR from './locales/drives.fr.json'
import drivesDE from './locales/drives.de.json'
import drivesIT from './locales/drives.it.json'
import drivesUK from './locales/drives.uk.json'
import faqDevicesEN from './locales/faq-devices.en.json'
import faqDevicesFR from './locales/faq-devices.fr.json'
import faqDevicesDE from './locales/faq-devices.de.json'
import faqDevicesIT from './locales/faq-devices.it.json'
import faqDevicesUK from './locales/faq-devices.uk.json'
import applicationsEN from './locales/applications.en.json'
import applicationsFR from './locales/applications.fr.json'
import applicationsDE from './locales/applications.de.json'
import applicationsIT from './locales/applications.it.json'
import applicationsUK from './locales/applications.uk.json'
import chatEN from './locales/chat.en.json'
import chatFR from './locales/chat.fr.json'
import chatIT from './locales/chat.it.json'
import chatDE from './locales/chat.de.json'
import chatUK from './locales/chat.uk.json'
import frFR from 'antd/locale/fr_FR'
import deDE from 'antd/locale/de_DE'
import enGB from 'antd/locale/en_GB'
import itIT from 'antd/locale/it_IT'
import ukUA from 'antd/locale/uk_UA'
import { Locale } from 'antd/lib/locale'

export type LocaleType = "fr" | "de" | "it" | "uk" | "en"


/**
 * Get the locale ant object from the locale string
 * @param locale i18n locale string
 * @returns Locale ant object
 */
export const antLocale: {[key: string]: Locale} = {
  "fr": frFR,
  "de": deDE,
  "it": itIT,
  "uk": ukUA,
  "en": enGB,
}

export const resources = {
  en: {
    auth: authEN,
    common: commonEN,
    faq: faqEN,
    meetings: meetingsEN,
    account: accountEN,
    layout: layoutEN,
    notarisation: notarisationEN,
    notification: notificationEN,
    invite: inviteEN,
    homepage: homepageEN,
    antechamber: antechamberEN,
    app: appEN,
    organisation: organisationEN,
    personalization: personalizationEN,
    reinitpassword: reinitPasswordEN,
    room: roomEN,
    roomDevicesConfiguration: roomDevicesConfigurationfromEN,
    roomGeneralInformation: roomGeneralInformationfromEN,
    modal: modalEN,
    calendar: calendarEN,
    chat: chatEN,
    groups: groupsEN,
    drives: drivesEN,
    faqDevices: faqDevicesEN,
    applications: applicationsEN,
    translation:{ //to be able translate from ts (usage: i18n.t('error.key'))
      error: errorEN,
    }
  },
  fr: {
    auth: authFR,
    common: commonFR,
    faq: faqFR,
    meetings: meetingsFR,
    account: accountFR,
    layout: layoutFR,
    notarisation: notarisationFR,
    notification: notificationFR,
    invite: inviteFR,
    homepage: homepageFR,
    antechamber: antechamberFR,
    app: appFR,
    organisation: organisationFR,
    personalization: personalizationFR,
    reinitpassword: reinitPasswordFR,
    room: roomFR,
    roomDevicesConfiguration: roomDevicesConfigurationfromFR,
    roomGeneralInformation: roomGeneralInformationfromFR,
    modal: modalFR,
    calendar: calendarFR,
    chat: chatFR,
    groups: groupsFR,
    drives: drivesFR,
    faqDevices: faqDevicesFR,
    applications: applicationsFR,
    translation:{
      error: errorFR,
    }
  },
  de: {
    auth: authDE,
    common: commonDE,
    faq: faqDE,
    meetings: meetingsDE,
    account: accountDE,
    layout: layoutDE,
    notarisation: notarisationDE,
    notification: notificationDE,
    invite: inviteDE,
    homepage: homepageDE,
    antechamber: antechamberDE,
    app: appDE,
    organisation: organisationDE,
    personalization: personalizationDE,
    reinitpassword: reinitPasswordDE,
    room: roomDE,
    roomDevicesConfiguration: roomDevicesConfigurationfromDE,
    roomGeneralInformation: roomGeneralInformationfromDE,
    modal: modalDE,
    calendar: calendarDE,
    groups: groupsDE,
    drives: drivesDE,
    faqDevices: faqDevicesDE,
    applications: applicationsDE,
    chat: chatDE,
    translation:{
      error: errorDE,
    }
  },
  it: {
    auth: authIT,
    common: commonIT,
    faq: faqIT,
    meetings: meetingsIT,
    account: accountIT,
    layout: layoutIT,
    notarisation: notarisationIT,
    notification: notificationIT,
    invite: inviteIT,
    homepage: homepageIT,
    antechamber: antechamberIT,
    app: appIT,
    organisation: organisationIT,
    personalization: personalizationIT,
    reinitpassword: reinitPasswordIT,
    room: roomIT,
    roomDevicesConfiguration: roomDevicesConfigurationfromIT,
    roomGeneralInformation: roomGeneralInformationfromIT,
    modal: modalIT,
    calendar: calendarIT,
    groups: groupsIT,
    drives: drivesIT,
    faqDevices: faqDevicesIT,
    applications: applicationsIT,
    chat: chatIT,
    translation: {
      error: errorIT
    }
  },
  uk: {
    auth: authUK,
    common: commonUK,
    faq: faqUK,
    meetings: meetingsUK,
    account: accountUK,
    layout: layoutUK,
    notarisation: notarisationUK,
    notification: notificationUK,
    invite: inviteUK,
    homepage: homepageUK,
    antechamber: antechamberUK,
    app: appUK,
    organisation: organisationUK,
    personalization: personalizationUK,
    reinitpassword: reinitPasswordUK,
    room: roomUK,
    roomDevicesConfiguration: roomDevicesConfigurationfromUK,
    roomGeneralInformation: roomGeneralInformationfromUK,
    modal: modalUK,
    calendar: calendarUK,
    groups: groupsUK,
    drives: drivesUK,
    faqDevices: faqDevicesUK,
    applications: applicationsUK,
    chat: chatUK,
    translation: {
      error: errorUK
    }
  },
} as const;


i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("settingsLng") || process.env.REACT_APP_DEFAULT_LOCALE,
    fallbackLng: 'en',
    resources,
    nsSeparator: false
  })


i18n.on("languageChanged", lng => {
  localStorage.setItem("settingsLng", lng)
})

export default i18n