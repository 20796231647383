import { createSlice } from "@reduxjs/toolkit"
import { DataConsumerModel } from "./model"
import { setRoomState } from "./roomSlice"


interface Consumers {
  [id: string]: DataConsumerModel,
}

interface State {
  consumers: Consumers,
}

export const initialState:State = {
  consumers: {},
}

const dataConsumersSlice = createSlice({
  initialState,
  name: 'dataConsumers',
  reducers: {
    addDataConsumer: (state, action: { payload : { dataConsumer: DataConsumerModel, peerId: string } }) => 
    {
      state.consumers[action.payload.dataConsumer.id] = action.payload.dataConsumer
    },

    removeDataConsumer: (state, action: { payload: { dataConsumerId: string, peerId: string }}) => 
    {
      delete state.consumers[action.payload.dataConsumerId]
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(setRoomState, (state, action: { payload: string }) => {
      if (action.payload === 'closed') {
        return initialState
      }
    })
  },
})


export default dataConsumersSlice.reducer

export const {
  addDataConsumer,
  removeDataConsumer,
} = dataConsumersSlice.actions
