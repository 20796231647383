import React, { useEffect, useState, useCallback } from 'react'
import { Button, Calendar } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { CalendarEvent } from './CalendarComponent'
import { MeetingType, setNewMeeting } from './redux/calendarSlice'
import '../../index.scss'
import '../calendar/calendar.scss'
import '../calendar/calendarMenu.scss'
import { IoAddCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

//TODP: Finish menu + check responsive

interface Props {
  meetings: CalendarEvent[] | undefined
  selectedDate: string
  pickedDateSetter: (selectedDate: string) => void
}

/**
 *
 * @param date
 * @param selectedDate
 * @param meetings
 * @returns used to render the calendar companion
 */
const CellRenderer = (
  date: Dayjs,
  selectedDate: string,
  meetings: CalendarEvent[] | undefined,
) => {
  const isSameMonth = date.format('MM') === dayjs(selectedDate).format('MM')

  if (!isSameMonth) {
    return null
  }

  let meetingClass = 'date'
  const isToday = date.isSame(dayjs(), 'day')

  if (date.isSame(dayjs(selectedDate), 'day')) {
    meetingClass += ' selected'
  }

  if (isToday) {
    meetingClass += ' today'
  }

  if (meetings) {
    for (const appointment of meetings) {
      const appointmentDate = dayjs(appointment.start).format('YYYY-MM-DD')

      if (date.isSame(appointmentDate, 'day')) {
        if (appointment.meetingsType === MeetingType.upcoming) {
          meetingClass += ' meeting'
        }

        if (appointment.meetingsType === MeetingType.pending) {
          meetingClass += ' pending'
        }
      }
    }
  }

  return <div className={meetingClass}>{date.date()}</div>
}

function CalendarMenu({ meetings, selectedDate, pickedDateSetter }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation('meetings')

  return (
    <div className="calendar-menu">
      <div className="menu-action-btn-container">
        <Button
          className="menu-action-btn"
          type="primary"
          icon={<IoAddCircle size="1.5rem" color="white" />}
          onClick={() =>
            dispatch(setNewMeeting({ meetingStartDate: dayjs(), meetingDuration: 30 }))
          }
        >
          {t('Create meeting', { ns: 'meetings' })}
        </Button>
      </div>
      <Calendar
        fullscreen={false}
        defaultValue={dayjs()}
        onSelect={(value) => pickedDateSetter(value.toISOString())}
        value={dayjs(selectedDate)}
        fullCellRender={(value) => CellRenderer(value, selectedDate, meetings)}
      />
    </div>
  )
}

export default CalendarMenu
