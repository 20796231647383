import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  attemptFetchNotifications,
  idleAttemptFetchNotificationsStatus,
} from './notificationsSlice'
import { useTranslation } from 'react-i18next'
import NotificationsHeader from './NotificationsHeader'
import NotificationsMenu from './NotificationsMenu'
import { Notification } from '../../components/NotificationWidget/liveWidgetSlice'
import NotificationsTable from './NotificationsTable'
import useNotificationDisplayed from '../../utils/hooks/useNotificationsDisplayed'

export enum NotificationsItem {
  All = 'All',
  Meetings = 'Meetings',
  Planning = 'Planning',
  Team = 'Team',
  Drive = 'Drive',
  Transfer = 'Transfer',
  Organization = 'Organisation', // Translation to modify
}

export default function Notifications() {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch<AppDispatch>()
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications,
  )
  const currentPage = useSelector((state: RootState) => state.notifications.currentPage)

  const [notificationsItem, setNotificationsItem] = useState<NotificationsItem>(
    NotificationsItem.All,
  )
  const [notificationsDisplayed, setNotificationsDisplayed] = useState<
    Notification[] | undefined
  >(notifications)

  useEffect(() => {
    if (currentPage) {
      dispatch(attemptFetchNotifications())
    }

    return () => {
      dispatch(idleAttemptFetchNotificationsStatus())
    }
  }, [currentPage])

  useNotificationDisplayed({
    notificationsItem,
    setNotificationsDisplayed,
  })

  function NotificationsAside() {
    return (
      <NotificationsMenu
        notificationsItem={notificationsItem}
        setNotificationsItem={setNotificationsItem}
      />
    )
  }

  function NotificationsContent() {
    return (
      <>
        <NotificationsHeader
          title={
            notificationsItem == 'All'
              ? t('All your notifications')
              : `${t('Your notifications of')} ${notificationsItem}`
          }
          notifications={notificationsDisplayed}
          setNotificationsDisplayed={setNotificationsDisplayed}
        />
        <NotificationsTable
          notifications={notificationsDisplayed}
          setNotificationsDisplayed={setNotificationsDisplayed}
        />
      </>
    )
  }

  return (
    <Layout
      className="notifications_page"
      title={t('Notifications', { ns: 'account' })}
      menu={<NotificationsAside />}
      content={<NotificationsContent />}
    />
  )
}
