import dayjs from "dayjs"
import i18n from "../i18n"
import { ContentTypes } from "../features/Drives/redux/drivesSlice"
import { RoomInterface, RoomParticipant, RoomParticipantRoleEnum } from '../features/room/RoomInterface'
import { store } from "../store"

/**
 * Format date according to dayjs.locale
 */
export function localFormatDate(date: dayjs.Dayjs){
  return date.format(
    `[${
      date.format('DD/MM/YYYY')
    }] [${i18n.t('at', {ns: 'calendar'})}] LT`,
  )
}

export const ENV_CONSTANTS = JSON.parse(process.env.REACT_APP_FAQ_INFORMATION || "")

/**
 * Limit Text to a certain number of characters (default 100)
 */
export function limitText(title:string | undefined, defaultNumber=100): string {
  if (!title) {
    return ''
  }
  if (title.length < defaultNumber) {
    return title
  } 
  return title.slice(0, defaultNumber - 3) + '...'
}


export function renderIcon(mimeType: string, fileName: string): string {
  switch (mimeType) {
    case ContentTypes.PDF:
      return '/images/auxadrive/pdf.png'
    case ContentTypes.JPEG:
    case ContentTypes.JPG:
      return '/images/auxadrive/jpg.png'
    case ContentTypes.PNG:
      return '/images/auxadrive/png.png'
    case ContentTypes.XLSX:
    case ContentTypes.XLS:
      return '/images/auxadrive/xls.png'
    case ContentTypes.DOC:
    case ContentTypes.DOCS:
      return '/images/auxadrive/doc.png'
    case ContentTypes.TXT:
      return '/images/auxadrive/txt.png'
    case ContentTypes.PPT:
    case ContentTypes.PPTX:
      return '/images/auxadrive/ppt.png'
    case ContentTypes.HTML:
      return '/images/auxadrive/html.png'
    case ContentTypes.CSS:
      return '/images/auxadrive/css.png'
    case ContentTypes.JS:
      return '/images/auxadrive/js.png'
    case ContentTypes.DCM:
      return '/images/auxadrive/dicom.png'
    case ContentTypes.MP3:
      return '/images/auxadrive/mp3.png'
    case ContentTypes.MP4:
      return '/images/auxadrive/mp4.png'
    case ContentTypes.CSV:
      return '/images/auxadrive/csv.png'
    default:
      if (fileName.toLowerCase().endsWith('.dcm')) {
        return '/images/auxadrive/dicom.png'
      }
      return '/images/auxadrive/default.png'
  }
}

export function formatBytes(bytes: number, decimals = 1) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${i18n.t(sizes[i], { ns: "drives" })}`
}

export function downloadEncryptedFile(id: string, encryptKey: string) {
  const link = document.createElement('a')
  link.href = `${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${id}/${encryptKey}?download=true`
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const allowedTagsObject = { allowedTags: ['b', 'p', 'ol', 'ul', 'li','strong', 'em', 's', 'u', 'br'] }

export const hasAccessFiles = (room?: RoomInterface) => {
  const { creator, participants = [], invited = [], startDate } = room || {}
  const allParticipants = [...participants, ...invited]
  const state = store.getState()
  const email = state.auth.email ?? state.invite.email
  const userRole = allParticipants.find(participant => participant.email === email)?.role

  return (
    email === creator?.email ||
    userRole === RoomParticipantRoleEnum.Proofreader ||
    userRole === RoomParticipantRoleEnum.Moderator ||
    (userRole === RoomParticipantRoleEnum.Document && startDate && dayjs().isAfter(startDate))
  )
}

export function getAllRoomMembers(room: RoomInterface): RoomParticipant[] {
  const { creator, participants, invited } = room

  const allParticipants: RoomParticipant[] = []

  if (creator) {
    allParticipants.push({ ...creator, role: RoomParticipantRoleEnum.Creator })
  }

  if (participants) {
    allParticipants.push(...participants)
  }

  if (invited) {
    allParticipants.push(...invited)
  }

  return allParticipants
}
