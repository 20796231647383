import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../utils/FetchStatus'
import customFetch from '../../customFetch'
import ApiError from '../../utils/errors/ApiError'

export interface ViewFile {
  id?: string,
  size?: number,
  mimeType?: string,
  name?: string,
}

interface ViewerState {
  fileToView?: {
    file: ViewFile
    encryptKey: string
  }
  fetchFileMetadataStatus: FetchStatus
}

export const initialState: ViewerState = {
  fetchFileMetadataStatus: 'idle',
}

export const fetchFileMetadata = createAsyncThunk(
  'newRoom/fetchFileMetadataStatus',
  async (payload: {
    uid: string, 
    encryptKey: string,
  }, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await customFetch(`${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/info/${payload.uid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
    })

    const body = await response.json()
    if (!response.ok) {
      const reason = body.reason || "unknown_error"
      throw new ApiError(reason)
    }

    return {
      file: body as ViewFile,
      encryptKey: payload.encryptKey,
    }
  }
)

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setFileToView(state, { payload }: PayloadAction<{ file: ViewFile, encryptKey: string } | undefined>) {
      state.fileToView = payload
    },
    idleViewer: (state) => {
      state.fileToView = undefined
    },  
  },
  extraReducers: {
    [fetchFileMetadata.pending.type]: (state, action) => {
      state.fetchFileMetadataStatus = "loading"
    },
    [fetchFileMetadata.fulfilled.type]: (state, action: PayloadAction<{
      file: ViewFile,
      encryptKey: string
    }>) => {
      state.fetchFileMetadataStatus = "success"
      state.fileToView = action.payload
    },
    [fetchFileMetadata.rejected.type]: (state, action) => {
      state.fetchFileMetadataStatus = "error"
    },
  }
})

export const {
  setFileToView,
  idleViewer
} = viewerSlice.actions

export default viewerSlice.reducer
