import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Dayjs } from "dayjs"
import customFetch from "../../../customFetch"
import { FetchStatus } from "../../../utils/FetchStatus"
import { stat } from "fs"

interface State {
  newMeeting?: {
    meetingStartDate?: Dayjs
    meetingDuration?: number
  },
  updateMeetingDrawerOpen: boolean,
  moveMeetingModalOpen: boolean,
  updateColorStatus: FetchStatus,
}


export enum MeetingType {
  upcoming = "upcoming",
  archived = "archived",
  pending = "pending",
}

export const initialState: State = {
  updateMeetingDrawerOpen: false,
  moveMeetingModalOpen: false,
  updateColorStatus: 'idle',
}

export const updateColor = createAsyncThunk(
  'calendar/updateColorStatus',
  async (payload: { color: string, meetingId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await customFetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/room/update-color-planning',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify(payload)
      },
    )

    if (!response.ok) {
      throw new Error('Could not update color')
    }
  },
)

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setNewMeeting: (state, { payload }: PayloadAction<{ meetingStartDate: Dayjs, meetingDuration: number } | undefined>) => {
      state.newMeeting = payload
    },
    setUpdateMeetingDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.updateMeetingDrawerOpen = payload
    },
    setMoveMeetingModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.moveMeetingModalOpen = payload
    },
    idleUpdateColorStatus: (state) => {
      state.updateColorStatus = 'idle'
    }
  },
  extraReducers: {
    [updateColor.pending.type]: (state,action) => {
      state.updateColorStatus = 'loading'
    },
    [updateColor.fulfilled.type]: (state,action: PayloadAction<{}>) => {
      state.updateColorStatus = 'success'
    },
    [updateColor.rejected.type]: (state,action) => {
      const error = action.error
      state.updateColorStatus = 'error'
      state.updateColorStatus = error.message ?? 'unknown_error'
    },
  }
})


export const {
  setNewMeeting,
  setUpdateMeetingDrawerOpen,
  setMoveMeetingModalOpen,
  idleUpdateColorStatus,
} = calendarSlice.actions

export default calendarSlice.reducer