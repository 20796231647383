import { AutoComplete, Form } from 'antd'
import React, { useState } from 'react'
import {
  SearchGroupDTO,
  SearchUserDTO,
  searchEntities,
} from '../../features/Organisation/OrganisationService'
import { useTranslation } from 'react-i18next'

interface SelectOption {
  value: string
  label: string
  type: 'user' | 'group' | 'title'
  email?: string
  firstName?: string
  lastName?: string
  id?: string
}

interface Option {
  label: React.ReactNode
  options: SelectOption[]
}

interface Props {
  jwt: string
  email: string
  organisationId: string
  peers: SearchUserDTO[]
  groups: SearchGroupDTO[]
  onUserSelect: (user: SearchUserDTO) => void
  onGroupSelect: (group: SearchGroupDTO) => void
}

export default function UserAndGroupAutocomplete({
  jwt,
  email,
  organisationId,
  peers,
  groups,
  onUserSelect,
  onGroupSelect,
}: Props) {
  const { t } = useTranslation('chat')
  const [options, setOptions] = useState<Option[]>([])
  const [addPeerForm] = Form.useForm()

  const onSearch = async (q: string) => {
    setOptions([])

    if (!organisationId) {
      return
    }

    try {
      const entities = await searchEntities({
        jwt,
        q,
        organisationId,
        excludeEmails: [...peers.map((p) => p.email), email],
        excludeGroupIds: [...groups.map((g) => g.id)],
      })

      const userSelectOptions: SelectOption[] = entities.users.map(
        ({ email, firstName, lastName }) => ({
          value: email,
          label: `${firstName} ${lastName} (${email})`,
          type: 'user',
          firstName,
          lastName,
        }),
      )

      const groupSelectOptions: SelectOption[] = entities.groups.map(({ id, name }) => ({
        value: name,
        label: name,
        type: 'group',
        id,
      }))

      const optionsFull: Option[] = [
        {
          label: <span>{t('Users', { ns: 'layout' })}</span>,
          options: userSelectOptions,
        },
        {
          label: <span>{t('Groups', { ns: 'groups' })}</span>,
          options: groupSelectOptions,
        },
      ]

      setOptions(optionsFull)
    } catch (e) {
      console.error(e)
    }
  }

  function doOnSelect(value: any, option: any) {
    setOptions([])
    addPeerForm.resetFields(['peer'])

    if (option.type === 'user') {
      onUserSelect({
        email: option.value,
        firstName: option.firstName,
        lastName: option.lastName,
      })
    }

    if (option.type === 'group') {
      onGroupSelect({
        id: option.id,
        name: option.value,
      })
    }
  }

  return (
    <Form form={addPeerForm} layout="horizontal">
      <Form.Item style={{ marginTop: '0px' }} name="peer">
        <AutoComplete options={options} onSelect={doOnSelect} onSearch={onSearch} />
      </Form.Item>
    </Form>
  )
}
