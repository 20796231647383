import React from 'react'
import { Badge, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { IoIosNotificationsOutline } from 'react-icons/io'
import { LOGO } from '../../assets/logos'
import './Notifications.scss'
import { NotificationsItem } from './Notifications'
import { MenuProps } from 'antd/lib'

interface Props {
  notificationsItem: NotificationsItem
  setNotificationsItem: (notificationsItem: NotificationsItem) => void
}

//TODO: notifications count for each type : CTRL F : "0"

function NotificationsMenu({ notificationsItem, setNotificationsItem }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation('layout')
  const unseen = useSelector((state: RootState) => state.liveNotification.unseen)

  const items: MenuProps['items'] = [
    {
      key: 'notifications-All',
      label: (
        <div className="notification_menu_text">
          {t('All notifications', { ns: 'notification' })}
          <Badge count={unseen} />
        </div>
      ),
      icon: <IoIosNotificationsOutline size="2em" />,
      onClick: () => setNotificationsItem(NotificationsItem.All),
    },
    {
      key: 'notifications-Meetings',
      label: (
        <div className={`notification_menu_text notification_menu_text_Meetings`}>
          Meetings
          <Badge count={0} />
        </div>
      ),
      icon: <img src={LOGO.logo_auxameetings} className="notification_menu_icon" />,
      onClick: () => setNotificationsItem(NotificationsItem.Meetings),
    },
    {
      key: 'notifications-Planning',
      label: (
        <div className="notification_menu_text notification_menu_text_Planning">
          Planning
          <Badge count={0} />
        </div>
      ),
      icon: <img src={LOGO.logo_auxaplanning} className="notification_menu_icon" />,
      onClick: () => setNotificationsItem(NotificationsItem.Planning),
    },
    {
      key: 'notifications-Team',
      label: (
        <div className="notification_menu_text notification_menu_text_Team">
          Team
          <Badge count={0} />
        </div>
      ),
      icon: <img src={LOGO.logo_auxateam} className="notification_menu_icon" />,
      onClick: () => setNotificationsItem(NotificationsItem.Team),
    },
    {
      key: 'notifications-Drive',
      label: (
        <div className="notification_menu_text notification_menu_text_Drive">
          Drive
          <Badge count={0} />
        </div>
      ),
      icon: <img src={LOGO.logo_auxadrive} className="notification_menu_icon" />,
      onClick: () => setNotificationsItem(NotificationsItem.Drive),
    },
    {
      key: 'notifications-Transfer',
      label: (
        <div className="notification_menu_text notification_menu_text_Transfer">
          Transfer
          <Badge count={0} />
        </div>
      ),
      icon: <img src={LOGO.logo_auxatransfer} className="notification_menu_icon" />,
      onClick: () => setNotificationsItem(NotificationsItem.Transfer),
    },
    {
      key: 'notifications-Organisation',
      label: (
        <div className="notification_menu_text">
          {t('Organisation', { ns: 'common' })}
          <Badge count={0} />
        </div>
      ),
      icon: <img src={LOGO.logo_groups} className="notification_menu_icon" />,
      onClick: () => setNotificationsItem(NotificationsItem.Organization),
    },
  ]

  return (
    <div className="notification_menu_to_align">
      <Menu
        className="menu"
        mode="inline"
        selectedKeys={['notifications-' + notificationsItem]}
        items={items}
      />
    </div>
  )
}
export default NotificationsMenu
