import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

function checkDevice():boolean {
  let details = navigator.userAgent
  let regexp = /android|iphone|kindle|ipad/i
  let isMobileDevice = regexp.test(details)
  return isMobileDevice
}

interface State {
  isPhone: boolean
  fullscreen: boolean
  isUnauthorized: boolean //Unauthorized access modal is disabled
}


export const initialState:State = {
  isPhone: false,
  fullscreen: false,
  isUnauthorized: false,
}

/**
 * Allows to switch to full screen mode
 */
export const toggleFullScreen = createAsyncThunk(
  'app/toggleFullScreen',
  async (_, { dispatch }) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      dispatch(setFullscreen({ fullscreen: false }));
    } else {
      document.documentElement.requestFullscreen();
      dispatch(setFullscreen({ fullscreen: true }));
    }
  }
)

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsPhone: (state) => {
      state.isPhone = checkDevice()
    },
    setFullscreen: (
      state,
      action: PayloadAction<{fullscreen: boolean}>,
    ) => {
      state.fullscreen = action.payload.fullscreen
    },
    setIsUnauthorized: (state, { payload }: PayloadAction<boolean>) =>{
      state.isUnauthorized = payload
    },
  },
})


export default appSlice.reducer


export const {
  setIsPhone,
  setFullscreen,
  setIsUnauthorized,
} = appSlice.actions
