import React, { useEffect } from 'react'
import { Drawer, Spin } from 'antd'
import RoomManage from '../../components/RoomManage/RoomManage'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { setRoomToModify } from '../Meetings/MeetingsSlice'
import { setUpdateMeetingDrawerOpen } from './redux/calendarSlice'

function UpdateMeetingDrawer() {
  const roomToModify = useSelector((state: RootState) => state.rooms.roomToModify)
  const updateMeetingDrawerOpen = useSelector(
    (state: RootState) => state.calendar.updateMeetingDrawerOpen,
  )
  const { t } = useTranslation('calendar')
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    return () => {
      dispatch(setUpdateMeetingDrawerOpen(false))
      dispatch(setRoomToModify())
    }
  }, [])

  /**
   *
   */
  function onUpdateMeetingDrawerClose() {
    dispatch(setUpdateMeetingDrawerOpen(false))
    dispatch(setRoomToModify())
  }

  return (
    <Drawer
      width="40rem"
      title={
        <div className="drawer-title">
          <span>{t('Manage meeting', { ns: 'meetings' })}</span>
        </div>
      }
      placement="right"
      open={updateMeetingDrawerOpen}
      onClose={onUpdateMeetingDrawerClose}
      mask={true}
    >
      {roomToModify ? (
        <RoomManage
          room={roomToModify}
          close={onUpdateMeetingDrawerClose}
          showTitle={false}
          fromCalendar={true}
        />
      ) : (
        <div className="d-flex d-flex-center">
          <Spin />
        </div>
      )}
    </Drawer>
  )
}

export default UpdateMeetingDrawer
