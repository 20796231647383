import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  deleteRoom as fetchDeleteRoom,
  idleDeleteRoomStatus,
  fetchRooms,
  setRoomToModify,
  setRoomToDelete,
  idleArchiveRoomStatus,
  RoomToModify,
} from '../MeetingsSlice'
import Layout from '../../../components/Layout/Layout'
import RoomManage from '../../../components/RoomManage/RoomManage'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import MeetingHeader from '../MeetingHeader'
import MeetingsTable from '../MeetingsTable'
import { useAttemptsListener } from 'auxasphere-react-kit'
import '../meetings.scss'
import ModalDeleteMeeting from '../../../components/Modal/ModalDeleteMeeting'
import MeetingsMenu from '../MeetingsMenu'
import { useToastContext } from '../../../components/Toast/ToastContext'

function ArchivedMeetings() {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const archiveRoomStatus = useSelector(
    (state: RootState) => state.rooms.archiveRoomStatus,
  )
  const deleteRoomStatus = useSelector((state: RootState) => state.rooms.deleteRoomStatus)
  const archived = useSelector((state: RootState) => state.rooms.rooms.archived)
  const [roomModify, setRoomModify] = useState<RoomToModify | undefined>(undefined)

  dayjs.extend(duration)

  useAttemptsListener(
    [
      [
        archiveRoomStatus,
        {
          success: () => {
            dispatch(setRoomToModify())
            dispatch(fetchRooms({ status: 'archived' }))
          },
        },
      ],
      [
        deleteRoomStatus,
        {
          success: () => {
            dispatch(setRoomToDelete({ room: undefined, pending: false }))
            ToastOpen({
              message: t('Meeting was successfully deleted.'),
              type: 'success',
            })
            dispatch(fetchRooms({ status: 'archived' }))
          },
          error: () => {
            ToastOpen({
              message: t('Error deleting meeting.'),
              type: 'error',
            })
          },
        },
      ],
    ],
    () => {
      dispatch(idleArchiveRoomStatus())
      dispatch(idleDeleteRoomStatus())
    },
    () => dispatch(fetchRooms({ status: 'archived' })),
  )

  function onCloseRoomManage() {
    setRoomModify(undefined)
  }

  function renderArchivedMeetingMenu() {
    return <MeetingsMenu />
  }

  function renderArchivedMeetingContent() {
    if (roomModify) {
      return <RoomManage room={roomModify} close={onCloseRoomManage} />
    } else {
      return (
        <>
          <MeetingHeader title={t('Your archived meetings')} />
          <MeetingsTable archived={true} data={archived} setRoomModify={setRoomModify} />
        </>
      )
    }
  }

  return (
    <>
      <Layout
        title="Meetings"
        menu={renderArchivedMeetingMenu()}
        content={renderArchivedMeetingContent()}
      />
      <ModalDeleteMeeting />
    </>
  )
}

export default ArchivedMeetings
