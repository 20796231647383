import React, { useRef } from 'react'
import { Tooltip } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { RoomParticipant } from '../RoomInterface'
import { attemptDeleteParticipant } from '../redux/roomSlice'
import './RoomParticipantDelete.scss'
import { AppDispatch } from '../../../store'
import { BsFillXSquareFill } from 'react-icons/bs'
import { AiFillApi } from 'react-icons/ai'
import ModalConfirmation, {
  ModalConfirmationInterface,
} from '../../../components/Modal/ModalConfirmation'
import { limitText } from '../../../utils/Utils'

interface RoomParticipantProps extends RoomParticipant {
  networkTrouble: boolean
}

function RoomParticipantDelete({
  email,
  firstName,
  lastName,
  role,
  networkTrouble,
}: RoomParticipantProps) {
  const { t } = useTranslation('room')
  const dispatch = useDispatch<AppDispatch>()
  const modalDeleteParticipantConfirm = useRef<ModalConfirmationInterface>(null)
  const onRemovePeer = (participant: string) => {
    dispatch(attemptDeleteParticipant(participant))
  }

  return (
    <div className="room-participant-item" key={email}>
      <div className="room-participant-item-body">
        <div className="room-participant-item-body-email">
          <div className="room-participant-item-body-email-max">{email}</div>
          {networkTrouble && (
            <span className="ml-05rem d-flex d-flex-center">
              <Tooltip title={t('Network trouble')}>
                <AiFillApi />
              </Tooltip>
            </span>
          )}
        </div>
        <div className="room-participant-item-body-role">{t(role)}</div>
      </div>
      <div className="room-participant-item-actions">
        <Tooltip title={t('Delete', { ns: 'common' })}>
          <BsFillXSquareFill
            role="button"
            className="room-participant-item-actions-buttons error-color"
            onClick={() => modalDeleteParticipantConfirm?.current?.openModal()}
          />
        </Tooltip>
      </div>
      <ModalConfirmation
        title={t('Delete participant')}
        ref={modalDeleteParticipantConfirm}
        okText={t('Delete')}
        onOk={() => onRemovePeer(email)}
      >
        <p>
          {firstName && lastName ? (
            <Trans
              i18nKey={'DELETE_PARTICIPANT'}
              shouldUnescape={true}
              ns="room"
              values={{ email: email, name: `${firstName} ${lastName}` }}
              components={{ bold: <strong /> }}
            />
          ) : (
            <Trans
              i18nKey={'DELETE_INVITED'}
              shouldUnescape={true}
              ns="room"
              values={{ email: email }}
              components={{ bold: <strong /> }}
            />
          )}
        </p>
      </ModalConfirmation>
    </div>
  )
}

export default RoomParticipantDelete
