import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type NotificationType = "DELETED" 
  | "CHAT_MESSAGE"
  | "DRIVE_CREATED"
  | "DRIVE_DELETED"
  | "PARTICIPANT_ADDED_TO_DRIVE"
  | "PARTICIPANT_REMOVED_FROM_DRIVE"
  | "ADDED_TO_MEETING"
  | "REMOVED_FROM_MEETING"

export interface Notification {
  id: string
  type: NotificationType
  groupedCount?: number
  createdAt?: string
  misc?: any
  unseenCount?: number
}

interface State {
  notifications: Notification[]
  total: number
  unseen?: number 
}

export const initialState: State = {
  notifications: [],
  total: 0,
}

interface AttemptFetchLiveNotificationsResponse {
  notifications: Notification[]
  totalCount: number
  unseenCount: number
}

export const attemptFetchLiveNotifications = createAsyncThunk(
  'notification/attemptFetchLiveNotifications',
  async (payload, { getState }) => {
    const { auth } = getState() as any

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/notification/persisted/chunk/0`, {
      method: 'GET',
      headers: {
        'Authorization': `${auth.jwt}`,
      },
    })

    return await response.json()
  }
)

export const attemptMarkAllNotificationsSeen = createAsyncThunk(
  'notification/attemptMarkAllNotificationsSeen',
  async (payload, { getState }) => {
    const { auth } = getState() as any

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/notification/mark-seen`, {
      method: 'POST',
      headers: {
        'Authorization': `${auth.jwt}`,
      },
    })

    return await response.json()
  }
)

export const attemptDeleteAllNotifications = createAsyncThunk(
  'notification/attemptDeleteAllNotifications',
  async (payload, { getState }) => {
    const { auth } = getState() as any

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/notification/delete-all`, {
      method: 'POST',
      headers: {
        'Authorization': `${auth.jwt}`,
      },
    })
  }
)

export const attemptMarkNotificationSeen = createAsyncThunk(
  'notification/attemptMarkNotificationSeen',
  async (notification: Notification, { getState }) => {
    const { auth } = getState() as any

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/notification/${notification.id}/mark-seen`, {
      method: 'POST',
      headers: {
        'Authorization': `${auth.jwt}`,
      },
    })

    return notification
  }
)

export const attemptDeleteNotification = createAsyncThunk(
  'notification/attemptDeleteNotification',
  async (notification: Notification, { getState }) => {
    const { auth } = getState() as any

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/notification/${notification.id}/delete`, {
      method: 'POST',
      headers: {
        'Authorization': `${auth.jwt}`,
      },
    })

    return notification
  }
)

export const liveNotificationSlice = createSlice({
  name: 'liveNotification',
  initialState,
  reducers: {
    addLiveNotification: (state, { payload }: PayloadAction<Notification>) => {
      // In case of grouped
      state.notifications = state.notifications.filter(n => n.id != payload.id)
      state.notifications.unshift(payload)
      // Limit size to 10
      state.notifications = state.notifications.slice(0, 10)
      if (state.unseen === undefined) { //first unseen
        state.unseen = 1
      } else {
        state.unseen++
      }
      state.total++
    },
  },
  extraReducers: {
    [attemptFetchLiveNotifications.pending.type]: (state) => {
      state.notifications = []
    },
    [attemptFetchLiveNotifications.fulfilled.type]: (state, { payload }: PayloadAction<AttemptFetchLiveNotificationsResponse>) => {
      state.notifications = (payload.notifications || []).slice(0, 10)
      state.total = payload.totalCount || 0
      state.unseen = payload.unseenCount || 0
    },
    [attemptFetchLiveNotifications.rejected.type]: (state) => {
      state.notifications = []
    },
    [attemptMarkAllNotificationsSeen.fulfilled.type]: (state, { payload }: PayloadAction<AttemptFetchLiveNotificationsResponse>) => {
      state.notifications = (payload.notifications || []).slice(0, 10)
      state.total = payload.totalCount || 0
      state.unseen = payload.unseenCount || 0
    },
    [attemptDeleteAllNotifications.fulfilled.type]: (state) => {
      state.notifications = []
      state.total = 0
      state.unseen = 0
    },
    [attemptMarkNotificationSeen.fulfilled.type]: (state, { payload }: PayloadAction<Notification>) => {
      for (const n of state.notifications) {
        if (n.id === payload.id) {
          n.unseenCount = 0
        }
      }
      state.unseen && (state.unseen -= payload.unseenCount || 0)
    },
    [attemptDeleteNotification.fulfilled.type]: (state, { payload }: PayloadAction<Notification>) => {
      state.notifications = state.notifications.filter(n => n.id !== payload.id)
      state.total -= payload.groupedCount || 0
      state.unseen && (state.unseen -= payload.unseenCount || 0)
    },
  }
})

export const {
  addLiveNotification
} = liveNotificationSlice.actions

export default liveNotificationSlice.reducer
