import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './HeaderMenuNavigation.scss'
import classNames from 'classnames'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { attemptCheckPersonalizationAvailable } from '../../features/Personalization/PersonalizationSlice'
import { toggleFullScreen } from '../../redux/appSlice'
import { LOGO } from '../../assets/logos'
import { Tooltip } from 'antd'

interface Props {
  handleTogglePopover: () => void
}

function HeaderMenuManagment({ handleTogglePopover }: Props) {
  const { t } = useTranslation('layout')
  const dispatch = useDispatch<AppDispatch>()
  const isAdmin = useSelector((state: RootState) => state.auth.organizationAdmin)
  const personalizationAvailable = useSelector(
    (state: RootState) => state.personalization.available,
  )

  const fullscreen = useSelector((state: RootState) => state.app.fullscreen)

  useEffect(() => {
    dispatch(attemptCheckPersonalizationAvailable())
  }, [])

  return (
    <div className="menu-navigation-container">
      <h3 className="main-title mt-1rem">{t('Management', { ns: 'layout' })}</h3>
      <div className="menu-navigation-content">
        <a
          className={classNames({
            'menu-navigation-element': true,
          })}
          href="/applications"
        >
          <span className="menu-navigation-logo-container">
            <img src={LOGO.menu} className="menu-navigation-logo" />
          </span>
          {t('Menu')}
        </a>
        <a
          className={classNames({
            'menu-navigation-element': true,
            'menu-navigation-element--active-organisation':
              location.pathname.includes('organization'),
          })}
          href="/organization"
        >
          <span className="menu-navigation-logo-container">
            <img src={LOGO.logo_users} className="menu-navigation-logo" />
          </span>
          {t('Members', { ns: 'common' })}
        </a>
        <a
          className={classNames({
            'menu-navigation-element': true,
            'menu-navigation-element--active-organisation':
              location.pathname.includes('groups'),
          })}
          href="/groups"
        >
          <span className="menu-navigation-logo-container">
            <img src={LOGO.logo_groups} className="menu-navigation-logo" />
          </span>
          {t('Groups')}
        </a>
        <Tooltip title={t('Coming soon')}>
          <a
            className={classNames({
              'menu-navigation-element': true,
            })}
          >
            <span className="menu-navigation-logo-container">
              <img
                src={'/logo/logo_customer_area.svg'}
                className="menu-navigation-logo"
              />
            </span>
            {t('Customer area')}
          </a>
        </Tooltip>
        <a
          className={classNames({
            'menu-navigation-element': true,
            'menu-navigation-element--active-faq': location.pathname.includes('faq'),
          })}
          href="/faq"
          target="_blank"
        >
          <span className="menu-navigation-logo-container">
            <img src={LOGO.FAQ} className="menu-navigation-logo" />
          </span>
          {t('FAQ')}
        </a>
        <a
          className={classNames({
            'menu-navigation-element': true,
          })}
          onClick={() => {
            dispatch(toggleFullScreen())
            handleTogglePopover()
          }}
        >
          {fullscreen ? (
            <>
              <span className="menu-navigation-logo-container">
                <img src={LOGO.zoom_out} className="menu-navigation-logo" />
              </span>
              {t('Exit full screen', { ns: 'room' })}
            </>
          ) : (
            <>
              <span className="menu-navigation-logo-container">
                <img src={LOGO.zoom_in} className="menu-navigation-logo" />
              </span>
              {t('Full screen', { ns: 'room' })}
            </>
          )}
        </a>
      </div>
      {isAdmin && personalizationAvailable && (
        <a
          className="text-center underline cursor-pointer black-color"
          href="/personalization/preview"
          target="_blank"
        >
          {t('Personalization')}
        </a>
      )}
    </div>
  )
}

export default HeaderMenuManagment
