import React, { useEffect, MutableRefObject} from "react"
import RoomClient from "../../features/room/RoomClient"


/**
 *
 * @param listeners
 * @param onBeforeSubscribe 
 * @param onAfterSubscribe
 * @param onReturn 
 * @param onIntervalFunction - {interval in ms, action: () => {}}
 */
export default function useRoomListener(
  roomClient: MutableRefObject<RoomClient>,
  listeners: {[key: string]: (...args: any[]) => void},
  onBeforeSubscribe?: () => void,
  onAfterSubscribe? : () => void,
  onReturn?         : () => void,
  onIntervalFunction? : {interval: number, action: () => void},
) {

  useEffect(() => {
    const interval = setInterval(() => {
      onIntervalFunction?.action()
    }, onIntervalFunction?.interval)
    return () => clearInterval(interval)
  }, [onIntervalFunction?.interval, onIntervalFunction?.action])


  useEffect(() => {
    onBeforeSubscribe && onBeforeSubscribe()
    Object.entries(listeners).forEach(([key, value]) => {
      roomClient.current.on(key, value)
    })

    onAfterSubscribe && onAfterSubscribe()

    return () => {
      onReturn && onReturn()
      Object.entries(listeners).forEach(([key, value]) => {
        roomClient.current.off(key, value)
      })
    }
  }, [])

}
