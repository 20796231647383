import React, { useRef, useState } from 'react'
import { Button, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/Layout/Layout'
import OrganisationMenu from '../Organisation/OrganisationMenu'
import CreateGroupModal, { CreateGroupModalInterface } from './CreateGroupModal'
import GroupsList from './GroupsList/GroupsList'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
function Groups() {
  const { t } = useTranslation('groups')
  const createGroupModalRef = useRef<CreateGroupModalInterface>(null)
  const isAdmin = useSelector((state: RootState) => state.auth.organizationAdmin)

  function renderGroupsMenu() {
    return <OrganisationMenu />
  }

  function renderGroupsContent() {
    return (
      <>
        <div className="layout-header-bottom">
          <h4 className="layout-list-header">{t('Groups management')}</h4>
        </div>
        <Divider />
        <GroupsList />
      </>
    )
  }

  return (
    <>
      <Layout
        title={t('Organisation', { ns: 'common' })}
        menu={renderGroupsMenu()}
        content={renderGroupsContent()}
      />
    </>
  )
}

export default Groups
