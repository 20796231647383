import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import ApiError from '../../../utils/errors/ApiError'
import { FetchStatus } from '../../../utils/FetchStatus'
import { setJWT } from '../../auth/redux/authSlice'
import { setOrganisation } from '../../Organisation/redux/organisationSlice'
import customFetch from '../../../customFetch'
import jwtDecode from 'jwt-decode'


interface State {
  authReinitPasswordTokenStatus: string,
  changePasswordStatus: string,
}


export const initialState:State = {
  authReinitPasswordTokenStatus: "idle",
  changePasswordStatus: "idle",
}


/**
 * Reinit password.
 */
export const claimResetPasswordToken = createAsyncThunk(
  'reinitPassword/claimResetPasswordToken',
  async ({ token }: { token: string }, { dispatch }) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/reset-password/claim-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    const payload:any = jwtDecode(body.token)
    dispatch(setJWT(body.token))
    dispatch(setOrganisation({
      id: payload['organizationId'],
      name: payload['organizationName'],
    }))
  }
)


/**
 * Reinit password.
 */
export const changePassword = createAsyncThunk(
  'reinitPassword/changePassword',
  async ({ password }: { password: string }, { getState }) => {

    const { auth } = getState() as RootState
    const response = await customFetch(`${process.env.REACT_APP_API_BASE_URL}/users/reset-password/set-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify({ password }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


const reinitPasswordSlice = createSlice({
  name: 'reinit-password',
  initialState,
  reducers: {
    idleAuthReinitPasswordTokenStatus: (state) => {
      state.authReinitPasswordTokenStatus = "idle"
    },
    idleChangePasswordStatus: (state) => {
      state.changePasswordStatus = "idle"
    },
  },
  extraReducers: {
    [claimResetPasswordToken.pending.type]: (state) => {
      state.authReinitPasswordTokenStatus = "loading"
    },
    [claimResetPasswordToken.fulfilled.type]: (state) => {
      state.authReinitPasswordTokenStatus = "success"
    },
    [claimResetPasswordToken.rejected.type]: (state, action) => {
      const error = action.error
      state.authReinitPasswordTokenStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [changePassword.pending.type]: (state) => {
      state.changePasswordStatus = "loading"
    },
    [changePassword.fulfilled.type]: (state) => {
      state.changePasswordStatus = "success"
    },
    [changePassword.rejected.type]: (state, action) => {
      const error = action.error
      state.changePasswordStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
  },
})


export default reinitPasswordSlice.reducer


export const {
  idleAuthReinitPasswordTokenStatus,
  idleChangePasswordStatus,
} = reinitPasswordSlice.actions
