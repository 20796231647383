import { VoteResponse } from "../features/Meetings/MeetingVote/MeetingVoteSlice"
import customFetch from "../customFetch"
import { RoomToModify } from "../features/Meetings/MeetingsSlice"
import { RoomInterface } from "../features/room/RoomInterface"


export async function fetchCreateRoom(jwt: string) : Promise<RoomInterface> {
  const response = await customFetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    }
  })

  if (!response.ok) {
    throw new Error("Could not create room")
  }

  const room = await response.json() as RoomInterface
  return room
}

export async function fetchGenerateRoom(jwt: string, room: RoomInterface) : Promise<RoomInterface> {
  const response = await customFetch(process.env.REACT_APP_MEDIA_BASE_URL + '/periodicRoom/' + room.periodicRoomId + "/" + room.startDate, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
  })

  if (!response.ok) {
    throw new Error("Could not generate room")
  }

  const result = await response.json() as RoomInterface
  return result
}

export async function fetchUpdateRoom(jwt: string, room: RoomToModify) : Promise<void> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/modify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
    body: JSON.stringify({
      id: room?.id,
      name: room?.name,
      color: room?.color,
      type: room?.type,
      reference: room?.reference,
      startDate: room?.startDate,
      duration: room?.duration,
      creatorNote: room?.creatorNote,
      participants: room?.participants,
      invited: room?.invited,
      unconfirmedFiles: room?.unconfirmedFiles,
      unconfirmedFileDeletions: room?.unconfirmedFileDeletions,
      createFromPending: room?.createFromPending,
      periodicity: room.periodicity,
    }),
  })

  if (!response.ok) {
    throw new Error("Could not modify room")
  }
}

export async function fetchCreatePeriodicRoom(jwt: string, room: RoomToModify) : Promise<void> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/periodicRoom/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
    body: JSON.stringify({
      id: room?.id,
      name: room?.name,
      color: room?.color,
      type: room?.type,
      reference: room?.reference,
      duration: room?.duration,
      creatorNote: room?.creatorNote,
      participants: room?.participants,
      invited: room?.invited,
      unconfirmedFiles: room?.unconfirmedFiles,
      periodicity: room.periodicity,
    }),
  })

  if (!response.ok) {
    throw new Error("Could not create a periodic room")
  }
}

export async function fetchUpdatePeriodicRoom(jwt: string, room: RoomToModify) : Promise<void> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/periodicRoom/global/modify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
    body: JSON.stringify({
      id: room?.id,
      name: room?.name,
      color: room?.color,
      type: room?.type,
      reference: room?.reference,
      startDate: room?.startDate,
      duration: room?.duration,
      creatorNote: room?.creatorNote,
      participants: room?.participants,
      invited: room?.invited,
      unconfirmedFiles: room?.unconfirmedFiles,
      unconfirmedFileDeletions: room?.unconfirmedFileDeletions,
      periodicity: room.periodicity,
    }),
  })

  if (!response.ok) {
    throw new Error("Could not create a periodic room")
  }
}


export async function fetchUpdatePlanRoom(jwt: string, room: RoomToModify, availableDates: string[]) : Promise<void> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/plan', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
    body: JSON.stringify({
      id: room?.id,
      name: room?.name,
      color: room?.color,
      type: room?.type,
      reference: room?.reference,
      duration: room?.duration,
      creatorNote: room?.creatorNote,
      participants: room?.participants,
      invited: room?.invited,
      unconfirmedFiles: room?.unconfirmedFiles,
      unconfirmedFileDeletions: room?.unconfirmedFileDeletions,
      periodicity: room.periodicity,
      availableDates,
    }),
  })
  if (!response.ok) {
    throw new Error("Could not modify room")
  }
}


export async function fetchRoomById(roomId: string, jwt: string) : Promise<RoomInterface> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/' + roomId, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
  })

  if (!response.ok) {
    throw new Error("Could not fetch room")
  }

  const room = await response.json() as RoomInterface
  return room
}

export async function fetchPeriodicRoomById(jwt: string, pid: string) : Promise<RoomInterface> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/periodicRoom/global/' + pid, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
  })

  if (!response.ok) {
    throw new Error("Could not fetch room")
  }

  const room = await response.json() as RoomInterface
  return room
}

export async function fetchMeetingDateVotes(roomId: string, jwt: string) : Promise<{[key: string]: {[key: string]: VoteResponse}}> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/planner/vote?' + new URLSearchParams({
    meetingId: roomId,
  }), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
  })

  if (!response.ok) {
    throw new Error("Could not fetch preferences")
  }

  const votes = await response.json() as {[key: string]: {[key: string]: VoteResponse}}
  return votes
}


export async function fetchUpdateVote(roomId: string, votes: {yes: string[], no: string[], maybe: string[]}, jwt: string) : Promise<void> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/planner/vote', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
    body: JSON.stringify({
      meetingId: roomId,
      dates: votes
    }),
  })

  if (!response.ok) {
    throw new Error("Could not send preference")
  }
}


export async function fetchSendSelectedDateStatus(roomId: string, date: string, jwt: string) : Promise<void> {
  const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/planner/room/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${jwt}`,
    },
    body: JSON.stringify({
      meetingId: roomId,
      startDate: date
    }),
  })

  if (!response.ok) {
    throw new Error("Could not send date")
  }
}