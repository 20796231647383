import React, { DragEvent, MouseEvent, useState } from 'react'
import {
  FileInterface,
  setFileToRename,
  setSelectedFilesFolders,
} from '../../../features/Drives/redux/drivesSlice'
import { AiOutlineEdit, AiOutlineEye } from 'react-icons/ai'
import { Button, Dropdown, Tooltip } from 'antd'
import {
  downloadEncryptedFile,
  formatBytes,
  localFormatDate,
  renderIcon,
} from '../../../utils/Utils'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { MenuProps } from 'antd/lib'
import { IoTrashOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { BsDownload } from 'react-icons/bs'
import { ViewFile, setFileToView } from '../../Viewer/ViewerSlice'
import { compareFilesFolders } from './DrivesList'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'

interface Props {
  file: FileInterface
  handleRowClick: (select: FileInterface, event: MouseEvent) => void
  setOpenDeleteFileModal: (open: boolean) => void
  setFileToDelete: (file: FileInterface) => void
  handleDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleDragEnd: () => void
}

function FileRow({
  file,
  handleRowClick,
  setOpenDeleteFileModal,
  setFileToDelete,
  handleDragStart,
  handleDragEnd,
}: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const auth = useSelector((state: RootState) => state.auth)
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const draggingOver = useSelector((state: RootState) => state.drive.draggingOver)
  const selectedFilesFolders = useSelector(
    (state: RootState) => state.drive.selectedFilesFolders,
  )

  const driveEncryptKey = useSelector(
    (state: RootState) =>
      state.drive.drives?.find((drive) => drive.id === selectedDriveFolder?.driveId)
        ?.fileEncryptKey,
  )

  const rights = useSelector((state: RootState) => state.drive.driveRights)

  const { creatorEmail } = useDriveLoader(selectedDriveFolder?.driveId)

  const [selectedFileInTable, setSelectedFileInTable] = useState<FileInterface>()

  const fileItems: MenuProps['items'] = [
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEye size="1.2em" />
          {t('Open')}
        </span>
      ),
      onClick: () => selectedFileInTable && onFileOpen(file),
      key: `view_file_${selectedFileInTable?.id}`,
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEdit size="1.2em" />
          {t('Rename')}
        </span>
      ),
      onClick: () => setRenameFile(file),
      key: `rename_file_${selectedFileInTable?.id}`,
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <BsDownload size="1.2em" />
          {t('Download')}
        </span>
      ),
      onClick: () =>
        driveEncryptKey &&
        selectedFileInTable &&
        downloadEncryptedFile(selectedFileInTable.id, driveEncryptKey),
      key: `download_file_${selectedFileInTable?.id}`,
    },

    {
      type: 'divider',
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem error-color">
          <IoTrashOutline size="1.2em" />
          {t('Delete')}
        </span>
      ),
      className: 'delete-btn',
      onClick: () => selectedFileInTable && onDeleteFile(selectedFileInTable),
      key: `delete_file_${selectedFileInTable?.id}`,
    },
  ]

  const fileItemsDefaultRights: MenuProps['items'] = [
    {
      label: (
        <span
          className="d-flex d-flex-middle d-flex-center g-0_5rem"
          onClick={() => selectedFileInTable && onFileOpen(selectedFileInTable)}
        >
          <AiOutlineEye size="1.2em" />
          {t('Open')}
        </span>
      ),
      key: `view_file_${selectedFileInTable?.id}`,
    },
    {
      label: (
        <span
          className="d-flex d-flex-middle d-flex-center g-0_5rem"
          onClick={() =>
            driveEncryptKey &&
            selectedFileInTable &&
            downloadEncryptedFile(selectedFileInTable.id, driveEncryptKey)
          }
        >
          <BsDownload size="1.2em" />
          {t('Download')}
        </span>
      ),
      key: `download_file_${selectedFileInTable?.id}`,
    },
  ]

  const handleMouseDown = (event: MouseEvent) => {
    if (!selectedFilesFolders.includes(file) && !event.shiftKey && !event.ctrlKey) {
      dispatch(setSelectedFilesFolders([file]))
    }
  }

  /**
   * Opens the delete file modal and sets the file to delete.
   *
   * @param document - The file to be deleted.
   */
  function onDeleteFile(document: FileInterface) {
    setOpenDeleteFileModal(true)
    setFileToDelete(document)
  }

  /**
   * Sets up the modal to rename the file.
   *
   * @param document - The file to be renamed.
   */
  function setRenameFile(document?: FileInterface) {
    if (selectedDriveFolder && document) {
      dispatch(setFileToRename({ file: document }))
    }
  }

  /**
   * Opens the file for viewing.
   *
   * @param row - The file to be opened.
   */
  async function onFileOpen(row: FileInterface) {
    if (driveEncryptKey) {
      dispatch(
        setFileToView({
          file: {
            id: row.id,
            size: parseInt(row.size),
            mimeType: row.mimeType,
            name: row.name,
          } as ViewFile,
          encryptKey: driveEncryptKey,
        }),
      )
    }
  }

  const isSelected = selectedFilesFolders.some((item) => compareFilesFolders(item, file))

  return (
    <div
      className={`DrivesList-table-row ${
        isSelected ? 'DrivesList-table-row-selected' : ''
      } ${draggingOver && selectedFilesFolders.includes(file) ? 'file-dragging' : ''}`}
      draggable
      onDragStart={(e) => handleDragStart(e)}
      onDragEnd={handleDragEnd}
      onClick={(e) => handleRowClick(file, e)}
      onMouseDown={(e) => handleMouseDown(e)}
      onDoubleClick={() => onFileOpen(file)}
    >
      <div className="DrivesList-table-name-column">
        <div className="name">
          <span className="document-icon">
            <img className="file-icon" src={renderIcon(file.mimeType, file.name)} />
          </span>
          <span className="document-name">{file.name}</span>
        </div>
      </div>
      <div className="DrivesList-table-owner-column">
        {file.creatorFullName ? (
          <Tooltip title={file.creatorEmail}>
            <span>{file.creatorFullName}</span>
          </Tooltip>
        ) : (
          <span>{file.creatorEmail}</span>
        )}
      </div>
      <div className="DrivesList-table-date-column">
        {localFormatDate(dayjs(file.createdAt))}
      </div>
      <div className="DrivesList-table-size-column">
        {formatBytes(parseFloat(file.size))}
      </div>
      <div className="DrivesList-table-actions-column">
        <div className="action-main-container">
          <div className="action-custom-container">
            <div className="action-hover-container">
              <div className="action-icons">
                {rights?.fileRights ||
                  (creatorEmail === auth.email && (
                    <div
                      className="action-rounded-icon hideOnLaptop"
                      onClick={() => setRenameFile(file)}
                    >
                      <AiOutlineEdit size="1.5em" />
                    </div>
                  ))}
                <div className="action-rounded-icon" onClick={() => onFileOpen(file)}>
                  <AiOutlineEye size="1.5em" />
                </div>
                <div
                  className="action-rounded-icon"
                  onClick={(e) =>
                    driveEncryptKey && downloadEncryptedFile(file.id, driveEncryptKey)
                  }
                >
                  <BsDownload size="1.5em" />
                </div>
              </div>
            </div>
          </div>
          <Dropdown
            menu={{
              items:
                rights?.fileRights || creatorEmail === auth.email
                  ? fileItems
                  : fileItemsDefaultRights,
            }}
            trigger={['click']}
          >
            <a
              onClick={() => setSelectedFileInTable(file)}
              className="action-more-container"
            >
              <div className="action-more">
                <BiDotsVerticalRounded size="1.5em" />
              </div>
            </a>
          </Dropdown>

          <div className="action-more-mobile">
            <Button type="primary" onClick={() => onFileOpen(file)}>
              <AiOutlineEye size="1.5em" />
            </Button>
            {rights?.fileRights ||
              (creatorEmail === auth.email && (
                <Button onClick={() => setRenameFile(file)}>
                  <AiOutlineEdit size="1.5em" />
                </Button>
              ))}
            <Button
              onClick={() =>
                driveEncryptKey && downloadEncryptedFile(file.id, driveEncryptKey)
              }
            >
              <BsDownload size="1.5em" />
            </Button>
            {rights?.fileRights ||
              (creatorEmail === auth.email && (
                <Button className="btn-danger-border" onClick={() => onDeleteFile(file)}>
                  <IoTrashOutline size="1.2em" className="error-color" />
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileRow
