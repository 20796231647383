import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { MessageInputDraft, setMessageInputDraftFiles } from '../../components/ChatComponent/Chat/chatsSlice'
import { UploadFile } from 'antd'

interface UseChatDraftInterface {
  currentChatId: string, 
  onLoaded: (files: UploadFile<any>[] | undefined) => void
  filesInProgress: UploadFile<any>[] | undefined, 
  messageInputDraft: MessageInputDraft | undefined
}
export default function useChatDraft({onLoaded, filesInProgress, currentChatId, messageInputDraft}: UseChatDraftInterface) {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (filesInProgress?.every((file) => file.status === 'done') && filesInProgress !== messageInputDraft?.files) {
      dispatch(
        setMessageInputDraftFiles({
          chatId: currentChatId,
          files: filesInProgress, 
        }),
      )
    }
    else if(filesInProgress?.length === 0) {
      dispatch(
        setMessageInputDraftFiles({
          chatId: currentChatId, 
        }),
        )
    }
  }, [filesInProgress])

  useEffect(() => {
    onLoaded(messageInputDraft?.files)
  }, [messageInputDraft?.files,currentChatId])
}
