import React from 'react'
import { Col, Row } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import './room-general-information.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { Table, Tooltip } from 'antd'
import 'react-quill/dist/quill.snow.css'
import { IoMdPersonAdd } from 'react-icons/io'
import { setRoomToModify } from '../../Meetings/MeetingsSlice'
import ModalManageParticipants from '../../../components/RoomManage/ModalManageParticipants'
import sanitizeHtml from 'sanitize-html'

function RoomGeneralInformation() {
  const { t } = useTranslation('roomGeneralInformation')
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)
  const participantRoom = useSelector((state: RootState) => state.room)
  const creator = {
    email: participantRoom?.creator,
    role: t('Organizer', { ns: 'common' }),
    type: t('Creator', { ns: 'common' }),
  }
  const participants = participantRoom?.participants?.map((participant) => ({
    ...participant,
    role: t(participant.role),
    type: t('Your organization'),
  }))

  const invited = participantRoom?.invited?.map((invited) => ({
    ...invited,
    role: t(invited.role),
    type: t('Invited guest'),
  }))

  const dataSource = [creator, ...(participants || []), ...(invited || [])]

  const columns = [
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      className: 'email-column',
    },
    {
      title: t('Role'),
      dataIndex: 'role',
      key: 'role',
      className: 'role-column',
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      className: 'type-column',
    },
  ]
  const openManageModal = () => {
    dispatch(setRoomToModify(participantRoom))
  }

  return (
    <>
      <div className="general-information-note-container">
        <div className="note-label ">{t('Note from the organizer')}</div>
        <hr />
        <div
          className="note-container"
          dangerouslySetInnerHTML={{
            __html: participantRoom?.creatorNote
              ? sanitizeHtml(participantRoom?.creatorNote, {
                  allowedTags: [
                    'b',
                    'p',
                    'ol',
                    'ul',
                    'li',
                    'strong',
                    'em',
                    's',
                    'u',
                    'br',
                  ],
                })
              : t('No message from the organizer'),
          }}
        />
      </div>
      <div className="general-information-table mt-1rem">
        <Row>
          <Col span={23}>
            <div className="table-label">{t('Participants')}</div>
          </Col>
          {(participantRoom.creator ? participantRoom.creator.email !== email : false) ? (
            <Col span={1}></Col>
          ) : (
            <Col span={1}>
              <Tooltip title={t('Participants management')} className="table-button">
                <IoMdPersonAdd onClick={openManageModal} size={23} />
              </Tooltip>
            </Col>
          )}
          <ModalManageParticipants />
        </Row>
        <hr />
        <Table
          className="participants-table"
          dataSource={dataSource}
          columns={columns}
          pagination={
            dataSource.length >= 11 && {
              defaultPageSize: 10,
              position: ['bottomCenter'],
              showTotal: (total, range) => (
                <Trans
                  ns="common"
                  i18nKey="TABLE_PAGINATION"
                  values={{
                    range0: range[0],
                    range1: range[1],
                    total: total,
                  }}
                />
              ),
              showSizeChanger: true,
            }
          }
        />
      </div>
    </>
  )
}

export default RoomGeneralInformation
