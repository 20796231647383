import React, { CSSProperties, useEffect, useRef } from 'react'
import { Form, Menu, MenuProps } from 'antd'
import {
  setContextMenuVisible,
  setOpenCreateFolder,
} from '../../../features/Drives/redux/drivesSlice'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import { MdOutlineCreateNewFolder, MdOutlineUploadFile } from 'react-icons/md'
import DrivesFileUpload from '../DrivesHeader/DrivesFileUpload'

interface Props {
  coordinates: { x: number; y: number }
}

function DriveContextMenu({ coordinates }: Props) {
  const { t } = useTranslation('drives')
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const dispatch = useDispatch<AppDispatch>()
  const rights = useSelector((state: RootState) => state.drive.driveRights)
  const { creatorEmail } = useDriveLoader(selectedDriveFolder?.driveId)
  const auth = useSelector((state: RootState) => state.auth)
  const inputFile = useRef<HTMLInputElement>(null)
  const itemsRightClickMenu: MenuProps['items'] = []

  useEffect(() => {
    const handleClick = () => dispatch(setContextMenuVisible(false))
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  if (selectedDriveFolder) {
    if (rights?.folderRights || creatorEmail === auth.email) {
      itemsRightClickMenu.push(
        {
          key: 'create_folder',
          className: 'menu-create-drive',
          label: (
            <>
              <Form.Item className="mb-0">
                <label>{t('Create folder')}</label>
              </Form.Item>
            </>
          ),
          onClick: () => dispatch(setOpenCreateFolder(true)),
          icon: <MdOutlineCreateNewFolder size="1rem" />,
        },
        {
          type: 'divider',
        },
      )
    }

    if (rights?.fileRights || creatorEmail === auth.email) {
      itemsRightClickMenu.push({
        key: 'import_file',
        className: 'menu-create-drive',
        label: (
          <span onClick={(event) => event.stopPropagation()}>
            <DrivesFileUpload inputFile={inputFile} />
          </span>
        ),
        icon: <MdOutlineUploadFile size="1rem" />,
        onClick: () => inputFile.current?.click(),
      })
    }
  }

  const menuStyle: CSSProperties = {
    position: 'absolute',
    top: coordinates.y,
    left: coordinates.x,
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    padding: '5px',
    zIndex: 1000,
  }

  return (
    <>
      {itemsRightClickMenu.length > 0 && (
        <Menu items={itemsRightClickMenu} style={menuStyle} className="context-menu" />
      )}
    </>
  )
}

export default DriveContextMenu
