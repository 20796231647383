import React from 'react'
import { Dropdown, MenuProps, Spin, Table, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { PendingRoomInterface, RoomInterface, RoomType } from '../../room/RoomInterface'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import '../meetings.scss'
import './pending-meetings-table.scss'
import { AiFillCheckCircle, AiOutlineSchedule } from 'react-icons/ai'
import { IoTrashOutline } from 'react-icons/io5'
import { RoomToModify, RoomToModifyPending, setRoomToDelete } from '../MeetingsSlice'
import { TbClockQuestion } from 'react-icons/tb'
import classNames from 'classnames'
import { AiOutlineEdit } from 'react-icons/ai'

import { localFormatDate } from '../../../utils/Utils'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import humanizeDuration from 'humanize-duration'
import { SlPhone } from 'react-icons/sl'
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2'
import { RiComputerLine } from 'react-icons/ri'

interface Props {
  data: {
    rooms?: PendingRoomInterface[]
    fetchStatus: string
    fetchError?: string | undefined
  }
  pagination: {
    page: number
    setPendingMeetingPage: (page: number) => void
  }
  setMeetingVoteRoom: (room: PendingRoomInterface) => void
  setRoomModify: (room: RoomToModify | RoomToModifyPending) => void
}

export default function MeetingsTable(props: Props) {
  const { data, setMeetingVoteRoom, pagination } = props
  const { t, i18n } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)

  function deleteRoom(room: RoomInterface | PendingRoomInterface) {
    dispatch(setRoomToDelete({ room, pending: true }))
  }

  const dropdownMenu = (room: PendingRoomInterface) => {
    var menu: MenuProps['items'] = []
    menu = menu.concat([
      {
        key: 'preferences',
        label: (
          <div className="cursor-pointer link d-flex d-flex-middle">
            <AiOutlineSchedule size="1.1rem" className="default-text-color" />
            <div className="dropdown-icon-margin-left">
              {room.creator?.email === email
                ? t('View preferences')
                : t('My preferences')}
            </div>
          </div>
        ),
        onClick: () => setMeetingVoteRoom(room),
      },
    ])
    if (room.creator?.email === email) {
      menu = menu.concat([
        {
          key: 'edit',
          label: (
            <div className="cursor-pointer link d-flex d-flex-middle">
              <AiOutlineEdit size="1.1rem" className="default-text-color" />
              <div className="dropdown-icon-margin-left">{t('Modify meeting')}</div>
            </div>
          ),
          onClick: () => {
            props.setRoomModify({
              ...room,
              plannedDates:
                room.voteStatus?.dates.map((date) => new Date(date).getTime()) || [],
            })
          },
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: (
            <div className="cursor-pointer d-flex d-flex-middle">
              <IoTrashOutline size="1.1rem" className="error-color" />
              <div className="dropdown-icon-margin-left error-color">
                {t('Delete meeting')}
              </div>
            </div>
          ),
          onClick: () => deleteRoom(room),
        },
      ])
    }

    return menu
  }

  const columns: ColumnsType<PendingRoomInterface> = [
    {
      className: 'type-meeting-column',
      render: (room: RoomInterface) =>
        room.type === RoomType.Phone ? (
          <SlPhone size="1.4em" className="default-text-color" />
        ) : room.type === RoomType.InPerson ? (
          <HiOutlineBuildingOffice2 size="1.5em" className="default-text-color" />
        ) : (
          <RiComputerLine size="1.5em" className="default-text-color" />
        ),
    },
    {
      title: t('Status'),
      className: 'status-column',
      render: (room: PendingRoomInterface) =>
        room.creator?.email === email ? (
          <span
            className={classNames({
              'd-flex d-flex-center d-flex-middle g-0_1rem': true,
              'row-urgent': dayjs().diff(room.voteStatus?.dates[0], 'day', true) > -2,
              'row-fully-voted':
                room.voteStatus?.count ===
                (room.participants?.length ?? 0) + (room.invited?.length ?? 0),
            })}
          >
            {`${room.voteStatus?.count}/${
              (room.participants?.length || 0) + (room.invited?.length || 0)
            }`}
          </span>
        ) : room.voteStatus?.voted ? (
          <Tooltip title={t('Chosen')}>
            <span className="success-color d-flex d-flex-center">
              <AiFillCheckCircle size="1.2em" />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={t('Not chosen')}>
            <span className="warning-color d-flex d-flex-center">
              <TbClockQuestion size="1.2em" />
            </span>
          </Tooltip>
        ),
    },
    {
      title: t('Duration'),
      className: 'duration-column',
      render: (room: PendingRoomInterface) =>
        humanizeDuration(dayjs.duration({ minutes: room.duration }).asMilliseconds(), {
          language: i18n.language,
        }),
    },
    {
      title: t('Meeting'),
      dataIndex: 'name',
      className: 'name-column',
    },
    {
      title: t('Created by'),
      className: 'creator-column',
      render: (room: PendingRoomInterface) => (
        <Tooltip title={room.creator?.email}>
          {room.creator?.firstName} {room.creator?.lastName}
        </Tooltip>
      ),
    },
    {
      title: t('Creation date', { ns: 'common' }),
      className: 'create_dt-column',
      render: (room: PendingRoomInterface) =>
        room.createdAt
          ? localFormatDate(dayjs(room.createdAt))
          : t('Date is not set', { ns: 'common' }),
    },
    {
      title: t('Due date'),
      className: 'create_dt-column',
      render: (room: PendingRoomInterface) =>
        room.createdAt
          ? localFormatDate(dayjs(room.voteStatus?.dates[0]))
          : t('Date is not set', { ns: 'common' }),
    },
    {
      title: '',
      className: 'action-column',
      render: (room: PendingRoomInterface) => (
        <div className="action-main-container">
          <div className="action-custom-container">
            <div className="action-hover-container">
              <div className="action-icons">
                <div
                  className="action-rounded-icon"
                  onClick={() => setMeetingVoteRoom(room)}
                >
                  <Tooltip
                    title={
                      room.creator?.email === email
                        ? t('View preferences')
                        : t('My preferences')
                    }
                    className="cursor-pointer link"
                  >
                    <AiOutlineSchedule size="1.5rem" className="default-text-color" />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <Dropdown menu={{ items: dropdownMenu(room) }} trigger={['click']}>
            <div className="action-more-container">
              <div className="action-more">
                <BiDotsVerticalRounded size="1.5em" />
              </div>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ]

  if (data.fetchStatus === 'loading') return <Spin size="large" />
  else if (data.fetchStatus === 'success') {
    if (data.rooms && data.rooms.length > 0)
      return (
        <Table
          dataSource={data.rooms}
          rootClassName="pending-meetings-table"
          /* Keep current page in memory if navigate to vote page */
          pagination={
            data.rooms.length >= 11 && {
              current: pagination.page,
              onChange: (page) => {
                pagination.setPendingMeetingPage(page)
              },
              position: ['bottomCenter'],
              showTotal: (total, range) => (
                <Trans
                  ns="common"
                  i18nKey="TABLE_PAGINATION"
                  values={{
                    range0: range[0],
                    range1: range[1],
                    total: total,
                  }}
                />
              ),
              showSizeChanger: true,
            }
          }
          rowKey="id"
          columns={columns}
          showSorterTooltip={false}
        />
      )
    else return <p>{t('No meetings to validate yet')}</p>
  } else {
    return <></>
  }
}
