import dayjs from 'dayjs'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import './ChatMessages.scss'
import { Chat, ChatMessage } from './chatsSlice'
import { TiMessages } from 'react-icons/ti'
import Message from './Message'

interface Props {
  chat: Chat
  loading: boolean
  unreadMessagesCount: number | undefined
}

function ChatMessages({ chat, loading, unreadMessagesCount }: Props) {
  const { t } = useTranslation('chat')
  const chatMessages = useSelector(
    (state: RootState) => state.chats.chatsMessages[chat.id],
  )

  let messages: ChatMessage[] = []
  if (chatMessages) {
    messages = [...Object.values(chatMessages.chunks).flat(), ...chatMessages.live]
  }

  return (
    <div
      className="chat_messages"
      id="chat_messages_container" /* only for smooth scrolling to bottom */
    >
      {messages.length === 0 && !loading ? (
        <div className="empty-container">
          <TiMessages size="4em" />
          <h2 className="text-color-default">
            {t('Send a first message to this conversation')}
          </h2>
        </div>
      ) : (
        <DisplayMessages
          messages={messages}
          chat={chat}
          unreadMessagesCount={unreadMessagesCount}
        />
      )}
    </div>
  )
}

function DisplayMessages({
  messages,
  chat,
  unreadMessagesCount,
}: Omit<Props, 'loading'> & { messages: ChatMessage[] }) {
  const { t } = useTranslation('chat')
  const result: React.ReactNode[] = []

  const lastReadMessage = useRef<ChatMessage>()

  if (unreadMessagesCount && unreadMessagesCount > 0)
    lastReadMessage.current = messages[messages.length - unreadMessagesCount]

  let currentDate
  for (let index = 0; index < messages.length; index++) {
    const message = messages[index]
    const messageDate =
      dayjs().format('LL') === dayjs(message.createdAt).format('LL')
        ? t('Today', { ns: 'calendar' })
        : dayjs(message.createdAt).format('LL')

    if (message === lastReadMessage.current) {
      result.push(
        <div key="new_messages" id="new_messages" className="chat_message_new_separator">
          <div className="new_separator_text">{t('New messages')}</div>
          <div className="new_separator_line"></div>
        </div>,
      )
    }

    if (currentDate != messageDate) {
      currentDate = messageDate
      result.push(
        <div className="chat_message_date_separator" key={messageDate}>
          <div className="separator_line" />
          <div className="chat_message_date_separator_text">{messageDate}</div>
          <div className="separator_line" />
        </div>,
      )
    }

    result.push(<Message message={message} chat={chat} key={message.id} />)
  }

  //be careful of placing some extra content here, you might break scroll.
  return <>{result}</>
}

export default ChatMessages
