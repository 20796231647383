import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export const useDriveLoader = (selectedDriveFolderId: string | undefined) => {
  const creatorEmail = useSelector(
    (state: RootState) =>
    state.drive.drives?.find((drive) => drive.id === selectedDriveFolderId)?.creatorEmail
  )

  return {
    creatorEmail
  }
}
