import retry from "retry";
import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../store";
import { setConnected } from "./websocketSlice";
import { Chat, ChatMessage } from "../Chat/chatsSlice";
import { Squad } from "../Squad/squadListSlice";

export interface ChatEventPayloadUser {
  alias: string
  avatar: string
}

interface Message {
  me: string
  type: MessageType
}

interface SquadMessage extends Message {
  squad: Squad
  peersOnline: string[]
}

interface SquadNotExistMessage extends Message {
  squadId: string
}

interface ChatListMessage extends Message {
  squadId: string
  chatList: Chat[]
}

interface ChatDetailsMessage extends Message {
  chatId: string
  lastMessage: ChatMessage
  unreadMessagesCount: number
}

interface ChatDetailsListMessage extends Message {
  squadId: string
  details: {
    chatId: string
    lastMessage: ChatMessage
    unreadMessagesCount: number
  }[]
}

interface ChatCreatedMessage extends Message {
  chat: Chat
}

interface ChatUpdatedMessage extends Message {
  chatId: string
  title: string
}

interface ChatArchivedMessage extends Message {
  chatId: string
}

interface ChatUnArchivedMessage extends Message {
  chat: Chat
}

interface ChatMessagesMessage extends Message {
  squadId: string
  chatId: string
  messages: ChatMessage[]
  chunk: number
  newestChunk: number
}

interface ChatMessageReceivedMessage extends Message {
  squadId: string
  chatId: string
  message: ChatMessage
}


interface ChatMessageDeletedMessage extends Message {
  squadId: string
  chatId: string
  messageId: string
  deleted: boolean
}

interface ChatMessageEditedMessage extends Message {
  squadId: string
  chatId: string
  messageId: string
  message: any
} 

interface ChatUserAddedMessage extends Message {
  squadId: string
  chat: Chat
}

interface ChatUserRemovedMessage extends Message {
  squadId: string
  chatId: string
}

interface ChatUsersUpdatedMessage extends Message {
  squadId: string
  chat: Chat
}

interface CreatorFocusMessage extends Message {
  chat: Chat
  creator: boolean
}

type PeerStatus = "ONLINE" | "OFFLINE"

interface PeerStatusMessage extends Message {
  peer: string
  squadId: string
  status: PeerStatus
}


enum MessageType {
  SQUAD = "SQUAD",
  SQUAD_NOT_EXIST = "SQUAD_NOT_EXIST",
  CHAT_LIST = "CHAT_LIST",
  CHAT_DETAILS = "CHAT_DETAILS",
  CHAT_DETAILS_LIST = "CHAT_DETAILS_LIST",
  CHAT_CREATED = "CHAT_CREATED",
  CHAT_UPDATED = "CHAT_UPDATED",
  CHAT_ARCHIVED = "CHAT_ARCHIVED",
  CHAT_UNARCHIVED = "CHAT_UNARCHIVED",
  CHAT_MESSAGES = "CHAT_MESSAGES",
  CHAT_MESSAGE_RECEIVED = "CHAT_MESSAGE_RECEIVED",
  CHAT_USER_ADDED = "CHAT_USER_ADDED",
  CHAT_USER_REMOVED = "CHAT_USER_REMOVED",
  CHAT_USERS_UPDATED = "CHAT_USERS_UPDATED",
  PEER_STATUS = "PEER_STATUS",
  HEARTBEAT = "HEARTBEAT",
  CHAT_MESSAGE_DELETED = "CHAT_MESSAGE_DELETED",
  CHAT_MESSAGE_EDITED = "CHAT_MESSAGE_EDITED",
  CREATOR_FOCUS = "CREATOR_FOCUS",
}

enum ChatActions {
  GET_SQUAD = "GET_SQUAD",
  CREATE_CHAT = "CREATE_CHAT",
  LIST_CHATS = "LIST_CHATS",
  FETCH_CHAT_MESSAGES = "FETCH_CHAT_MESSAGES",
  FETCH_CHAT_MESSAGES_UNTIL = "FETCH_CHAT_MESSAGES_UNTIL",
  MARK_CHAT_MESSAGES_SEEN = "MARK_CHAT_MESSAGES_SEEN",
  CREATE_CHAT_MESSAGE = "CREATE_CHAT_MESSAGE",
  MARK_CHAT_MESSAGE_DELETED="MARK_CHAT_MESSAGE_DELETED",
  EDIT_CHAT_MESSAGE = "EDIT_CHAT_MESSAGE",
  ARCHIVE_CHAT = "ARCHIVE_CHAT",
  UNARCHIVE_CHAT = "UNARCHIVE_CHAT",
  UPDATE_CHAT_TITLE = "UPDATE_CHAT_TITLE",
  UPDATE_CHAT_PEERS = "UPDATE_CHAT_PEERS",
}

export const websocketSquadAction = createAction<SquadMessage>('websocket-connection/squad')
export const websocketSquadNotExistAction = createAction<SquadNotExistMessage>('websocket-connection/squad-not-exist')
export const websocketChatListAction = createAction<ChatListMessage>('websocket-connection/chat-list')
export const websocketChatDetailsAction = createAction<ChatDetailsMessage>('websocket-connection/chat-details')
export const websocketChatDetailsListAction = createAction<ChatDetailsListMessage>('websocket-connection/chat-details-list')
export const websocketChatCreatedAction = createAction<ChatCreatedMessage>('websocket-connection/chat-created')
export const websocketChatUpdatedAction = createAction<ChatUpdatedMessage>('websocket-connection/chat-updated')
export const websocketChatArchivedAction = createAction<ChatArchivedMessage>('websocket-connection/chat-archived')
export const websocketChatUnarchivedAction = createAction<ChatUnArchivedMessage>('websocket-connection/chat-unarchived')
export const websocketChatMessagesAction = createAction<ChatMessagesMessage>('websocket-connection/chat-messages')
export const websocketChatMessageReceivedAction = createAction<ChatMessageReceivedMessage>('websocket-connection/chat-message-received')
export const websocketChatMessageDeletedAction = createAction<ChatMessageDeletedMessage>('websocket-connection/chat-message-deleted')
export const websocketChatMessageEditedAction = createAction<ChatMessageEditedMessage>('websocket-connection/chat-message-edited')
export const websocketChatUserAddedAction = createAction<ChatUserAddedMessage>('websocket-connection/chat-user-added')
export const websocketChatUserRemovedAction = createAction<ChatUserRemovedMessage>('websocket-connection/chat-user-removed')
export const websocketChatUsersUpdatedAction = createAction<ChatUsersUpdatedMessage>('websocket-connection/chat-users-updated')
export const websocketPeerStatusAction = createAction<PeerStatusMessage>('websocket-connection/peer-status')
export const websocketCreatorFocusAction = createAction<CreatorFocusMessage>('websocket-connection/CreatorFocus')

export class WebsocketConnection {
  private _dispatch: AppDispatch
  private _ws?: WebSocket
  private _jwt: string
  private _me: string
  private _squadId: string
  private disconnectTimeout: ReturnType<typeof setTimeout> | undefined
  private _closed: boolean

  constructor(dispatch: AppDispatch, jwt: string, me: string, squadId: string) {
    console.debug('WebsocketConnection::constructor')
    this._dispatch = dispatch
    this._jwt = jwt
    this._me = me
    this._squadId = squadId
    this._closed = false
  }

  public connect() {
    const operation = retry.operation({
      forever: true,
      factor: 1.5,
      minTimeout: 1000,
      maxTimeout: 5000,
      randomize: true,
    })

    operation.attempt((currentAttempt) => {
      if (this._closed) {
        console.log('this._closed')
        operation.stop()
        return
      }

      this.disconnect(false)
      this._ws = new WebSocket(`${process.env.REACT_APP_CHAT_BASE_WS_URL}/chat?token=${this._jwt}&squad=${this._squadId}`)

      this._ws.onmessage = (msg) => {
        try {
          const data = JSON.parse(msg.data)
          data['me'] = this._me

          switch (data.type) {
            case MessageType.SQUAD:
              this._dispatch(websocketSquadAction(data))
              break

            case MessageType.SQUAD_NOT_EXIST:
              this._dispatch(websocketSquadNotExistAction(data))
              break

            case MessageType.CHAT_LIST:
              this._dispatch(websocketChatListAction(data))
              break

            case MessageType.CHAT_DETAILS:
              this._dispatch(websocketChatDetailsAction(data))
              break

            case MessageType.CHAT_DETAILS_LIST:
              this._dispatch(websocketChatDetailsListAction(data))
              break

            case MessageType.CHAT_CREATED:
              this._dispatch(websocketChatCreatedAction(data))
              break

            case MessageType.CHAT_UPDATED:
              this._dispatch(websocketChatUpdatedAction(data))
              break

            case MessageType.CHAT_ARCHIVED:
              this._dispatch(websocketChatArchivedAction(data))
              break
            
            case MessageType.CHAT_UNARCHIVED:
              this._dispatch(websocketChatUnarchivedAction(data))
              break

            case MessageType.CHAT_MESSAGES:
              this._dispatch(websocketChatMessagesAction(data))
              break

            case MessageType.CHAT_MESSAGE_RECEIVED:
              this._dispatch(websocketChatMessageReceivedAction(data))
              break

            case MessageType.CHAT_MESSAGE_DELETED:
              this._dispatch(websocketChatMessageDeletedAction(data))
              break
            
            case MessageType.CHAT_MESSAGE_EDITED:
              this._dispatch(websocketChatMessageEditedAction(data))
              break

            case MessageType.CHAT_USER_ADDED:
              this._dispatch(websocketChatUserAddedAction(data))
              break

            case MessageType.CHAT_USER_REMOVED:
              this._dispatch(websocketChatUserRemovedAction(data))
              break

            case MessageType.CHAT_USERS_UPDATED:
              this._dispatch(websocketChatUsersUpdatedAction(data))
              break

            case MessageType.PEER_STATUS:
              this._dispatch(websocketPeerStatusAction(data))
              break

            case MessageType.CREATOR_FOCUS:
              this._dispatch(websocketCreatorFocusAction(data))
              break

            case MessageType.HEARTBEAT:
              this.updateDisconnectTimeout()
              break

            default:
              throw new Error("Unknown message type: " + msg.data)
          }
        } catch(e) {
          console.error(e)
        }
      }

      this._ws.onopen = (e) => {
        console.debug('WebsocketConnection::onopen', e)
        this._dispatch(setConnected(true))
        this.updateDisconnectTimeout()
      }

      this._ws.onclose = (e) => {
        console.error('WebsocketConnection::onclose', e)
        this._dispatch(setConnected(false))

        operation.retry(new Error('Closed'))
      }

      this._ws.onerror = (e) => {
        console.error('WebsocketConnection::onerror', e)
        // operation.retry(new Error("Error"))
      }
    })
  }

  private updateDisconnectTimeout() {
    clearTimeout(this.disconnectTimeout)

    this.disconnectTimeout = setTimeout(() => {
      this.connect()
    }, 5000)
  }


  public disconnect(definetly: boolean = true) {
    console.debug('WebsocketConnection::disconnect')
    if (definetly) {
      this._closed = true
    }

    if (this._ws) {
      console.debug('WebsocketConnection::disconnect this._ws')
      this._ws.onopen = null
      this._ws.onclose = null
      this._ws.onerror = null
      this._ws.onmessage = null
      this._ws.close()
      this._ws = undefined
      this._dispatch(setConnected(false))
    }
  }

  public sendGetSquad(squadId: string) {
    const data = JSON.stringify({
      type: ChatActions.GET_SQUAD,
      payload: { squadId }
    })

    this.send(data)
  }

  public sendCreateChat(
    title: string, 
    squadId?: string, 
    users?: string[],
    groups?: string[]
  ) {
    const data = JSON.stringify({
      type: ChatActions.CREATE_CHAT,
      payload: {
        title,
        squadId,
        users: JSON.stringify(users),
        groups: JSON.stringify(groups)
      }
    })
    this.send(data)
  }

  public sendListChats(squadId?: string) {
    const data = JSON.stringify({
      type: ChatActions.LIST_CHATS,
      payload: { squadId }
    })

    this.send(data)
  }

  public sendFetchChatMessages(squadId: string, chatId: string, chunk?: number) {
    const data = JSON.stringify({
      type: ChatActions.FETCH_CHAT_MESSAGES,
      payload: { squadId, chatId, chunk },
    })

    this.send(data)
  }

  public sendFetchMessagesUntil(squadId: string, chatId: string, targetMessageId: string, oldestLoadedChunk: number) {
    const data = JSON.stringify({
      type: ChatActions.FETCH_CHAT_MESSAGES_UNTIL,
      payload: { squadId, chatId, targetMessageId, oldestLoadedChunk},
    })

    this.send(data)
  }

  public sendMarkChatMessagesSeen(squadId: string, chatId: string) {
    const data = JSON.stringify({
      type: ChatActions.MARK_CHAT_MESSAGES_SEEN,
      payload: { squadId, chatId },
    })

    this.send(data)
  }

  public sendChatMessage(
    squadId: string, 
    chatId: string, 
    text?: string,
    files?: string,
    repliedTo?: string
  ) {
    const data = JSON.stringify({
      type: ChatActions.CREATE_CHAT_MESSAGE,
      payload: { squadId, chatId, text, files, repliedTo },
    })

    this.send(data)
  }

  public markChatMessageDeleted(
    squadId: string, 
    chatId: string,
    messageId: string,
    deleted: boolean
  ) {
    const data = JSON.stringify({
      type: ChatActions.MARK_CHAT_MESSAGE_DELETED,
      payload: { squadId, chatId, messageId, deleted },
    })

    this.send(data)
  } 

  public editChatMessage(
    squadId: string, 
    chatId: string,
    messageId: string,
    createdAt: string,
    text?: string,
    files?: string,
    repliedTo?: string
  ) {
    //TODO: check if !text && !files before calling this method => throw an error. msg mustn't be empty
    const data = JSON.stringify({
      type: ChatActions.EDIT_CHAT_MESSAGE,
      payload: { squadId, chatId, messageId, createdAt, text, files, repliedTo },
    })

    this.send(data)
  }

  public sendArchiveChat(chatId: string) {
    const data = JSON.stringify({
      type: ChatActions.ARCHIVE_CHAT,
      payload: {
        chatId,
      },
    })

    this.send(data)
  }

  public sendUpdateChatTitle(chatId: string, title: string) {
    const data = JSON.stringify({
      type: ChatActions.UPDATE_CHAT_TITLE,
      payload: {
        chatId,
        title,
      },
    })

    this.send(data)
  }

  public sendUpdateChatPeers(chatId: string, users: string[], groups: string[]) {
    const data = JSON.stringify({
      type: ChatActions.UPDATE_CHAT_PEERS,
      payload: {
        chatId,
        users: JSON.stringify(users),
        groups: JSON.stringify(groups),
      },
    })

    this.send(data)
  }

  private send(data: string) {
    try {
      this._ws?.send(data)
    } catch(e) {
      console.error(e, data)
    }
  }
}
