import { createSlice } from "@reduxjs/toolkit"
import { ProducerModel, ScoreModel } from "./model"
import { setRoomState } from "./roomSlice"


interface Producers {
  [id: string]: ProducerModel,
}

interface State {
  producers: Producers
}

export const initialState:State = {
  producers: {},
}

const producersSlice = createSlice({
  initialState,
  name: 'producers',
  reducers: {
    addProducer: (state, action: { payload: ProducerModel }) => {
      const producer = action.payload
      state.producers[producer.id] = producer
    },

    removeProducer: (state, action) => {
      delete state.producers[action.payload]
    },

    setProducerPaused: (state, action) => {
      const producer = state.producers[action.payload]
      if (producer) {
        producer.paused = true
      }
    },

    setProducerResumed: (state, action) => {
      const producer = state.producers[action.payload]
      if (producer) {
        producer.paused = false
      }
    },

    setProducerTrack: (state, action: { payload: { producerId: string, track: MediaStreamTrack } }) => {
      const producer = state.producers[action.payload.producerId]
      if (producer) {
        producer.track = action.payload.track
      }
    },

    setProducerScore: (state, action: { payload: { producerId: string, score: ScoreModel } }) => {
      const producer = state.producers[action.payload.producerId]
      if (producer) {
        producer.score = action.payload.score
      }
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(setRoomState, (state, action: { payload: string }) => {
      if (action.payload === 'closed') {
        return initialState
      }
    })
  },
})


export default producersSlice.reducer

export const {
  addProducer,
  removeProducer,
  setProducerPaused,
  setProducerResumed,
  setProducerTrack,
  setProducerScore,
} = producersSlice.actions
