
class ApiError extends Error {
  constructor(message: string) {
    super(message || "unknown_error")
    this.name = "ApiError"
  }
}

class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message || "unknown_error")
    this.name = "UnauthorizedError"
  }
}

/**
 * if user unauthorised (has been logged in from another browser/device)
 * 
 * -> 
 * 
 * show notification
 * @param response - from server
 */
function assertSessionValid(response: Response) {
  if (response.status === 403) {
    throw new UnauthorizedError('Unauthorized')
    // don't need to call `store.dispatch(logout())` cause we handle Unauthorized error in store.ts
  }
}

export default async function customFetch(url: RequestInfo, options?: RequestInit, /* handledReasons?: string[] */){
    const response = await fetch(url, options)
    
    if (!response.ok) {
      assertSessionValid(response)
    
      let reason
      try {
          const responseBody = await response.json()
          reason = responseBody.reason || responseBody.message
      } catch (e) { }
      throw new ApiError(reason || "unknown_error")
  }

  return response
}

/* 
   *** Another version that overrides builtin fetch function ***
        Put code in comment bellow in index.tsx or App.tsx:

//////////////////////////////////////////////////
    import { globalFetch } from './customFetch'

    declare global {
    interface Window {
        oldFetch : typeof fetch
        fetch: typeof globalFetch
    }
    }
    window.oldFetch = window.fetch
    window.fetch = globalFetch    declare global {
    interface Window {
        oldFetch : typeof fetch
        fetch: typeof globalFetch
    }
    }
    window.oldFetch = window.fetch
    window.fetch = globalFetch
//////////////////////////////////////////////////

import { logout } from './features/auth/redux/authSlice'
import { store } from './store'
import i18n from './i18n'

export function assertSessionValid(response: Response) {
    if(response.statusText === "Unauthorized"){
        dispatch(ToastOpen(
          {            
            message: i18n.t('error.unauthorized'),
            duration: 30,
            type: "warning",
          }
        )
        store.dispatch(logout())
    }
}

export async function customFetch(url: RequestInfo, options?: RequestInit): Promise<Response>{
    const response = await window.oldFetch(url, options)
    assertSessionValid(response)
    return response
}

export const globalFetch: typeof fetch = async (url, options) => customFetch(url as RequestInfo, options) 

*/