import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { setCurrentChatId } from '../../components/ChatComponent/Chat/chatsSlice'
import useWebsocketConnection from '../../components/ChatComponent/WebsocketConnection/useWebsocketConnection'

type ChatStatus = "ACTIVE" | "LOADING" | "MISSING_SQUAD"

export default function useChatInitialization(
  { currentSquadId, jwt, email, selectDefaultChatOnLoad }:
  { currentSquadId: string, jwt: string, email: string, selectDefaultChatOnLoad: boolean }
) {
  const dispatch = useDispatch<AppDispatch>()
  const wsRef = useWebsocketConnection(jwt, email, currentSquadId)
  const currentChatId = useSelector((state: RootState) => state.chats.currentChat?.id)
  const chats = useSelector((state: RootState) => state.chats.chats)
  const squad = useSelector((state: RootState) => state.chatSquads.squads[currentSquadId])
  const missingSquads = useSelector((state: RootState) => state.chatSquads.missingSquads)
  
  let chatStatus: ChatStatus
  if (squad) {
    chatStatus = "ACTIVE"
  } else if (missingSquads.includes(currentSquadId)) {
    chatStatus = "MISSING_SQUAD"
  } else {
    chatStatus = "LOADING"
  }

  useEffect(() => {
    return () => {
      dispatch(setCurrentChatId(undefined))
    }
  }, [])

  useEffect(() => {
    if (selectDefaultChatOnLoad) {
      const allSquadChats = chats[currentSquadId]
      if (!currentChatId && allSquadChats) {
        for (const chat of allSquadChats) {
          if (chat.everybody) {
            dispatch(setCurrentChatId(chat.id))
            break
          }
        }
      }
    }
  }, [currentChatId, chats, selectDefaultChatOnLoad])

  return { wsRef, squad, currentChatId, chats, chatStatus }
}
