import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from "jwt-decode"
import { clearInvite, connectInvited } from '../../invite/redux/inviteSlice'
import { setOrganisation } from '../../Organisation/redux/organisationSlice'
import customFetch from '../../../customFetch'

class ApiError extends Error {
  constructor(message: string) {
    super(message || "unknown_error")
    this.name = "ApiError"
  }
}

export interface UserGroup {
  id: string,
  name: string,
}

interface User {
  email?: string
  sessionExpiresAt?: number
  groups?: UserGroup[]
  firstName?: string
  lastName?: string
  organizationAdmin?: boolean
  organizationName?: string
  tel?: string
  lang?: string
}


interface State extends User {
  jwt?: string
  attemptAuthStatus: string
  attemptSignupStatus: string
  attemptReinitPasswordStatus: string
  hasBeenDisconnected: boolean
}


export const initialState: State = {
  attemptAuthStatus: "idle",
  attemptSignupStatus: "idle",
  attemptReinitPasswordStatus: "idle",
  hasBeenDisconnected: false
}

/**
 * Auth user with email and password.
 */
export const attemptAuth = createAsyncThunk(
  'auth/attemptAuth',
  async (payload: { email: string, password: string, lang: string }, { dispatch }) => {
    dispatch(clearInvite())
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const message = (await response.json()).message || "unknown_error"
      throw new ApiError(message)
    }

    try {
      const body = await response.json()
      const payload:any = jwtDecode(body.token)
      dispatch(setJWT(body.token))
      dispatch(setOrganisation({
        id: payload['organizationId'],
        name: payload['organizationName'],
      }))
    } catch(e) {
      console.error(e)
      throw e
    }
  }
)


/**
 * Auth user with Secure & Access token.
 */
export const attemptSafeaccessAuth = createAsyncThunk(
  'auth/attemptSafeaccessAuth',
  async (payload: { token: string }, { dispatch }) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/auth/login/securenaccess`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    try {
      const body = await response.json()
      const payload:any = jwtDecode(body.token)
      dispatch(setJWT(body.token))
      dispatch(setOrganisation({
        id: payload['organizationId'],
        name: payload['organizationName'],
      }))
    } catch(e) {
      console.error(e)
      throw e
    }
  }
)


/**
 * Sign up user.
 */
export const attemptSignup = createAsyncThunk(
  'auth/attemptSignup',
  async (payload: { email: string, firstName: String, lastName: string, tel: string, lang: string, password: string }) => {
    const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/participant/signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    return await response.json()
  }
)


/**
 * Reinit password.
 */
export const attemptReinitPassword = createAsyncThunk(
  'auth/attemptReinitPassword',
  async ({ email }: { email: string }) => {
    await fetch(process.env.REACT_APP_BASE_USERS_URL + '/reset-password/demand', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
  }
)


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJWT: (state, { payload }: PayloadAction<string>) => {
      state.jwt = payload
      const user:any = jwtDecode(payload)
      state.email = user.sub
      state.sessionExpiresAt = user.exp * 1000
      state.firstName = user.firstName
      state.lastName = user.lastName
      state.tel = user.tel
      state.organizationAdmin = user.organizationAdmin
      state.organizationName = user.organizationName
      state.lang = user.lang
      state.groups = user.groups
    },
    idleAttemptAuthStatus: (state) => {
      state.attemptAuthStatus = "idle"
    },
    idleAttemptSignupStatus: (state) => {
      state.attemptSignupStatus = "idle"
    },
    idleAttemptReinitPasswordStatus: (state) => {
      state.attemptReinitPasswordStatus = "idle"
    },
    logout: () => {
      // Handled in store.ts in rootReducer
    },
  },
  extraReducers: {
    [attemptAuth.pending.type]: (state) => {
      state.attemptAuthStatus = "loading"
    },
    [attemptAuth.fulfilled.type]: (state, action) => {
      state.attemptAuthStatus = "success"
    },
    [attemptAuth.rejected.type]: (state, action) => {
      const error = action.error
      state.jwt = undefined
      state.email = undefined
      state.groups = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
      state.attemptAuthStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptSafeaccessAuth.pending.type]: (state) => {
      state.attemptAuthStatus = "loading"
    },
    [attemptSafeaccessAuth.fulfilled.type]: (state, action) => {
      state.attemptAuthStatus = "success"
    },
    [attemptSafeaccessAuth.rejected.type]: (state, action) => {
      const error = action.error
      state.jwt = undefined
      state.email = undefined
      state.groups = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
      state.attemptAuthStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptSignup.pending.type]: (state) => {
      state.attemptSignupStatus = "loading"
    },
    [attemptSignup.fulfilled.type]: (state, action) => {
      state.attemptSignupStatus = "success"
      state.jwt = action.payload.token
      const user: User = jwtDecode(action.payload.token)
      state.email = user.email
      state.firstName = user.firstName
      state.lastName = user.lastName
      state.tel = user.tel
      state.lang = user.lang
    },
    [attemptSignup.rejected.type]: (state, action) => {
      const error = action.error
      state.jwt = undefined
      state.email = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
      state.attemptSignupStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptReinitPassword.pending.type]: (state, action) => {
      state.attemptReinitPasswordStatus = "loading"
    },
    [attemptReinitPassword.fulfilled.type]: (state, action) => {
      state.attemptReinitPasswordStatus = "success"
    },
    [attemptReinitPassword.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptReinitPasswordStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [connectInvited.pending.type]: (state) => {
      state.jwt = undefined
      state.email = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.tel = undefined
      state.lang = undefined
    }
  }
})

export const {
  setJWT,
  idleAttemptAuthStatus,
  idleAttemptSignupStatus,
  idleAttemptReinitPasswordStatus,
  logout,
} = authSlice.actions

export default authSlice.reducer
