import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { useTranslation } from 'react-i18next'
import './drives.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { fetchDrives, idleDrive, setCreateDrive } from './redux/drivesSlice'
import DrivesList from '../../components/DrivesComponent/DrivesList/DrivesList'
import DrivesMenu from '../../components/DrivesComponent/DrivesMenu/DrivesMenu'
import DrivesHeader from '../../components/DrivesComponent/DrivesHeader/DrivesHeader'
import { Button, Drawer, Spin } from 'antd'
import { MdOutlineFolderOff } from 'react-icons/md'
import { UploadFile } from 'antd/lib'
import DriveProvider from './DrivesContext'
import { LuUnplug } from 'react-icons/lu'

function Drives() {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('drives')
  const drives = useSelector((state: RootState) => state.drive.drives)
  const fetchDrivesStatus = useSelector(
    (state: RootState) => state.drive.fetchDrivesStatus,
  )
  const [openDrawerMenu, setOpenDrawerMenu] = useState(false)

  useEffect(() => {
    dispatch(fetchDrives({}))
    return () => {
      dispatch(idleDrive())
    }
  }, [])

  const renderDriveMenu = () => (
    <div className="Drives-main-container">
      <DrivesMenu drivesFolders={drives?.map((drive) => drive.rootFolder) || []} />
    </div>
  )

  const renderDriveMenuDrawer = () => (
    <Drawer
      title="Drive"
      open={openDrawerMenu}
      placement="left"
      className="right-side-drawer"
      onClose={() => setOpenDrawerMenu(false)}
    >
      <DrivesMenu drivesFolders={drives?.map((drive) => drive.rootFolder) || []} />
    </Drawer>
  )

  const renderDriveContent = () => {
    if (fetchDrivesStatus === 'loading') {
      return <Spin size="large" className="d-flex d-flex-center d-flex-middle" />
    }

    if (fetchDrivesStatus === 'FETCH_ERROR') {
      return (
        <div className="Drives-empty-container">
          <LuUnplug size="2.5em" />
          <div className="text-center">
            <h3>{t('The service is currently unavailable.')}</h3>
            <h4>{t('Please try again later or contact your administrator.')}</h4>
          </div>
        </div>
      )
    }

    if (!drives || drives.length === 0) {
      return (
        <div className="Drives-empty-container">
          <MdOutlineFolderOff size="2.5em" />
          <h3>{t('You currently have no drives. Please click here to create one:')}</h3>
          <Button type="primary" onClick={() => dispatch(setCreateDrive(true))}>
            {t('Create drive')}
          </Button>
        </div>
      )
    }

    return (
      <div className="Drives-main-container">
        <DrivesHeader
          openDrawerMenu={openDrawerMenu}
          setOpenDrawerMenu={setOpenDrawerMenu}
        />
        <DrivesList />
        {renderDriveMenuDrawer()}
      </div>
    )
  }

  return (
    <div className="Drives">
      <Layout title="Drive" menu={renderDriveMenu()} content={renderDriveContent()} />
    </div>
  )
}

const DriveProviderWrapper = () => (
  <DriveProvider>
    <Drives />
  </DriveProvider>
)

export default DriveProviderWrapper
