import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../store"
import ApiError from "../../../utils/errors/ApiError"
import { FetchStatus } from "../../../utils/FetchStatus"
import { clearInvite } from "../../invite/redux/inviteSlice"
import { RoomInterface, RoomParticipant } from "../RoomInterface"
import { RoomParticipantRole } from "../RoomInterface"
import { addConsumer, removeConsumer } from "./consumersSlice"
import { addDataConsumer, removeDataConsumer } from "./dataConsumersSlice"
import { ConsumerModel, DataConsumerModel, PeerModel } from "./model"

import customFetch from "../../../customFetch"
import { buildChatId, DEFAULT_CHATS, getAllParticipants, sortByLatestMessageTimestamp } from "../RoomChat/ChatLibrary"


type RoomState = 'new' | 'connecting' | 'connected' | 'joined' | 'closed'
type AllowedToJoinState = "unknown" | "allowed" | "prohibited"


interface MediaDeviceLabel {
  value: string
  label: string
}
interface Peers {
  [id: string]: PeerModel,
}
export interface ChatInterface {
  messages: ChatMessage[],
  unread: number,
  icon: string,
  name: string,
  id: string,
}

export interface ChatMessage {
  id: string,
  authorId: string,
  authorName: string,
  timestamp: number,
  text: string,
}

interface State extends RoomInterface {
  fetchAccessTokenStatus: FetchStatus
  fetchAccessTokenError?: string
  accessToken?   : string
  state          : RoomState
  statsPeerId    : any
  webcam?        : string
  webcams        : MediaDeviceLabel[]
  mic?           : string
  mics           : MediaDeviceLabel[]
  speakerId?     : string
  speakers       : MediaDeviceLabel[]
  waitingInvited : RoomParticipant[]
  networkTroubleUsers: string[]
  allowed?       : AllowedToJoinState
  showInfo       : boolean
  chats          : ChatInterface[],
  joinedTimeStamp?: number | undefined,
  peers: Peers,
  activeSpeakerId: string | null
  promotedFullScreen: boolean
  disconnectDueToMultipleConnections: boolean
}

export const initialState:State = {
  fetchAccessTokenStatus: "idle",
  state          : 'new',
  activeSpeakerId: null,
  statsPeerId    : null,
  participants   : [],
  webcams        : [],
  mics           : [],
  speakers       : [],
  waitingInvited : [],
  networkTroubleUsers: [],
  showInfo       : false,
  chats          : [],
  peers: {  },
  joinedTimeStamp: undefined,
  promotedFullScreen:false,
  disconnectDueToMultipleConnections: false,
}

/**
 * Fetch access token.
 */
export const fetchAccessToken = createAsyncThunk(
  'room/fetchAccessToken',
  async ({ roomId }: { roomId: String }, { getState, dispatch }) => {
    const { auth, invite } = getState() as RootState

    let response

    if (invite.jwt) {
      response = await customFetch(`${process.env.REACT_APP_MEDIA_BASE_URL}/meeting/access-token/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${invite.jwt}`,
        },
        body: JSON.stringify({ roomId })
      })
    }
    
    if (auth.jwt && (!response || !response.ok)) {
      dispatch(clearInvite())

      response = await customFetch(`${process.env.REACT_APP_MEDIA_BASE_URL}/meeting/access-token/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.jwt}`,
        },
        body: JSON.stringify({ roomId })
      })
    }

    if (!response) {
      throw new Error("Could not access token")
    }

    const body = await response.json()
    if (!response.ok) {
      const reason = body.reason || "unknown_error"
      throw new ApiError(reason)
    }

    return body.token
  }
)



/**
 * Add participant.
 */
export const attemptAddParticipant = createAsyncThunk(
  'room/attemptAddParticipant',
  async ({ email, role, color }: { email: String, role: RoomParticipantRole, color: String }, { getState }) => {
    const { auth, room } = getState() as RootState
    if (!room.id) {
      return
    }

    const response = await customFetch(`${process.env.REACT_APP_MEDIA_BASE_URL}/room/participant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify({ meetingId: room.id, email, role, color })
    })

    if (!response.ok) {
      throw new Error("Could not fetch rooms")
    }
  }
)

export const attemptUpdateParticipant = createAsyncThunk(
  'room/attemptUpdateParticipant',
  async ({ email, newRole }: { email: String, newRole: string }, { getState }) => {
    const { auth, room } = getState() as RootState
    if (!room.id) {
      return
    }

    const response = await customFetch(`${process.env.REACT_APP_MEDIA_BASE_URL}/room/update-participant-role`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify({ meetingId: room.id, email, newRole })
    })

    if (!response.ok) {
      throw new Error("Could not fetch participant role update")
    }
  }
)

export const attemptUpdateInvited = createAsyncThunk(
  'room/attemptUpdateInvited',
  async ({ email, newRole }: { email: String, newRole: string }, { getState }) => {
    const { auth, room } = getState() as RootState
    if (!room.id) {
      return
    }

    const response = await customFetch(`${process.env.REACT_APP_MEDIA_BASE_URL}/room/update-invited-role`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify({ meetingId: room.id, email, newRole })
    })

    if (!response.ok) {
      throw new Error("Could not fetch invited role update")
    }
  }
)

/**
 * Delete peer.
 */
 export const attemptDeleteParticipant = createAsyncThunk(
  'room/attemptDeleteParticipant',
  async (email: String, { getState }) => {
    const { auth, room } = getState() as RootState
    if (!room.id) {
      return
    }

    const response = await customFetch(`${process.env.REACT_APP_MEDIA_BASE_URL}/room/participant`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify({ meetingId: room.id, email })
    })

    if (!response.ok) {
      throw new Error("Could not fetch rooms")
    }
  }
)

const roomSlice = createSlice({
  initialState,
  name: 'room',
  reducers: {
    clearAccessToken: (state) => {
      state.accessToken = undefined
    },

    clearMeeting: (state) => {
      return initialState
    },

    setRoomState: (state, action: { payload: RoomState }) => 
    {
      state.state = action.payload

      if (action.payload !== 'joined') {
        state.activeSpeakerId = null
        state.statsPeerId = null
      } else {
        state.joinedTimeStamp = Date.now()
      }
    },

    setRoomDetails: (state, { payload }: PayloadAction<any>) =>
    {
      state.id = payload.id
      state.name = payload.name
      state.creator = payload.creator
      state.participants = payload.participants
      state.invited = payload.invited
      state.startDate = payload.startDate
      state.duration = payload.duration
      state.creatorNote = payload.creatorNote
      state.role = payload.role
      state.files = payload.files
      state.hasAccessFiles = payload.hasAccessFiles
      state.waitingInvited = payload.waitingInvited || []
      state.networkTroubleUsers = payload.networkTroubleUsers || []
      state.allowed = payload.allowed
      state.decrypt_key = payload.decrypt_key
    },

    setAllowedToJoin: (state, { payload }: PayloadAction<AllowedToJoinState>) =>
    {
      state.allowed = payload
    },

    setRoomActiveSpeaker: (state, action: { payload: string | null }) =>
    {
      state.activeSpeakerId = action.payload
    },

    setWebcam: (state, { payload }: PayloadAction<string | undefined>) =>
    {
      state.webcam = payload
    },

    setWebcams: (state, { payload }: PayloadAction<MediaDeviceLabel[]>) =>
    {
      state.webcams = payload
    },

    setMic: (state, { payload }: PayloadAction<string | undefined>) =>
    {
      state.mic = payload
    },

    setMics: (state, { payload }: PayloadAction<MediaDeviceLabel[]>) =>
    {
      state.mics = payload
    },

    setSpeaker: (state, { payload }: PayloadAction<string | undefined>) =>
    {
      state.speakerId = payload
    },

    setSpeakers: (state, { payload }: PayloadAction<MediaDeviceLabel[]>) =>
    {
      state.speakers = payload
    },

    setShowInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.showInfo = payload
    },

    addChatMessage(state, { payload }: PayloadAction<{ chat: string, message: ChatMessage, email: string}>) {
      const chat = state.chats.find(chat => chat.id === payload.chat)

      const pushMessage = (chat: ChatInterface) => {
        chat.messages.push(payload.message)
        if (state.joinedTimeStamp && payload.message.timestamp > state.joinedTimeStamp) {
          chat.unread += 1
        }
      }

      if(chat) pushMessage(chat)
      state.chats = sortByLatestMessageTimestamp(state.chats)      
    },

    readChat(state, { payload }: PayloadAction<string>) {
      const chat = state.chats.find(chat => chat.id === payload)
      if (chat) {
        chat.unread = 0
      }
    },

    addPeer: (state, { payload }: PayloadAction<PeerModel>) =>
    {
      state.peers[payload.id] = payload
    },

    removePeer: (state, { payload }: PayloadAction<string>) =>
    {
      delete state.peers[payload]
    },
    setPromotedFullScreen:(state, { payload }: PayloadAction<boolean>) => {
      state.promotedFullScreen = payload
    },
    setDisconnectDueToMultipleConnections:(state, { payload }: PayloadAction<boolean>) => {
      state.disconnectDueToMultipleConnections = payload
    },
  },

  extraReducers: (builder) => {
    builder
    .addCase(fetchAccessToken.pending.type, (state) => {
      state.fetchAccessTokenStatus = "loading"
      state.fetchAccessTokenError = undefined
    })

    .addCase(fetchAccessToken.fulfilled.type, (state, action: PayloadAction<string>) => {
      state.fetchAccessTokenStatus = "success"
      state.fetchAccessTokenError = undefined
      state.accessToken = action.payload
    })

    .addCase(fetchAccessToken.rejected.type, (state, action) => {
      const error = (action as any).error
      state.fetchAccessTokenStatus = "error"
      state.fetchAccessTokenError = error && error.name === "ApiError" ? error.message : "unknown_error"
    })
    
    .addCase(removePeer, (state, action: { payload: string }) =>
    {
      if (state.activeSpeakerId === action.payload) {
        state.activeSpeakerId = null
      }

      if (state.statsPeerId === action.payload) {
        state.statsPeerId = null
      }
    })
    .addCase(setRoomState, (state, { payload }: PayloadAction<string>) => {
      if (payload === 'closed') {
        return initialState
      }
    })
    .addCase(addConsumer, (state, action: { payload: { consumer: ConsumerModel, peerId: string } }) => {
      const peer = state.peers[action.payload.peerId]
      if (peer) {
        peer.consumers.push(action.payload.consumer.id)
      }
    })
    .addCase(removeConsumer, (state, action: { payload: { consumerId: string, peerId: string } }) => 
    {
      const peer = state.peers[action.payload.peerId]
      if (peer) {
        peer.consumers = peer.consumers.filter(consumer => consumer !== action.payload.consumerId)
      }
    })
    .addCase(addDataConsumer, (state, action: { payload : { dataConsumer: DataConsumerModel, peerId: string } }) => 
    {
      const peer = state.peers[action.payload.peerId]
      if (peer) {
        peer.dataConsumers.push(action.payload.dataConsumer.id)
      }
    })
    .addCase(removeDataConsumer, (state, action: { payload: { dataConsumerId: string, peerId: string }}) => 
    {
      const peer = state.peers[action.payload.peerId]
      if (peer) {
        peer.dataConsumers = peer.dataConsumers.filter(dataConsumer => dataConsumer !== action.payload.dataConsumerId)
      }
    })
  },
})


export default roomSlice.reducer


export const {
  clearAccessToken,
  clearMeeting,
  setRoomState,
  setRoomDetails,
  setAllowedToJoin,
  setRoomActiveSpeaker,
  setWebcam,
  setWebcams,
  setMic,
  setMics,
  setSpeaker,
  setSpeakers,
  setShowInfo,
  addChatMessage,
  readChat,
  addPeer,
  removePeer,
  setPromotedFullScreen,
  setDisconnectDueToMultipleConnections
} = roomSlice.actions
