import React from 'react'
import { BsUpload } from 'react-icons/bs'
import { UploadProps } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addUnconfirmedFile, updateFiles } from '../../features/Meetings/MeetingsSlice'
import { RoomInterface } from '../../features/room/RoomInterface'
import { AppDispatch, RootState } from '../../store'
import customFetch from '../../customFetch'
import { useToastContext } from '../Toast/ToastContext'

interface Props {
  room?: RoomInterface
  title?: string
  immediateSave: boolean
  disabled: boolean
  newRoomAddUnconfirmedFile?: (file: { uid: string; filename: string }) => void
}

function DocumentsUpload({
  room,
  title,
  immediateSave,
  disabled,
  newRoomAddUnconfirmedFile,
}: Props) {
  const { t } = useTranslation('meetings')
  const auth = useSelector((state: RootState) => state.auth)
  const invite = useSelector((state: RootState) => state.invite)
  const jwt = invite.jwt ?? auth.jwt
  const encryptKey = room?.decrypt_key
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: (file) => {
      if (room?.files?.some((f) => f.filename === file.name)) {
        ToastOpen({
          message: t('This file already exists.'),
          type: 'error',
        })
        return false
      }
    },
    action: `${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${encryptKey}`,
    onChange: async (info) => {
      switch (info.file.status) {
        case 'done':
          if (immediateSave) {
            const response = await customFetch(
              process.env.REACT_APP_MEDIA_BASE_URL + '/key-storage/upload',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `${jwt}`,
                },
                body: JSON.stringify({
                  meetingId: room?.id,
                  uid: info.file.response.id,
                  filename: info.file.name,
                }),
              },
            )
            if (!response.ok) {
              throw new Error(response.status.toFixed() || '401')
            }
            const body = await response.json()
            dispatch(updateFiles({ roomId: room?.id || '', files: body }))
            ToastOpen({
              message: t('File has been imported successfully.', { ns: 'drives' }),
              type: 'success',
            })
          } else {
            if (newRoomAddUnconfirmedFile) {
              newRoomAddUnconfirmedFile({
                uid: info.file.response.id,
                filename: info.file.name,
              })
            } else {
              dispatch(
                addUnconfirmedFile({
                  roomId: room?.id || '',
                  file: {
                    uid: info.file.response.id,
                    filename: info.file.name,
                  },
                }),
              )
            }
          }
          break
        case 'error':
          ToastOpen({
            message: t('Error uploading file.'),
            type: 'error',
          })
          break
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    showUploadList: false,
  }

  return !disabled ? (
    <>
      {title && <h4 className="uppercase mb-05rem">{title}</h4>}
      <Dragger {...draggerProps} className="documents-dragger">
        <p className="ant-upload-drag-icon">
          <BsUpload />
        </p>
        <p className="ant-upload-text">
          {t('Click or drag file to this area to upload')}
        </p>
        <p className="ant-upload-hint">
          {t('We will encrypt your file and share it with authorized people only.')}
        </p>
      </Dragger>
    </>
  ) : (
    <></>
  )
}

export default DocumentsUpload
