import React from 'react'
import './meetings.scss'
import { Divider } from 'antd'

interface Props {
  title: string
}

export default function UpcomingMainContent(props: Props) {
  const { title } = props

  return (
    <>
      <div className="layout-header-bottom">
        <h4 className="layout-list-header">{title}</h4>
      </div>

      <Divider />
    </>
  )
}
