import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { AppDispatch, RootState } from '../../../store'
import {
  fetchPeriodicRooms,
  fetchRooms,
  idleCreateRoomStatus,
  idleDeleteGeneratedRoomStatus,
  idleDeletePeriodicRoomStatus,
  idleDeleteRoomStatus,
  periodicRoomsCount,
  roomsCount,
  RoomToModify,
  setRoomToDelete,
  setRoomToModify,
} from '../MeetingsSlice'
import Layout from '../../../components/Layout/Layout'
import RoomManage from '../../../components/RoomManage/RoomManage'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import MeetingHeader from '../MeetingHeader'
import MeetingsTable from '../MeetingsTable'
import '../meetings.scss'
import './upcoming-meetings.scss'
import ModalDeleteMeeting from '../../../components/Modal/ModalDeleteMeeting'
import MeetingsMenu from '../MeetingsMenu'
import { useToastContext } from '../../../components/Toast/ToastContext'

function UpcomingMeetings() {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const navigate = useNavigate()
  const upcoming = useSelector((state: RootState) => state.rooms.rooms.upcoming)
  const periodicUpcoming = useSelector(
    (state: RootState) => state.rooms.periodicRooms.upcoming,
  )
  const maxDate = dayjs()
    .add(parseInt(process.env.REACT_APP_MAX_DATE_UPCOMING || '30'), 'days')
    .toString()
  const createRoomStatus = useSelector((state: RootState) => state.rooms.createRoomStatus)
  const updateRoomStatus = useSelector((state: RootState) => state.rooms.updateRoomStatus)
  const updatePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.updatePeriodicRoomStatus,
  )
  const deleteRoomStatus = useSelector((state: RootState) => state.rooms.deleteRoomStatus)
  const deleteGeneratedRoomStatus = useSelector(
    (state: RootState) => state.rooms.deleteGeneratedRoomStatus,
  )
  const deletePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.deletePeriodicRoomStatus,
  )

  dayjs.extend(duration)
  const roomModify = useSelector((state: RootState) => state.rooms.roomToModify)
  const setRoomModify = (room: RoomToModify | undefined): any =>
    dispatch(setRoomToModify(room))

  const periodicNowDate = () => dayjs().startOf('day').toString()

  useEffect(() => {
    return () => {
      dispatch(idleCreateRoomStatus())
    }
  }, [createRoomStatus])

  useAttemptsListener(
    [
      [
        createRoomStatus,
        {
          error: () =>
            ToastOpen({
              message: t('Error creating meeting.'),
              type: 'error',
            }),
        },
      ],
      [
        updateRoomStatus,
        {
          success: () => {
            dispatch(fetchRooms({ status: 'upcoming' }))
            dispatch(
              fetchPeriodicRooms({
                status: 'upcoming',
                minDate: periodicNowDate(),
                maxDate: maxDate,
              }),
            )
          },
        },
      ],
      [
        updatePeriodicRoomStatus,
        {
          success: () => {
            dispatch(fetchRooms({ status: 'upcoming' }))
            dispatch(
              fetchPeriodicRooms({
                status: 'upcoming',
                minDate: periodicNowDate(),
                maxDate: maxDate,
              }),
            )
            dispatch(
              periodicRoomsCount({ minDate: dayjs().toISOString(), maxDate: maxDate }),
            )
          },
        },
      ],
      [
        deleteRoomStatus,
        {
          success: () => {
            dispatch(setRoomToDelete({ room: undefined, pending: false }))
            ToastOpen({
              message: t('Meeting was successfully deleted.'),
              type: 'success',
            })
            dispatch(fetchRooms({ status: 'upcoming' }))
            dispatch(
              fetchPeriodicRooms({
                status: 'upcoming',
                minDate: periodicNowDate(),
                maxDate: maxDate,
              }),
            )
            dispatch(roomsCount({}))
          },
          error: () =>
            ToastOpen({
              message: t('Error deleting meeting.'),
              type: 'error',
            }),
        },
      ],
      [
        deleteGeneratedRoomStatus,
        {
          success: () => {
            dispatch(setRoomToDelete({ room: undefined, pending: false }))
            ToastOpen({
              message: t('Meeting was successfully deleted.'),
              type: 'success',
            })
            dispatch(fetchRooms({ status: 'upcoming' }))
            dispatch(
              fetchPeriodicRooms({
                status: 'upcoming',
                minDate: periodicNowDate(),
                maxDate: maxDate,
              }),
            )
            dispatch(
              periodicRoomsCount({ minDate: dayjs().toISOString(), maxDate: maxDate }),
            )
          },
          error: () =>
            ToastOpen({
              message: t('Error deleting meeting.'),
              type: 'error',
            }),
        },
      ],
      [
        deletePeriodicRoomStatus,
        {
          success: () => {
            dispatch(setRoomToDelete({ room: undefined, pending: false }))
            ToastOpen({
              message: t('Meetings were successfully deleted.'),
              type: 'success',
            })
            dispatch(fetchRooms({ status: 'upcoming' }))
            fetchPeriodicRooms({
              status: 'upcoming',
              minDate: periodicNowDate(),
              maxDate: maxDate,
            })
            dispatch(
              periodicRoomsCount({ minDate: dayjs().toISOString(), maxDate: maxDate }),
            )
          },
          error: () =>
            ToastOpen({
              message: t('Error deleting meeting.'),
              type: 'error',
            }),
        },
      ],
    ],
    () => {
      dispatch(idleCreateRoomStatus())
      dispatch(idleDeleteRoomStatus())
      dispatch(idleDeleteGeneratedRoomStatus())
      dispatch(idleDeletePeriodicRoomStatus())
    },
    () => {
      dispatch(fetchRooms({ status: 'upcoming' }))
      dispatch(
        fetchPeriodicRooms({
          status: 'upcoming',
          minDate: periodicNowDate(),
          maxDate: maxDate,
        }),
      )
    },
  )

  function onCloseRoomManage() {
    navigate('/upcoming-meetings')
    setRoomModify(undefined)
  }

  function renderUpcomingMeetingsContent() {
    if (roomModify) {
      return <RoomManage room={roomModify} close={onCloseRoomManage} />
    } else {
      return (
        <>
          <MeetingHeader title={t('Your upcoming meetings')} />
          <MeetingsTable
            archived={false}
            data={{
              rooms: [...upcoming.rooms, ...periodicUpcoming.rooms],
              fetchStatus:
                upcoming.fetchStatus == 'success'
                  ? periodicUpcoming.fetchPeriodicRoomStatus
                  : upcoming.fetchStatus,
              fetchError: upcoming.fetchError || periodicUpcoming.fetchPeriodicRoomError,
            }}
            setRoomModify={setRoomModify}
          />
        </>
      )
    }
  }

  function renderUpcomingMeetingsMenu() {
    return <MeetingsMenu />
  }

  return (
    <>
      <Layout
        title="Meetings"
        menu={renderUpcomingMeetingsMenu()}
        content={renderUpcomingMeetingsContent()}
      />
      <ModalDeleteMeeting />
    </>
  )
}

export default UpcomingMeetings
