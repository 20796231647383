import React from 'react'
import { Button, List, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import './DriveGroups.scss'
import {
  DriveGroupParticipant,
  DriveRole,
  setGroups,
} from '../../../features/Drives/redux/drivesSlice'
import VirtualList from 'rc-virtual-list'
import { IoTrashOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'

interface Props {
  newDrive: boolean
}

function DriveGroups({ newDrive }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const groups = useSelector((state: RootState) => state.drive.driveModifyForm.groups)
  const isAdmin = useSelector((state: RootState) => state.drive.driveModifyForm.isAdmin)

  function renderSelectValue(participant: DriveGroupParticipant) {
    switch (true) {
      case participant.adminRights:
        return DriveRole.ADMIN
      case participant.folderRights:
        return DriveRole.FOLDERS_FILES
      case participant.fileRights:
        return DriveRole.FILES
      default:
        return DriveRole.READ_ONLY
    }
  }

  /**
   *
   */
  function removeDriveParticipant(participantToRemove: DriveGroupParticipant) {
    const index = groups.findIndex((group) => group === participantToRemove)

    if (index !== -1) {
      const updatedParticipants = [...groups]
      updatedParticipants.splice(index, 1)
      dispatch(setGroups(updatedParticipants))
    }
  }

  /**
   *
   * @param email
   * @param newRole
   */
  function changeParticipantRole(
    groupToChange: DriveGroupParticipant,
    newRole: DriveRole,
  ) {
    const index = groups.findIndex((group) => group === groupToChange)
    const updatedGroups = [...groups]
    switch (newRole) {
      case DriveRole.ADMIN:
        updatedGroups[index] = {
          ...groupToChange,
          adminRights: true,
          folderRights: true,
          fileRights: true,
        }
        break

      case DriveRole.FOLDERS_FILES:
        updatedGroups[index] = {
          ...groupToChange,
          adminRights: false,
          folderRights: true,
          fileRights: true,
        }
        break

      case DriveRole.FILES:
        updatedGroups[index] = {
          ...groupToChange,
          adminRights: false,
          folderRights: false,
          fileRights: true,
        }
        break

      default:
        updatedGroups[index] = {
          ...groupToChange,
          adminRights: false,
          folderRights: false,
          fileRights: false,
        }
    }
    dispatch(setGroups(updatedGroups))
  }

  return (
    <div className="DriveGroups">
      <List>
        <VirtualList data={groups} itemKey="id" className="list">
          {(participant: DriveGroupParticipant) => (
            <List.Item key={participant.id}>
              <List.Item.Meta title={participant.name} description={t('Group')} />
              <div className="d-flex d-flex-center d-flex-middle g-0_5rem">
                <Select
                  defaultValue={renderSelectValue(participant)}
                  onChange={(value) => changeParticipantRole(participant, value)}
                  disabled={!isAdmin && !newDrive}
                >
                  <Select.Option value={DriveRole.READ_ONLY}>
                    {t(DriveRole.READ_ONLY)}
                  </Select.Option>
                  <Select.Option value={DriveRole.FILES}>
                    {t(DriveRole.FILES)}
                  </Select.Option>
                  <Select.Option value={DriveRole.FOLDERS_FILES}>
                    {t(DriveRole.FOLDERS_FILES)}
                  </Select.Option>
                  <Select.Option value={DriveRole.ADMIN}>
                    {t(DriveRole.ADMIN)}
                  </Select.Option>
                </Select>
                {(isAdmin || newDrive) && (
                  <Button
                    className="btn-danger-border"
                    onClick={() => removeDriveParticipant(participant)}
                  >
                    <IoTrashOutline className="error-color" />
                  </Button>
                )}
              </div>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </div>
  )
}

export default DriveGroups
