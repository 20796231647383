import { useState, useRef, MouseEventHandler } from 'react';

export default function useLongPress(callback: () => void) {
  const timerRef = useRef<NodeJS.Timeout>();

  function startPressInterval() {
    timerRef.current = setInterval(() => {
      callback()
    }, 50)
  }

  function stopPressInterval() {
    clearTimeout(Number(timerRef.current));
  }

  function start() {
    startPressInterval();
  }

  function end() {
    stopPressInterval()
  }

  return {
    onMouseDown: start,
    onMouseUp: end,
    onTouchStart: start,
    onTouchEnd: end,
    onMouseLeave: end,
  }
}