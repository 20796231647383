import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../utils/FetchStatus'
import customFetch from '../../customFetch'


export interface PersonalizationValues {
  title             : string
  primaryColor      : string
  primaryColorText  : string
  secondaryColor    : string
  secondaryColorText: string
  tertiaryColor     : string
  tertiaryColorText : string
}


export interface PersonalizationUpdateValues extends PersonalizationValues {
  replaceLoginLogo?       : string
  replaceEmailLogo?       : string
  replaceLoginBackground? : string
  replaceHeaderLogo?      : string
  replaceHeaderLogoMobile?: string
  replaceCallLogo?        : string
  replaceIcon?            : string
}


interface State {
  getStatus: FetchStatus,
  updateStatus: FetchStatus,
  values?: PersonalizationValues,
  buildInProgress: boolean,
  available: boolean
}


export const initialState:State = {
  getStatus: "idle",
  updateStatus: "idle",
  buildInProgress: false,
  available: false
}


/**
 * Get personalization.
 */
export const attemptGetPersonalization = createAsyncThunk(
  'personalization/getBuildInProgress',
  async ({ jwt }: { jwt: string }) => {
    const response = await customFetch(process.env.REACT_APP_BASE_PERSONALIZATION_URL + '/getBuildInProgress', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${jwt}`,
      },
    })

    if (!response.ok) {
      throw new Error("Could not personalize")
    }

    const values = await response.json()

    return values
  }
)


/**
 * Update personalization.
 */
export const attemptUpdatePersonalization = createAsyncThunk(
  'personalization/update',
  async (payload: PersonalizationUpdateValues, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string }}

    const response = await customFetch(process.env.REACT_APP_BASE_PERSONALIZATION_URL + '/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      throw new Error("Could not personalize")
    }
  }
)

/**
 * Check if this feature is available.
 */
export const attemptCheckPersonalizationAvailable = createAsyncThunk(
  'personalization/availability',
  async () => {
    const response = await customFetch(process.env.REACT_APP_BASE_PERSONALIZATION_PUBLIC_URL + '/current.json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error("Could not check for availability")
    }

    return ({available: response.ok})
  }
)


const personalizationSlice = createSlice({
  name: 'personalization',
  initialState,
  reducers: {
    idlePersonalizationGetStatus: (state) => {
      state.getStatus = "idle"
    },
    idlePersonalizationUpdateStatus: (state) => {
      state.updateStatus = "idle"
    },
  },

  extraReducers: {
    [attemptGetPersonalization.fulfilled.type]: (state, action) => {
      state.getStatus = "success"
      state.values = action.payload.personalization
      state.buildInProgress = action.payload.buildInProgress
    },
    [attemptGetPersonalization.pending.type]: (state, action) => {
      state.getStatus = "loading"
    },
    [attemptGetPersonalization.rejected.type]: (state, action) => {
      state.getStatus = "error"
      console.error(action)
    },

    [attemptUpdatePersonalization.pending.type]: (state, action) => {
      state.updateStatus = "loading"
    },
    [attemptUpdatePersonalization.fulfilled.type]: (state, action) => {
      state.updateStatus = "success"
      state.buildInProgress = true
    },
    [attemptUpdatePersonalization.rejected.type]: (state, action) => {
      state.updateStatus = "error"
    },

    [attemptCheckPersonalizationAvailable.fulfilled.type]: (state, action) => {
      state.available = action.payload.available
    },
  },
})


export default personalizationSlice.reducer


export const {
  idlePersonalizationGetStatus,
  idlePersonalizationUpdateStatus,
} = personalizationSlice.actions
