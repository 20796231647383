import { AnyAction, combineReducers, configureStore, createListenerMiddleware, Middleware, Reducer } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { enableMapSet } from 'immer'
import accountReducer, { initialState as accountInitialState } from './features/account/redux/accountSlice'
import authReducer, { initialState as authInitialState } from './features/auth/redux/authSlice'
import appReducer, { initialState as appInitialState } from './redux/appSlice'
import newMeetingReducer, { initialState as newMeetingInitialState } from './features/Meetings/NewMeetingSlice'
import meetingVoteReducer, { initialState as meetingVoteInitialState } from './features/Meetings/MeetingVote/MeetingVoteSlice'
import roomsReducer, { initialState as roomsInitialState } from './features/Meetings/MeetingsSlice'
import roomReducer, { initialState as roomInitialState } from './features/room/redux/roomSlice'
import meReducer, { setBokehEffectActive, initialState as meInitialState } from './features/room/redux/meSlice'
import producersReducer, { initialState as producersInitialState } from './features/room/redux/producersSlice'
import dataProducersReducer, { initialState as dataProducersInitialState } from './features/room/redux/dataProducersSlice'
import consumersReducer, { initialState as consumersInitialState } from './features/room/redux/consumersSlice'
import dataConsumersReducer, { initialState as dataConsumersInitialState } from './features/room/redux/dataConsumersSlice'
import inviteReducer, { initialState as inviteInitialState } from './features/invite/redux/inviteSlice'
import organisationReducer, { initialState as organisationInitialState } from './features/Organisation/redux/organisationSlice'
import personalizationReducer, { initialState as PersonalizationInitialState } from './features/Personalization/PersonalizationSlice'
import roomNotarisationReducer, { initialState as roomNotarisationInitialState } from './features/Notarisation/roomNotarisationSlice'
import reinitPasswordReducer, { initialState as reinitPasswordInitialState } from './features/ReinitPassword/redux/reinitPasswordSlice'
import calendarReducer, { initialState as calendarInitialState } from './features/calendar/redux/calendarSlice'
import groupsReducer, { initialState as groupsInitialState } from './features/Groups/redux/groupsSlice'
import driveReducer, { initialState as driveInitialState } from './features/Drives/redux/drivesSlice'
import layoutReducer, { initialState as layoutInitialState } from './components/Layout/LayoutSlice'
import chatSquadsReducer, { initialState as chatSquadsInitialState } from './components/ChatComponent/Squad/squadListSlice'
import chatsReducer, { initialState as chatsInitialState } from './components/ChatComponent/Chat/chatsSlice'
import chatWebsocketReducer, { initialState as websocketInitialState } from './components/ChatComponent/WebsocketConnection/websocketSlice'
import viewerReducer, {initialState as viewerInitialState } from './components/Viewer/ViewerSlice'
import previewerReducer, {initialState as previewerInitialState } from './components/ChatComponent/Chat/Previewer/PreviewerSlice'
import liveNotificationReducer, { initialState as liveNotificationInitialState } from './components/NotificationWidget/liveWidgetSlice'
import notificationsReducer, { initialState as notificationsInitialState } from './features/Notifications/notificationsSlice'

enableMapSet()


const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'app',
    'newMeeting',
    'rooms',
    'room',
    'meetingVote',
    'me',
    'producers',
    'dataProducers',
    'consumers',
    'dataConsumers',
    'peers',
    'roomFiles',
    'reinitPassword',
    'personalization',
    'calendar',
    'groups',
    'drive',
    'chatSquads',
    'chats',
    'chatWebsocket',
    'liveNotification',
    'notifications',
  ],
  whitelist: [
    'auth',
    'invite',
    'organisation',
    'driveFiles',
  ],
}


const combinedReducer = combineReducers({
  auth          : authReducer,
  invite        : inviteReducer,
  organisation  : organisationReducer,
  account       : accountReducer,
  app           : appReducer,
  newMeeting    : newMeetingReducer,
  rooms         : roomsReducer,
  room          : roomReducer,
  meetingVote   : meetingVoteReducer,
  me            : meReducer,
  producers     : producersReducer,
  dataProducers : dataProducersReducer,
  consumers     : consumersReducer,
  dataConsumers : dataConsumersReducer,
  reinitPassword  : reinitPasswordReducer,
  calendar        : calendarReducer,
  groups          : groupsReducer,
  drive           : driveReducer,
  personalization : personalizationReducer,
  roomNotarisation: roomNotarisationReducer,
  layout          : layoutReducer,
  chatSquads      : chatSquadsReducer,
  chats           : chatsReducer,
  chatWebsocket   : chatWebsocketReducer,
  viewer          : viewerReducer,
  previewer       : previewerReducer,
  liveNotification: liveNotificationReducer,
  notifications   : notificationsReducer,
})

const initialState = {
  account       : accountInitialState,
  auth          : authInitialState,
  app           : appInitialState,
  newMeeting    : newMeetingInitialState,
  rooms         : roomsInitialState,
  room          : roomInitialState,
  meetingVote   : meetingVoteInitialState,
  me            : meInitialState,
  producers     : producersInitialState,
  dataProducers : dataProducersInitialState,
  consumers     : consumersInitialState,
  dataConsumers : dataConsumersInitialState,
  invite        : inviteInitialState,
  organisation  : organisationInitialState,
  personalization : PersonalizationInitialState,
  roomNotarisation: roomNotarisationInitialState,
  reinitPassword  : reinitPasswordInitialState,
  calendar: calendarInitialState,
  groups: groupsInitialState,
  drive: driveInitialState,
  layout: layoutInitialState,
  chatSquads: chatSquadsInitialState,
  chats: chatsInitialState,
  chatWebsocket: websocketInitialState,
  viewer: viewerInitialState,
  previewer: previewerInitialState,
  liveNotification: liveNotificationInitialState,
  notifications: notificationsInitialState,
}

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    return combinedReducer(initialState, action)
  }
  
  if (action.error?.name === 'UnauthorizedError') {
    return combinedReducer({...initialState, auth: {...authInitialState, hasBeenDisconnected: true}}, action)
  }

  if (action.type === 'me/setBokehEffectActive') {
    localStorage.setItem("settingsBlurCamera", action.payload)
  }

  if (action.type === 'persist/REHYDRATE') {
    if (action.payload) {
      action.payload.auth.attemptAuthStatus = "idle"
      action.payload.auth.attemptSignupStatus = "idle"
      action.payload.auth.attemptReinitPasswordStatus = "idle"
      action.payload.invite.connectStatus = "idle"
      action.payload.invite.connectSecureAccessStatus = "idle"
      action.payload.invite.sendNewAccessTokenStatus = "idle"
    }

    return combinedReducer(state, action)
  }

  return combinedReducer(state, action)
}


const persistedReducer = persistReducer(persistConfig, rootReducer)


export const restoreSettingsMiddleware: Middleware<
  {},
  RootState
> = storeApi => next => action => {

  if (action.type === 'persist/REHYDRATE') {
    const blurCamera = localStorage.getItem("settingsBlurCamera") == "true"
    storeApi.dispatch(setBokehEffectActive(blurCamera))
  }

  return next(action)
}


const listenerMiddleware = createListenerMiddleware()

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"]
      }
    })
    .prepend(listenerMiddleware.middleware)
    .concat(restoreSettingsMiddleware),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

