import React, { useEffect, useRef } from 'react'
import { Button, Form, InputRef, Modal, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useAttemptsListener } from 'auxasphere-react-kit'
import {
  createDrive,
  fetchDrive,
  idleCreateDriveStatus,
  setCreateDrive,
  setGroups,
  setParticipants,
} from '../../../features/Drives/redux/drivesSlice'
import DriveForm from './DriveForm'
import { MdOutlineCreateNewFolder } from 'react-icons/md'
import { addLiveNotification } from '../../NotificationWidget/liveWidgetSlice'
import { addListener } from '@reduxjs/toolkit'
import { useToastContext } from '../../Toast/ToastContext'

function CreateDriveModal() {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const [driveForm] = Form.useForm()
  const createDriveOpen = useSelector((state: RootState) => state.drive.createDrive)

  const participants = useSelector(
    (state: RootState) => state.drive.driveModifyForm.participants,
  )
  const groups = useSelector((state: RootState) => state.drive.driveModifyForm.groups)

  const createDriveStatus = useSelector(
    (state: RootState) => state.drive.createDriveStatus,
  )
  const inputFocusRef = useRef<InputRef>(null)
  const { ToastOpen } = useToastContext()

  useEffect(() => {
    const removeLiveNotificationListener = dispatch(
      addListener({
        actionCreator: addLiveNotification,
        effect: (action, listenerApi) => {
          if (action.payload.type === 'DRIVE_CREATED') {
            dispatch(fetchDrive({ id: action.payload.misc['driveId'], newDrive: true }))
          }
        },
      }),
    )

    return () => {
      resetModal()
      dispatch(setCreateDrive(false))
      removeLiveNotificationListener()
    }
  }, [])

  useAttemptsListener([
    [
      createDriveStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Drive was successfully created.'),
            type: 'success',
          })
          dispatch(setCreateDrive(false))
          resetModal()
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error creating group..'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleCreateDriveStatus()),
    ],
  ])

  /**
   *
   */
  function onDriveCreate() {
    dispatch(
      createDrive({
        name: driveForm.getFieldsValue().name,
        users: participants,
        groups: groups,
      }),
    )
  }

  /**
   *
   */
  function onClose() {
    resetModal()
    dispatch(setCreateDrive(false))
  }

  /**
   *
   */
  function resetModal() {
    driveForm.resetFields()
    dispatch(setParticipants([]))
    dispatch(setGroups([]))
  }

  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <Modal
        centered
        open={createDriveOpen}
        title={t('Create drive')}
        onOk={driveForm.submit}
        onCancel={onClose}
        okText={t('Create')}
        cancelText={t('Cancel', { ns: 'common' })}
        maskClosable={false}
        width="60em"
        afterOpenChange={(open) =>
          open && inputFocusRef.current?.focus({ cursor: 'all' })
        }
      >
        <div className="edit-drive-modal">
          <DriveForm
            driveForm={driveForm}
            onDriveSubmit={onDriveCreate}
            newDrive={true}
            inputFocusRef={inputFocusRef}
          />
        </div>
      </Modal>
    </div>
  )
}

export default CreateDriveModal
