import React from 'react'
import Table, { ColumnsType } from 'antd/es/table'
import {
  Notification,
  attemptDeleteNotification,
  attemptMarkNotificationSeen,
} from '../../components/NotificationWidget/liveWidgetSlice'
import { Trans, useTranslation } from 'react-i18next'
import { Spin, Tooltip } from 'antd'
import dayjs from 'dayjs'
import NotificationList from '../../components/NotificationWidget/NotificationList'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { localFormatDate } from '../../utils/Utils'
import { IoCheckmarkCircleOutline, IoTrashOutline } from 'react-icons/io5'
import './Notifications.scss'

interface Props {
  notifications: Notification[] | undefined
  setNotificationsDisplayed: (notifications: Notification[] | undefined) => void
}

function NotificationsTable({ notifications, setNotificationsDisplayed }: Props) {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch<AppDispatch>()

  const totalCount = notifications?.length

  const attemptFetchNotificationsStatus = useSelector(
    (state: RootState) => state.notifications.attemptFetchNotificationsStatus,
  )

  const handleRead = (notification: Notification) => {
    setNotificationsDisplayed(
      notifications?.map((n) =>
        n.id === notification.id ? { ...n, unseenCount: 0 } : n,
      ),
    )
    dispatch(attemptMarkNotificationSeen(notification))
  }
  const handleDelete = (notification: Notification) => {
    setNotificationsDisplayed(notifications?.filter((n) => n.id !== notification.id))
    dispatch(attemptDeleteNotification(notification))
  }

  const columns: ColumnsType<Notification> = [
    // {
    //   className: 'notification_table_icon_column',
    //   render: () => <img src="" className="notification_widget_content_item_icon" />,
    // },
    {
      title: t('Date', { ns: 'common' }),
      className: 'date-column notification_table_date_column',
      render: (notification: Notification) =>
        localFormatDate(dayjs(notification.createdAt)),
      sorter: (a: Notification, b: Notification) => {
        if (!a.createdAt && !b.createdAt) {
          return 0
        }
        if (!a.createdAt) {
          return -1
        }
        if (!b.createdAt) {
          return -1
        }
        return dayjs.duration(dayjs(a.createdAt).diff(dayjs(b.createdAt))).asMinutes()
      },
    },
    {
      title: t('Message'),
      className: 'notification_table_message_column',
      render: (notification: Notification) => (
        <NotificationList notification={notification} isRowTable />
      ),
    },
    {
      title: '',
      className: 'notification_table_actions_column',
      render: (notification: Notification) => (
        <div className="notification_actions">
          {notification.unseenCount && notification.unseenCount > 0 ? (
            <Tooltip title={t('Mark as read')}>
              <IoCheckmarkCircleOutline
                onClick={() => handleRead(notification)}
                className="notification_widget_content_action notification_header_actions_hblue"
              />
            </Tooltip>
          ) : (
            <></>
          )}

          <Tooltip title={t('Delete', { ns: 'common' })}>
            <IoTrashOutline
              onClick={() => handleDelete(notification)}
              className="notification_widget_content_action notification_header_actions_hred"
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  if (attemptFetchNotificationsStatus === 'success') {
    if (notifications && notifications.length > 0) {
      return (
        <Table
          dataSource={notifications}
          rowKey="id"
          columns={columns}
          showSorterTooltip={false}
          pagination={
            notifications.length >= 11 && {
              position: ['bottomCenter'],
              showTotal: (total, range) => (
                <Trans
                  ns="common"
                  i18nKey="TABLE_PAGINATION"
                  values={{
                    range0: range[0],
                    range1: range[1],
                    total: total,
                  }}
                />
              ),
              showSizeChanger: true,
            }
          }
        />
      )
    } else {
      return <>{t('No notification')}</>
    }
  } else {
    return <Spin size="large" />
  }
}

export default NotificationsTable
