import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'antd'
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import { AiFillHome } from 'react-icons/ai' // N'oubliez pas d'importer AiFillHome
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  DriveInterface,
  FolderInterface,
  fetchFolderById,
} from '../../../features/Drives/redux/drivesSlice'
import { useDrivesContext } from '../../../features/Drives/DrivesContext'

interface PathFolder {
  find: boolean
  breadcrumb: ItemType[]
}

function DrivesBreadcrumb() {
  const dispatch = useDispatch<AppDispatch>()
  const { onFolderOpen } = useDrivesContext()
  const [breadcrumbItems, setBreadcrumbItems] = useState<ItemType[]>([])
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const drives = useSelector((state: RootState) => state.drive.drives)

  /**
   *
   */
  useEffect(() => {
    const findPathResult = buildBreadcrumb(selectedDriveFolder, drives)
    setBreadcrumbItems(findPathResult.breadcrumb || [])
  }, [selectedDriveFolder])

  /**
   * Build the breadcrumb to a specific folder
   * @param selectedDriveFolder the specific folder
   * @param drivesFolders all folders
   * @returns
   */
  function buildBreadcrumb(
    selectedDriveFolder: FolderInterface | undefined,
    drivesFolders: DriveInterface[] | undefined,
  ) {
    const getMenuItems = (folder: FolderInterface) => {
      return (folder?.childrenFolders || [])
        .filter((subFolder) => subFolder)
        .map((subFolder) => ({
          label: subFolder.name,
          key: subFolder.id,
          className: 'wrap-dropdown',
          onClick: async () => {
            if (
              (subFolder.childrenFolders &&
                subFolder.childrenFolders.length > 0 &&
                typeof subFolder.childrenFolders[0] === 'string') ||
              (subFolder.childrenFiles.length > 0 &&
                typeof subFolder.childrenFiles[0] === 'string')
            ) {
              await dispatch(
                fetchFolderById({
                  id: subFolder.id,
                  driveId: subFolder.driveId,
                  selected: true,
                }),
              )
            }
            onFolderOpen(subFolder)
          },
        }))
    }

    const buildBreadcrumbRec = (
      folder: FolderInterface | undefined,
      breadcrumb: ItemType[],
    ): PathFolder => {
      if (!folder) {
        return { find: false, breadcrumb }
      }

      const breadcrumbCurrentFolder: ItemType = {
        title: <a>{folder.name}</a>,
      }

      if (folder.id === selectedDriveFolder?.id) {
        const menuItems = getMenuItems(selectedDriveFolder)

        if (menuItems.length > 0) {
          breadcrumbCurrentFolder.menu = { items: menuItems }
        }

        return { find: true, breadcrumb: breadcrumb.concat(breadcrumbCurrentFolder) }
      } else {
        const folderHasChildren =
          folder.childrenFolders && folder.childrenFolders.length > 0
        if (folderHasChildren) {
          breadcrumbCurrentFolder.onClick = () => onFolderOpen(folder)
        }

        const currentBreadcrumb = breadcrumb.concat(breadcrumbCurrentFolder)
        let bestResultSubFolder = { find: false, breadcrumb: currentBreadcrumb }

        if (folderHasChildren) {
          for (const subFolder of folder.childrenFolders) {
            const subResult = buildBreadcrumbRec(subFolder, currentBreadcrumb)
            if (subResult.find) {
              bestResultSubFolder = subResult
            }
          }
        }

        return bestResultSubFolder
      }
    }

    const rootFolder = drivesFolders?.find(
      (drive) => drive.id === selectedDriveFolder?.driveId,
    )?.rootFolder
    return buildBreadcrumbRec(rootFolder, [])
  }

  return (
    <div className="Drives-breadcrumb-container">
      <AiFillHome size="1.1rem" />
      <Breadcrumb items={breadcrumbItems} separator="/" />
    </div>
  )
}

export default DrivesBreadcrumb
