import React, { useRef, useState, useEffect } from 'react'
import { Col, Input, InputRef, Tag, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import '../meetings.scss'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface Props {
  initialRef?: Array<string>
}

export default function TagReference({ initialRef }: Props) {
  const { t } = useTranslation('meetings')
  const [tags, setTags] = useState(initialRef ? initialRef.filter((a) => a) : [])

  return (
    <Col span={12}>
      <div className="meeting-reference">
        <span className="mr-1rem">{t('Internal reference')}</span>
        <i className="text-color-lighten">({t('only you can see')})</i>
      </div>
      <Form.List name="reference" initialValue={initialRef}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(
              (field) =>
                tags[field.name] && (
                  <Form.Item
                    key={field.key}
                    name={field.name}
                    initialValue={tags[field.name]}
                    style={{ display: 'inline-block' }}
                  >
                    <Tag
                      closable
                      onClose={(e) => {
                        const newTags = tags.filter((_, i) => i !== field.name)
                        setTags(newTags)
                        remove(field.name)
                      }}
                      color="blue"
                    >
                      {tags[field.name]}
                    </Tag>
                  </Form.Item>
                ),
            )}
            {tags.length < 3 && (
              <ReferenceInput tags={tags} setTags={setTags} add={add} />
            )}
          </>
        )}
      </Form.List>
    </Col>
  )
}

function ReferenceInput({
  tags,
  setTags,
  add,
}: {
  tags: string[]
  setTags: React.Dispatch<React.SetStateAction<string[]>>
  add: (defaultValue?: string, insertIndex?: number) => void
}) {
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const { t } = useTranslation('meetings')

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (
      inputValue &&
      tags.indexOf(inputValue) === -1 &&
      inputValue.match(/^ *$/) === null &&
      tags.includes(inputValue) === false
    ) {
      setTags([...tags, inputValue])
      add(inputValue)
    }
    setInputVisible(false)
    setInputValue('')
  }
  if (inputVisible) {
    return (
      <Input
        autoFocus
        ref={inputRef}
        type="text"
        style={{ width: 78 }}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
      />
    )
  } else
    return (
      <Tag onClick={showInput} color="blue" style={{ borderStyle: 'dashed' }}>
        <PlusOutlined /> {t('Add internal reference')}
      </Tag>
    )
}
